import React, { useEffect, useState } from "react";

import { SelectField } from "../";
import { IItemSelectField } from "../SelectField";

import "./styles.scss";
import { Tags } from "../../../shared/components/Tags";

 
interface SearchOEProps {
  enableAreaConhecimento?: boolean;
  enableTipo?: boolean;
  enableRotulo?: boolean;
  enableOrdenacao?: boolean;
  theme?: "white" | "dark";
  handleFilter(value: FiltersOE): void;
  listFilters: IListFiltersSearchOe;
  totals?: number;
  resetSearch: number;

}

export interface IListFiltersSearchOe {
  areaConhecimento: IItemSelectField[];
  rotulo: IItemSelectField[];
  tipo: IItemSelectField[];
  ordenacao: IItemSelectField[];
}

interface FiltersOE {
  areaConhecimento?: any;
  rotulo?: any;
  tipo?: any;
  ordenacao?: any;
  possuiCertificado?: boolean;
}

interface StructureType {
  id: string;
  label: string;
  list: IItemSelectField[];
}

const structure = {
  rotulo: {
    id: "rotulo",
    label: "Métodos de ensino",
  } as StructureType,
  tipo: {
    id: "tipo",
    label: "Tipo de Mídia",
  } as StructureType,
  ordenacao: {
    id: "ordenacao",
    label: "Ordenar",
  } as StructureType,
};

export const SearchOE: React.FC<SearchOEProps> = (props) => {
  const [enables, setEnables] = useState<StructureType[]>([]);
  const [filters, setFilters] = useState<FiltersOE>({
    areaConhecimento: [],
    ordenacao: "",
    rotulo: [],
    tipo: [],
    possuiCertificado: undefined,
  });

  useEffect(() => {  
    filterSelected.rotulo = [];
    filterSelected.tipo = [];
    props.handleFilter(filterSelected);
  }, [props.resetSearch]);

  useEffect(() => {
    const filtersType: StructureType[] = [];

    if (props.enableRotulo) {
      filtersType.push(structure.rotulo);
    }

    if (props.enableTipo) {
      filtersType.push(structure.tipo);
    }

    if (props.enableOrdenacao) {
      filtersType.push(structure.ordenacao);
    }

    setEnables(filtersType);
  }, []);
  const [filterSelected, setFilterSelected] = useState({ ...filters });
  const [totalItems, setTotalItems] = useState<number>(0);

  useEffect(() => {
    setTotalItems(props.totals ?? 0);
  }, [props.totals]);
  
  useEffect(() => {
    setFilterSelected({ ...filters });
     
  }, [filters]);

  const handleRemove = (item: any, name: string) => {        
    switch (name){
      case 'rotulo':
        filterSelected.rotulo = [];
        props.handleFilter(filterSelected);
        break;
      case 'tipo':
        filterSelected.tipo = [];
        props.handleFilter(filterSelected);  
        break;
      default: 
      break;
    }

  };

  return (
    <div data-search-oes>
      <div data-select-field-list>
        {enables &&
          enables.map((filter: StructureType, idx) => (
            <div data-select-field key={idx}>
              <SelectField
                color={props.theme}
                name={filter.id}
                value={(filters as any)[filter.id]}
                label={filter.label}
                items={[
                  { label: filter.label, value: "" },
                  ...(props.listFilters as any)[filter.id],
                ]}
                handleChange={(ev) => {
                  const { value, name } = ev.target;

                  const val = (props.listFilters as any)[filter.id].find(
                    (r: any) => r.value == value
                  );
                  if (
                    (filters as any)[name].indexOf(
                      (i: any) => i.value == value
                    ) > 0
                  ) {
                    return;
                  }
                  const _filters = { ...filterSelected };
                  (_filters as any)[name] = [val];
                  setFilters(_filters);
                  props.handleFilter(_filters);
                  setTotalItems(0);
                }}
                size="sm"
              />
            </div>
          ))}
      </div>
      {filterSelected &&
          ((filterSelected?.rotulo?.length > 0) || filterSelected?.tipo?.length > 0) &&
          (<div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: 26, width: '1246px', margin: '0 auto' }}>
            {filterSelected?.rotulo?.map((item: any, index: number) => (
              <Tags key={index} title={`${item.label} (${totalItems ?? 0})`} onClose={() => handleRemove(item, 'rotulo')} />
            ))}
            {filterSelected?.tipo?.map((item: any, index: number) => (
              <Tags key={index} title={`${item.label} (${totalItems ?? 0})`} onClose={() => handleRemove(item, 'tipo')} />
            ))}        
          </div>)}
           
      <div>
        <label data-possui-certificador-filter htmlFor="possuiCertificado">
          <input
            type="checkbox"
            name="possuiCertificado"
            id="possuiCertificado"
            defaultChecked={filters.possuiCertificado}
            onChange={(ev) => {
              const _filters = filters;
              _filters.possuiCertificado = ev.target.checked;

              setFilters(_filters);
              props.handleFilter(_filters);
            }}
          />{" "}
          Exibir apenas conteúdo com declaração de participação
        </label>
      </div>
    </div>
  );
};
