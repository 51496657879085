"use client";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from "./ui/dialog";
import { Button } from "./ui/button";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./ui/accordion";
import { Checkbox } from "./ui/checkbox";
import { useEffect, useState } from "react";
import qs from "qs";
import { Badge } from "./ui/badge";
import { DomainModel } from "../../models/DomainModal";
import { ParametrosBuscaModel } from "../../models/ParametroBuscaModel";
import { useHistory, useParams } from "react-router";
import { OrderType } from "../../models/orderType";
import * as Icon from "../../assets/svgs";
import { X } from "lucide-react";

export type TypeFilter = "SEARCH" | "AREA_INTERESSE_USUARIO" | "PATROCINADOR" | "METODO_ENSINO" | "SUB_METODO_ENSINO" | "AREA_INTERESSE";

export default function Filters({
  parametros,
  order,
  onChangeOrder,
  typeFilter = "SEARCH",
}: {
  parametros?: ParametrosBuscaModel;
  order: OrderType;
  onChangeOrder: (order: OrderType) => void;
  typeFilter?: TypeFilter;
}) {
  const history = useHistory();
  const [checkedFilters, setCheckedFilters] = useState<{
    areaDeInteresses: number[];
    profissoes: number[];
    metodosDeEnsino: number[];
    niveisMaturidade: number[];
    tipoMidia: number[];
    sort: string;
    possuiCertificado?: boolean;
  }>({
    areaDeInteresses: [],
    profissoes: [],
    metodosDeEnsino: [],
    niveisMaturidade: [],
    tipoMidia: [],
    sort: order?.toString() || "",
  });

  const searchParams = useParams<{
    a?: string;
    p?: string;
    m?: string;
    n?: string;
    t?: string;
    s?: string;
    c?: string;
  }>();

  useEffect(() => {
    const a: any = searchParams.a;
    const p: any = searchParams.p;
    const m: any = searchParams.m;
    const n: any = searchParams.n;
    const t: any = searchParams.t;
    const s: any = searchParams.s;
    const c: any = searchParams.c;

    if (a || p || m || n || t || s || c) {
      setCheckedFilters({
        areaDeInteresses: a ? a.map((item: any) => parseInt(item)) : [],
        profissoes: p ? p.map((item: any) => parseInt(item)) : [],
        metodosDeEnsino: m ? m.map((item: any) => parseInt(item)) : [],
        niveisMaturidade: n ? n.map((item: any) => parseInt(item)) : [],
        tipoMidia: t ? t.map((item: any) => parseInt(item)) : [],
        sort: s ? s : "",
        possuiCertificado: c ? c == "true" : undefined,
      });

      if (s) {
        onChangeOrder(s as any as OrderType);
      }
    }
  }, [searchParams]);

  function handleClear() {
    setCheckedFilters({
      areaDeInteresses: [],
      metodosDeEnsino: [],
      niveisMaturidade: [],
      profissoes: [],
      sort: "",
      tipoMidia: [],
      possuiCertificado: false,
    });
  }

  function applyFilters() {
    const params = {
      a: checkedFilters.areaDeInteresses,
      p: checkedFilters.profissoes,
      m: checkedFilters.metodosDeEnsino,
      n: checkedFilters.niveisMaturidade,
      t: checkedFilters.tipoMidia,
      s: checkedFilters.sort || order?.toString(),
      c: checkedFilters.possuiCertificado,
    };

    const queryString = qs.stringify(
      { ...params },
      {
        arrayFormat: "repeat",
        encodeValuesOnly: true,
        encode: true,
      },
    );

    // eslint-disable-next-line no-restricted-globals
    history.push(`${location.pathname}?${queryString}`);
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          data-testid="btn-filtro"
          variant="outline"
          size="icon"
          className="leading-4.5 flex gap-2  border-gray-300 text-base font-semibold text-black-900 shadow-none"
        >
          <Icon.FilterIconNew />
          Filtro
        </Button>
      </DialogTrigger>
      <DialogContent className="flex h-full w-full flex-col gap-0 p-0 md:h-[526px] md:min-h-[526px] md:min-w-[700px]">
        <DialogHeader className="relative flex h-16 items-center justify-center border-b md:h-24 tablet:h-20">
          <DialogTitle className="flex text-base font-bold leading-22 text-[#004F92]">
            Filtrar
          </DialogTitle>{" "}
        </DialogHeader>
        <div className="flex h-full justify-center overflow-y-auto px-9 md:px-11">
          <Accordion type="single" collapsible className="w-full">
            {(typeFilter === "SEARCH" || typeFilter === "SUB_METODO_ENSINO") && (
              <AccordionItem value="item-1" className="">
                <AccordionTrigger>
                  <h3 className="leading-4.5 text-sm font-semibold text-black-900">
                    Área de Interesses
                  </h3>
                </AccordionTrigger>
                {checkedFilters.areaDeInteresses.length > 0 && (
                  <div className="flex flex-wrap gap-1 pb-4">
                    {checkedFilters.areaDeInteresses.map(
                      (id: number, index: number) => {
                        const area = parametros?.areaDeInteresses.find(
                          (item: DomainModel) => item.id === id,
                        );

                        return (
                          <Badge
                            className="rounded-full"
                            onClick={() => {
                              setCheckedFilters((prevState: any) => ({
                                ...prevState,
                                areaDeInteresses:
                                  prevState.areaDeInteresses.filter(
                                    (item: number) => item !== id,
                                  ),
                              }));
                            }}
                            key={index}
                            variant="outline"
                          >
                            {area?.descricao}
                          </Badge>
                        );
                      },
                    )}
                  </div>
                )}
                <AccordionContent>
                  {
                    <div className="grid gap-2 md:grid-cols-2 ">
                      {parametros?.areaDeInteresses.map(
                        (area: DomainModel, index: number) => (
                          <div
                            key={index}
                            className="flex items-center space-x-2"
                          >
                            <Checkbox
                              defaultChecked={checkedFilters.areaDeInteresses.includes(
                                area.id,
                              )}
                              checked={checkedFilters.areaDeInteresses.includes(
                                area.id,
                              )}
                              onCheckedChange={(checked: boolean) => {
                                setCheckedFilters((prevState: any) => ({
                                  ...prevState,
                                  areaDeInteresses: checked
                                    ? [...prevState.areaDeInteresses, area.id]
                                    : prevState.areaDeInteresses.filter(
                                      (item: number) => item !== area.id,
                                    ),
                                }));
                              }}
                              className="h-5 w-5 rounded-[3px]"
                            />
                            <label
                              htmlFor="terms"
                              className="text-sm font-normal leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                            >
                              {area.descricao}
                            </label>
                          </div>
                        ),
                      )}
                    </div>
                  }
                </AccordionContent>
              </AccordionItem>
            )}
            {(typeFilter === "SEARCH" || typeFilter === "METODO_ENSINO" || typeFilter === "AREA_INTERESSE") && (
              <AccordionItem value="item-2">
                <AccordionTrigger>
                  <h3 className="leading-4.5 text-sm font-semibold text-black-900">
                    Profissões
                  </h3>
                </AccordionTrigger>
                {checkedFilters.profissoes.length > 0 && (
                  <div className="flex flex-wrap gap-1 pb-4">
                    {checkedFilters.profissoes.map(
                      (id: number, index: number) => {
                        const profissao = parametros?.profissoes.find(
                          (item: DomainModel) => item.id === id,
                        );

                        return (
                          <Badge
                            className="rounded-full"
                            onClick={() => {
                              setCheckedFilters((prevState) => ({
                                ...prevState,
                                profissoes: prevState.profissoes.filter(
                                  (item: number) => item !== id,
                                ),
                              }));
                            }}
                            key={index}
                            variant="outline"
                          >
                            {profissao?.descricao}
                          </Badge>
                        );
                      },
                    )}
                  </div>
                )}
                <AccordionContent>
                  {
                    <div className="grid gap-2 md:grid-cols-2">
                      {parametros?.profissoes.map(
                        (profissao: DomainModel, index: number) => (
                          <div
                            key={index}
                            className="flex items-center space-x-2"
                          >
                            <Checkbox
                              defaultChecked={checkedFilters.profissoes.includes(
                                profissao.id,
                              )}
                              checked={checkedFilters.profissoes.includes(
                                profissao.id,
                              )}
                              onCheckedChange={(checked: boolean) => {
                                setCheckedFilters((prevState: any) => ({
                                  ...prevState,
                                  profissoes: checked
                                    ? [...prevState.profissoes, profissao.id]
                                    : prevState.profissoes.filter(
                                      (item: number) => item !== profissao.id,
                                    ),
                                }));
                              }}
                              className="h-5 w-5 rounded-[3px] !border-[1.8px] shadow-none"
                            />
                            <label
                              htmlFor="terms"
                              className="text-sm font-normal leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                            >
                              {profissao.descricao}
                            </label>
                          </div>
                        ),
                      )}
                    </div>
                  }
                </AccordionContent>
              </AccordionItem>
            )}
            {(typeFilter !== "METODO_ENSINO" && typeFilter !== "SUB_METODO_ENSINO") && (
              <AccordionItem value="item-3">
                <AccordionTrigger>
                  <h3 className="leading-4.5 text-sm font-semibold text-black-900">
                    Método de ensino
                  </h3>
                </AccordionTrigger>
                {checkedFilters.metodosDeEnsino.length > 0 && (
                  <div className="flex flex-wrap gap-1 pb-4">
                    {checkedFilters.metodosDeEnsino.map(
                      (id: number, index: number) => {
                        const metodoDeEnsino = parametros?.metodosDeEnsino.find(
                          (item: DomainModel) => item.id === id,
                        );

                        return (
                          <Badge
                            className="rounded-full"
                            onClick={() => {
                              setCheckedFilters((prevState) => ({
                                ...prevState,
                                metodosDeEnsino: prevState.metodosDeEnsino.filter(
                                  (item: number) => item !== id,
                                ),
                              }));
                            }}
                            key={index}
                            variant="outline"
                          >
                            {metodoDeEnsino?.descricao}
                          </Badge>
                        );
                      },
                    )}
                  </div>
                )}
                <AccordionContent>
                  {
                    <div className="grid gap-2 md:grid-cols-2">
                      {parametros?.metodosDeEnsino.map(
                        (metodoEnsino: DomainModel, index: number) => (
                          <div
                            key={index}
                            className="flex items-center space-x-2"
                          >
                            <Checkbox
                              defaultChecked={checkedFilters.metodosDeEnsino.includes(
                                metodoEnsino.id,
                              )}
                              checked={checkedFilters.metodosDeEnsino.includes(
                                metodoEnsino.id,
                              )}
                              onCheckedChange={(checked: boolean) => {
                                setCheckedFilters((prevState: any) => ({
                                  ...prevState,
                                  metodosDeEnsino: checked
                                    ? [
                                      ...prevState.metodosDeEnsino,
                                      metodoEnsino.id,
                                    ]
                                    : prevState.metodosDeEnsino.filter(
                                      (item: number) =>
                                        item !== metodoEnsino.id,
                                    ),
                                }));
                              }}
                              className="h-5 w-5 rounded-[3px] border-[1.8px] shadow-none"
                            />
                            <label
                              htmlFor="terms"
                              className="text-sm font-normal leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                            >
                              {metodoEnsino.descricao}
                            </label>
                          </div>
                        ),
                      )}
                    </div>
                  }
                </AccordionContent>
              </AccordionItem>
            )}
            {(typeFilter === "SEARCH" ||
              typeFilter === "AREA_INTERESSE_USUARIO") && (
                <AccordionItem value="item-4">
                  <AccordionTrigger>
                    <h3 className="leading-4.5 text-sm font-semibold text-black-900">
                      Nível de maturidade
                    </h3>
                  </AccordionTrigger>
                  {checkedFilters.niveisMaturidade.length > 0 && (
                    <div className="flex flex-wrap gap-1 pb-4">
                      {checkedFilters.niveisMaturidade.map(
                        (id: number, index: number) => {
                          const niveisMaturidade =
                            parametros?.niveisMaturidade.find(
                              (item: DomainModel) => item.id === id,
                            );

                          return (
                            <Badge
                              className="rounded-full"
                              onClick={() => {
                                setCheckedFilters((prevState) => ({
                                  ...prevState,
                                  niveisMaturidade:
                                    prevState.niveisMaturidade.filter(
                                      (item: number) => item !== id,
                                    ),
                                }));
                              }}
                              key={index}
                              variant="outline"
                            >
                              {niveisMaturidade?.descricao}
                            </Badge>
                          );
                        },
                      )}
                    </div>
                  )}
                  <AccordionContent>
                    {
                      <div className="grid gap-2 md:grid-cols-2">
                        {parametros?.niveisMaturidade.map(
                          (nivelMaturidade: DomainModel, index: number) => (
                            <div
                              key={index}
                              className="flex items-center space-x-2"
                            >
                              <Checkbox
                                defaultChecked={checkedFilters.niveisMaturidade.includes(
                                  nivelMaturidade.id,
                                )}
                                checked={checkedFilters.niveisMaturidade.includes(
                                  nivelMaturidade.id,
                                )}
                                onCheckedChange={(checked: boolean) => {
                                  setCheckedFilters((prevState: any) => ({
                                    ...prevState,
                                    niveisMaturidade: checked
                                      ? [
                                        ...prevState.niveisMaturidade,
                                        nivelMaturidade.id,
                                      ]
                                      : prevState.niveisMaturidade.filter(
                                        (item: number) =>
                                          item !== nivelMaturidade.id,
                                      ),
                                  }));
                                }}
                                className="h-5 w-5 rounded-[3px] border-[1.8px] shadow-none"
                              />
                              <label
                                htmlFor="terms"
                                className="text-sm font-normal leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                              >
                                {nivelMaturidade.descricao}
                              </label>
                            </div>
                          ),
                        )}
                      </div>
                    }
                  </AccordionContent>
                </AccordionItem>
              )}
            {(typeFilter !== "SUB_METODO_ENSINO") && (
              <AccordionItem value="item-5">
                <AccordionTrigger>
                  <h3 className="leading-4.5 text-sm font-semibold text-black-900">
                    Tipo de mídia
                  </h3>
                </AccordionTrigger>
                {checkedFilters.tipoMidia.length > 0 && (
                  <div className="flex flex-wrap gap-1 pb-4">
                    {checkedFilters.tipoMidia.map((id: number, index: number) => {
                      const tipoMidia = parametros?.tipoMidia.find(
                        (item: DomainModel) => item.id === id,
                      );

                      return (
                        <Badge
                          className="rounded-full"
                          onClick={() => {
                            setCheckedFilters((prevState) => ({
                              ...prevState,
                              tipoMidia: prevState.tipoMidia.filter(
                                (item: number) => item !== id,
                              ),
                            }));
                          }}
                          key={index}
                          variant="outline"
                        >
                          {tipoMidia?.descricao}
                        </Badge>
                      );
                    })}
                  </div>
                )}
                <AccordionContent>
                  {
                    <div className="grid gap-2 md:grid-cols-2">
                      {parametros?.tipoMidia.map(
                        (tipo: DomainModel, index: number) => (
                          <div
                            key={index}
                            className="flex items-center space-x-2"
                          >
                            <Checkbox
                              defaultChecked={checkedFilters.tipoMidia.includes(
                                tipo.id,
                              )}
                              checked={checkedFilters.tipoMidia.includes(tipo.id)}
                              onCheckedChange={(checked: boolean) => {
                                setCheckedFilters((prevState: any) => ({
                                  ...prevState,
                                  tipoMidia: checked
                                    ? [...prevState.tipoMidia, tipo.id]
                                    : prevState.tipoMidia.filter(
                                      (item: number) => item !== tipo.id,
                                    ),
                                }));
                              }}
                              className="h-5 w-5 rounded-[3px] border-[1.8px] shadow-none"
                            />
                            <label
                              htmlFor="terms"
                              className="text-sm font-normal leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                            >
                              {tipo.descricao}
                            </label>
                          </div>
                        ),
                      )}
                    </div>
                  }
                </AccordionContent>
              </AccordionItem>
            )}
            {(typeFilter === "SEARCH" || typeFilter === "AREA_INTERESSE") && (
              <div className="flex items-center space-x-2 py-4">
                <Checkbox
                  id="terms"
                  className="h-5 w-5 rounded-[3px] border-[1.8px] shadow-none"
                  defaultChecked={checkedFilters.possuiCertificado}
                  checked={checkedFilters.possuiCertificado}
                  onCheckedChange={(checked: boolean) => {
                    setCheckedFilters((prevState: any) => ({
                      ...prevState,
                      possuiCertificado: checked,
                    }));
                  }}
                />
                <label
                  htmlFor="terms"
                  className="text-sm font-medium leading-none text-[#111316] peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  Exibir apenas conteúdos com declaração de participação
                </label>
              </div>
            )}
          </Accordion>
        </div>
        <DialogFooter className="h-auto border-t py-[18px] md:py-5 tablet:py-3">
          <div className="flex w-full items-center justify-evenly md:justify-between md:px-24">
            <Button
              data-testid="btn-limpar-filtro"
              variant="link"
              size="none"
              onClick={() => handleClear()}
              className="leading-4.5 flex gap-1 text-sm font-semibold text-brand-primary"
            >
              <Icon.TrashNew />
              Limpar Filtros
            </Button>
            <DialogClose>
              <Button
                data-testid="btn-aplicar-filtro"
                onClick={applyFilters}
                variant="primary"
                size="default"
              >
                Aplicar Filtros
              </Button>
            </DialogClose>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
