import Container from "../../shared/components/Container";
import * as Icon from "../../assets/svgs";
import Vimeo from "@u-wave/react-vimeo";
import { useModalDegustacaoPos } from "../../hooks/useModalDegustacaoPos";
import { useModalComplementoCadastro } from "../../hooks/useModalComplementoCadastro";
import { UserContext } from "../../context/UserContext";
import { useContext, useEffect, useState } from "react";

import { mostrarModalLogin } from "../../shared/modals/ModalLogin";
import { useLocation } from "react-router";
import { fetchApi } from "../../shared/services/rest-dotnet.service";
import { DegustacaoPosModel } from "../../models/DegustacaoPosModel";
import { Button } from "../../shared/components/ui/button";

export default function DegustacaoPos() {
  const modalDegustacaoPos = useModalDegustacaoPos();
  const modalComplementoCadastro = useModalComplementoCadastro();
  const { user, jwt } = useContext(UserContext);
  const location = useLocation();

  const [originState, setOriginState] = useState<string>(
    location.search as string,
  );

  const [loading, setLoading] = useState<boolean>(false);

  const criarCadastroDegustacao = () => {
    fetchApi(
      "degustacaopos/criarDegustacaoPos",
      "POST",
      JSON.stringify({
        documento: "",
        documentoInternacional: false,
        ignorar: true,
      }),
      jwt,
    )
      .then((resultado: any) => {
        if (resultado.ok) {
          modalDegustacaoPos.mostrar();
        } else {
          alert("Erro ao criar degustação, tente novamente");
        }
      })
      .catch((error) => {
        alert("Erro ao criar degustação, tente novamente");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const vertificaCadastroUsuario = () => {
    setLoading(true);
    fetchApi("degustacaopos/verificaCadastroUsuario", "GET").then(
      (resultado: any) => {
        resultado
          .json()
          .then((data: DegustacaoPosModel[]) => {
            if (data.some((item) => item.usuarioInscrito === true)) {
              modalDegustacaoPos.mostrar();
              setLoading(false);
            } else if (data.some((item) => item.cpfCadastrado === false)) {
              setLoading(false);
              modalComplementoCadastro.mostrar();
            } else {
              criarCadastroDegustacao();
            }
          })
          .catch((erro: any) => {
            alert("Erro ao criar degustação, tente novamente");
            setLoading(false);
          });
      },
    );
  };

  function handleQueroExperimentar() {
    if (loading) return;
    if (!user) {
      mostrarModalLogin();
    } else {
      vertificaCadastroUsuario();
    }
  }

  useEffect(() => {
    if (originState && user) {
      handleQueroExperimentar();
    }
  }, [originState, user]);

  return (
    <div className="">
      <span className="sr-only">Experiência EAD Einstein</span>
      <span className="sr-only">
        Experimente um conteúdo exclusivo da pós-graduação a distância e conheça
        nossa plataforma de ensino.
      </span>
      <ul className="sr-only">
        <li className="sr-only">Certificado de conclusão</li>
        <li className="sr-only">
          Navegue e explore nosso ambiente virtual de aprendizagem
        </li>
        <li className="sr-only">
          Conheça nossos conteúdos e recursos educacionais
        </li>
      </ul>
      <Button
        variant="pagination"
        data-testid="btn-quero-experimentar"
        className="sr-only"
      >
        Quero experimentar
      </Button>

      <Icon.bgBanner
        data-testid="ban-quero-experimentar"
        className="hidden h-full w-full cursor-pointer md:block "
        onClick={() => handleQueroExperimentar()}
      />
      <Icon.bgBannerMobile
        data-testid="ban-mobile-quero-experimentar"
        className="flex h-full w-full cursor-pointer md:!hidden"
        onClick={() => handleQueroExperimentar()}
      />
      <div className="flex h-[54px] w-full items-center justify-center bg-[#00B193] px-8 py-2 md:h-[40px] ">
        <span className="text-center text-sm font-semibold text-[#FFFFFF] md:text-base">
          Conheça na prática nossa metodologia de ensino a distância da{" "}
          <span className="text-sm font-extrabold md:text-base">
            {" "}
            Pós-graduação.
          </span>
        </span>
      </div>
      <div className="w-full bg-[#F5F8FF]">
        <Container className="py-14">
          <div className="flex flex-col-reverse items-center justify-evenly gap-6 md:flex-row md:gap-0">
            <div className="w-full md:w-[364px]">
              <p className="font-normal leading-[22px] text-[#111316] md:text-base">
                A Experiência EAD Einstein foi construída para que você{" "}
                <span className="font-semibold">
                  explore a metodologia, as dinâmicas
                </span>{" "}
                e{" "}
                <span className="font-semibold  ">
                  os recursos presentes na Pós-graduação a Distância do
                  Einstein.
                </span>
              </p>
              <p className="mt-6 font-normal leading-[22px] text-[#111316] md:text-base">
                {" "}
                Ao longo da jornada, você terá a oportunidade de conhecer a
                estrutura dos nossos conteúdos, interagir com o ambiente virtual
                de aprendizagem que utilizamos e compreender como será a sua
                jornada. Tudo isso para proporcionar a você uma visão abrangente
                e esclarecedora do que esperar ao ingressar em nossos cursos
                EAD.
              </p>
              <div className="flex w-full justify-center md:justify-start">
                <Button
                  data-testid="btn-quero-experimentar"
                  disabled={loading}
                  onClick={() => {
                    handleQueroExperimentar();
                  }}
                  className="relative mt-4 h-[58px] w-[249px] self-center rounded-[26px] bg-[#004F92] font-semibold text-[#FFFFFF] hover:bg-[#3777af] md:mt-12	md:!text-base "
                >
                  Quero experimentar
                </Button>
              </div>
            </div>
            <div className="flex h-auto w-full items-start overflow-hidden !rounded-[7.98px] md:h-[270.44px] md:w-[485px]">
              <Vimeo
                pip={true}
                video={"https://vimeo.com/917137342/1d265e8f8b?share=copy"}
                responsive
                className="h-full w-full !rounded-[7.98px]"
              />
            </div>
          </div>
        </Container>
      </div>
      <div className="bg-[#FFFFFF]">
        <Container className="w-full py-16  md:w-[946px] md:py-24">
          <span className="max-w-[300px] text-xl font-normal leading-7 text-[#111316] md:max-w-[752px] md:text-2xl md:leading-[29.26px]">
            Nosso modelo EAD foi cuidadosamente pensado para levar a{" "}
            <br className="hidden md:flex" />
            sala de aula até você, e está fundamentado em{" "}
            <span className="font-semibold"> três pilares:</span>
          </span>

          <div className="mt-6 flex flex-col gap-3">
            <div className="shadow rounded-xl p-8">
              <div className="flex flex-col items-center  gap-6 md:flex-row">
                <div>
                  <Icon.PuzzlePiece className=" mx-auto" />
                </div>
                <div className="text-base font-normal leading-[22px] text-[#111316]">
                  <p className="font-semibold">
                    Aprendizagem baseada em casos e situações-problema
                  </p>
                  <p className="mt-4 leading-[22px] md:mt-0">
                    Ao longo de sua jornada de estudos, você será desafiado com
                    exercícios de resolução de
                    <br className="hidden md:flex" /> problemas desenhados para
                    acelerar e reforçar o seu aprendizado.
                  </p>
                </div>
              </div>
            </div>

            <div className="shadow rounded-xl p-8">
              <div className="flex flex-col items-center gap-6 md:flex-row">
                <div>
                  <Icon.Presentation className=" mx-auto" />
                </div>
                <div className="text-base font-normal leading-[22px] text-[#111316]">
                  <p className="font-semibold">Aprendizagem ativa</p>
                  <p className="mt-4 leading-[22px] md:mt-0">
                    A cada semana você irá se envolver em uma nova unidade, por
                    meio de nosso conteúdo para
                    <br className="hidden md:flex" /> estudo autodirigido, e
                    aplicar seus conhecimentos em momentos de prática e reflexão
                    e<br /> webconferências (síncronas) com nossos
                    especialistas.
                  </p>
                </div>
              </div>
            </div>

            <div className="shadow rounded-xl p-8">
              <div className="flex flex-col items-center gap-6 md:flex-row">
                <div>
                  <Icon.UsersThree className=" mx-auto" />
                </div>
                <div className="text-base font-normal leading-[22px] text-[#111316]">
                  <p className="font-semibold">Aprendizagem social</p>
                  <p className="mt-4 leading-[22px] md:mt-0">
                    Durante todo o curso você poderá trocar ideias com seus
                    colegas de classe, ampliar a sua
                    <br className="hidden md:flex" /> perspectiva e rede de
                    contatos.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className="bg-[#F5F8FF]">
        <Container className="py-6 md:py-24">
          <div className="flex flex-col justify-evenly md:flex-row">
            <span className="w-[276px] text-2xl font-semibold text-[#004F92] md:text-4xl">
              <Icon.UsersStar />
              Competências do Líder em Saúde
            </span>
            <div className="mt-4 w-full md:mt-0 md:w-[558px] ">
              <p className="text-sm font-normal md:text-[18.28px]">
                O tema escolhido para a Experiência EAD Einstein está alinhado à
                atual expansão e transformação do mercado de saúde, e a
                necessidade urgente de líderes de saúde qualificados.
              </p>
              <p className="mt-6 text-sm font-normal md:text-[18.28px]">
                No curso Competências do Líder em Saúde, você terá a
                oportunidade de{" "}
                <span className="font-semibold text-[#626466]">
                  aprimorar habilidades fundamentais para
                </span>{" "}
                a{" "}
                <span className="font-semibold text-[#626466]">
                  liderança na área da saúde.{" "}
                  <span className="text-[#004F92]">
                    {" "}
                    Ao final, receberá um certificado de conclusão.
                  </span>
                </span>
              </p>
              <div className="flex w-full justify-center md:justify-start">
                <Button
                  data-testid="btn-quero-experimentar"
                  onClick={() => {
                    handleQueroExperimentar();
                  }}
                  disabled={loading}
                  className="relative mt-4 h-[58px] w-[249px] rounded-[26px] bg-[#004F92] text-sm font-semibold text-[#FFFFFF] hover:bg-[#3777af] md:mt-12	md:!text-base "
                >
                  Quero experimentar
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}
