import React, { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { withStyles } from "@material-ui/styles";
import { Theme, Tooltip } from "@material-ui/core";

import InfoPatrocinadorBrancoHover from "../../assets/svgs/info-patrocinador-branco-hover.svg";
import NenhumItem from "../../assets/svgs/nenhum_item.svg";

import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../hooks/useGtm";
import {
  Loader,
} from "../../shared/components";

import { UserContext } from "../../context/UserContext";

import "./styles.scss";
import { useScrollTop } from "../../shared/hooks/useScrollTop";
import Container from "../../shared/components/Container";
import { Breadcrumb, BreadcrumbItem,BreadcrumbLink, BreadcrumbList, BreadcrumbSeparator } from "../../shared/components/ui/breadcrumb";
import Menu from "../../shared/components/menu";
import { Root, ObjetoEducacional } from "../../models/ObjetoEducacional";
import { fetchApi } from "../../shared/services/rest-dotnet.service";
import CardObjetoEducacional from "../../shared/components/oe-card";
import Pagination from "@mui/material/Pagination";
import { PatrocinadorModel } from "../../models/PatrocinadorModel";

interface SearchProps {
  m?: string;
  t?: string;
  s?: string;
  patrocinadorId: number;
  page?: number;
}

async function searchObjetosEducacionais({
  m,
  t,
  s,
  patrocinadorId,
  page,
}: SearchProps): Promise<Root> {
  const params = {
    metodosDeEnsino: Array.isArray(m) ? m : m ? [m] : [],
    tipoMidia: Array.isArray(t) ? t : t ? [t] : [],
    ordernar: s,
    patrocinadorId,
    pageIndex: page || 1,
    pageSize: 15,
  };

  const response = await fetchApi(
    "objeto-educacional/get-oe-patrocinador",
    "POST",
    JSON.stringify(params),
  ).then((res) => {
    if (res) {
      return res.json();
    } else {
      return {
        items: [],
        totalItems: 0,
        pageIndex: 0,
        pageSize: 0,
        totalPages: 0,
      } as Root;
    }
  });

  return response;
}

async function getPatrocinador(id: number): Promise<PatrocinadorModel> {

  const response = await fetchApi(
    `patrocinador/get-patrocinador-by-id/${id}`,
    "GET"
  ).then((res) => {
    if (res) {
      return res.json();
    } else {
      return {
        id: 0,
        nome: ""
      } as PatrocinadorModel;
    }
  });

  return response;
}
export const PatrocinadorPage: React.FC = () => {
  const gtm = useGtm();
  const params = useParams<{ name: string; id: string }>();
  const { user, loadingUser } = useContext(UserContext);

  const [patrocinador, setPatrocinador] = useState<PatrocinadorModel>();
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSearch, setLoadingSearch] = useState<boolean>(true);
  const [oes, setOes] = useState<ObjetoEducacional[]>([]);
  const [oesTotalPage, setOesTotalPages] = useState<number>(0);
  const [oesTotal, setOesTotal] = useState<number>(0);
  const scrollTop = useScrollTop();
  const [searchParams, setSearchParams] = useState<SearchProps>(
    {} as SearchProps,
  );
  const [page, setPage] = useState<number>(1);
  const location = useLocation();

  const loadObjetos = async (search: SearchProps) => {
    setLoadingSearch(true);
    setLoading(true);
    const resultado = await searchObjetosEducacionais(search || searchParams);
    setOes(resultado.items);
    setOesTotalPages(resultado.totalPages);
    setOesTotal(resultado.totalItems);
    setLoadingSearch(false);
    setLoading(false);
    if (resultado.items.length > 0) {
      //setPatrocinador(resultado.items[0].);
    }
  };

  const loadPatrocinador = async () => {
    const patrocinador = await getPatrocinador(Number(params.id!));
    setPatrocinador(patrocinador);
  };

  useEffect(() => {
    const queryLocation = new URLSearchParams(location.search);
    const search = {
      m: queryLocation.get("m") || undefined,
      t: queryLocation.get("t") || undefined,
      s: queryLocation.get("s") || undefined,
      patrocinadorId: Number(params.id!),
      page: page,
    };
    setSearchParams(search);
    loadObjetos(search);
  }, [location.search, page]);


  useEffect(() => {
    if (!!loadingUser) return;

    scrollTop();
    setLoadingSearch(true);
  }, [user, loadingUser]);

  useEffect(() => {
    loadPatrocinador();
    return () => {
      // GTM doc linha 64
      if (params && params.id) {
        gtm.push(
          EDataLayerEventCategory.PAGE_VIEW,
          EDataLayerEventAction.NAVEGACAO,
          {
            label: `area_patrocinador -- patrocinador: ${params.id}`,
          }
        );
      }
    };
  }, []);

  const PatrocinadorTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fontSize: 12,
      fontStyle: "italic",
    },
  }))(Tooltip);


  return (
    <>
      <Loader
        show={loading}
        message="Aguarde enquanto o conteúdo é carregado..."
      />
      <div
        style={{
          background: "linear-gradient(137.1deg, #107B95 6.6%, #1BB28E 98.07%)",
        }}
        className="flex h-32 w-full"
      >
      <Container className="!px-0 py-9">
          <Breadcrumb className="flex justify-start !text-xxs font-normal text-white-200 md:text-xs">
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbLink href="/">Home</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbLink href="/area-do-patrocinador">
                  Area do patrocinador
                </BreadcrumbLink>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
          <h2 className="flex flex-row leading-6.5 mt-3 text-base font-semibold normal-case text-white-200 md:text-1.5xl">
            {patrocinador?.nome}{" "}
            <PatrocinadorTooltip
              title="Este conteúdo foi desenvolvido pela Sociedade Beneficente Israelita Brasileira Albert Einstein e tem por objetivo a difusão de conhecimento técnico-científico acerca do tema apresentado, contribuindo com o desenvolvimento das atividades de saúde. A Sociedade se compromete com a transparência e com a imparcialidade do conteúdo acadêmico, livre de qualquer propósito comercial ou vinculação com contratos de aquisição de produtos de eventual apoiador ou patrocinador."
              placement="bottom-start"
              className="ml-2"
            >
              <img
                data-header-info
                src={InfoPatrocinadorBrancoHover}
                className="w-4 h-4 mt-0.5"
              />
            </PatrocinadorTooltip>
          </h2>
        </Container>
      </div>
      <Container className="h-full min-h-screen  py-9 pb-9">
        <div className="flex flex-col gap-10 items-center">
          <Menu qtdConteudos={oesTotal} typeFiltro="PATROCINADOR" />

          {oes && oesTotalPage > 1 && (
            <div className="flex w-full justify-end items-end">
              <Pagination
                count={oesTotalPage}
                onChange={(e, p) => setPage(p)}
                page={page}
                variant="outlined"
                color="primary"
              />
            </div>
          )}
          <div className="mt-5 grid grid-cols-1 gap-6 md:mt-0 md:grid-cols-3">
            {oes?.map((_: ObjetoEducacional, index: number) => (
              <div key={index} className="p-1">
                <CardObjetoEducacional objetoEducacional={_} />
              </div>
            ))}
          </div>
          {oes && oes.length === 0 && (
            <div className="flex flex-col justify-center items-center text-center w-full max-w-[43.87rem]">
              <img
                src={NenhumItem}
                className="h-52 mb-6"
              />
              <span className="text-[#004F92] font-semibold md:text-xxl tablet:text-xxl md:leading-26 tablet:leading-26 text-base leading-18">Não encontramos conteúdos com as suas preferências</span>
              <span className="text-[#626466] font-medium text-sm md:text-lg tablet:text-lg  mt-3 md:leading-22 tablet:leading-22 leading-4 ">Parece que não encontramos conteúdos com os filtros que você escolheu.
Tente ajustar ou selecionar novos filtros para ver mais opções!</span>
            </div>
          )}
          {oes && oesTotalPage > 1 && (
            <div className="flex w-full justify-end items-end">
              <Pagination
                count={oesTotalPage}
                onChange={(e, p) => setPage(p)}
                page={page}
                variant="outlined"
                color="primary"
              />
            </div>
          )}
        </div>
      </Container>
    </>
  );
};
