import React, { useState, useEffect, useContext } from "react";
import { useParams, useLocation } from "react-router-dom";

import {
  PerfilDeAutorEntity,
  SubAreaConhecimento,
  SubAreaConhecimentoEntity,
  useGetAuthorByIdLazyQuery,
} from "../../generated/graphql";

import { getSearchQuery } from "../../helpers/common";

import { BannerSection, CardSection, Loader } from "../../shared/components";
import OePesquisaCabecalho from "./components/index";

import { useQueryParams } from "../../hooks/useQuerystring";

import { UserContext } from "../../context/UserContext";

import "./styles.scss";
import { useScrollTop } from "../../shared/hooks/useScrollTop";

import {
  Breadcrumb,
  BreadcrumbItem,
  HeaderSection,
} from "../../shared/components";
import { useSubAreaInteresseSeach } from "../../hooks/useSubAreaInteresseSeach";

import Container from "../../shared/components/Container";
interface OePesquisaProps {
  location: any;
}

interface OePesquisaState {
  pagina: number;
  error: any;
  filters: any;
  search: string | null;
  sort: string;
}

export interface OeParametros {
  search?: string[];
  tiposOe?: string[];
  categorias?: string[];
  areaInteresse?: string[];
}

export interface SubArreasInteresseAutorLocationParams {
  idAreaInteresse?: number;
  nameAreaInteresse?: string;
}

export const SubAreasInteresseAutor: React.FC<OePesquisaProps> = (props) => {
  const scrollTop = useScrollTop();
  const { user } = useContext(UserContext);
  const params = useParams<{ idAutor: string }>();
  const [author, setAuthor] = useState<PerfilDeAutorEntity | undefined>(
    undefined
  );
  const { state: areaInteresse } =
    useLocation<SubArreasInteresseAutorLocationParams>();
  const { buscarOes, resultadoOE, carregandoOEs, offset } =
    useSubAreaInteresseSeach();
  const queryParamsObject = useQueryParams<OeParametros>();
  const [total, setTotal] = useState<number>(0);

  const [state, setState] = useState<OePesquisaState>({
    pagina: 1,
    error: null,
    filters: {
      palavraChave: "",
      areasAtuacao: [
        ...(areaInteresse?.idAreaInteresse
          ? [
            {
              attributes: { titulo: areaInteresse.nameAreaInteresse },
              id: areaInteresse.idAreaInteresse,
            },
          ]
          : []),
      ],
      metodoEnsino: [],
      tipoMidia: [],
      possuiDeclaracao: false,
    },
    sort: "publishedAt:asc",
    search: getSearchQuery(props.location.search).get("search"),
  });

  const [GetAuthor] = useGetAuthorByIdLazyQuery({
    variables: {
      id: params.idAutor || "0",
    },
    onCompleted: (data: any) => {
      setAuthor(data.perfilDeAutor?.data as PerfilDeAutorEntity);
    },
    onError: () => { },
  });

  useEffect(() => {
    GetAuthor();
  }, []);

  useEffect(() => {
    if (!carregandoOEs) {
      setTotal(
        resultadoOE?.reduce((a, p) => {
          let v = a || 0;
          v += p.attributes?.ObjetosEducacionais?.data.length || 0;
          return v;
        }, 0) || 0
      );

      setState({
        ...state,
        filters: {
          ...state.filters,
          areasAtuacao: state.filters.areasAtuacao?.map((i: any) => {
            const qtd =
              resultadoOE?.reduce((ant, prox) => {
                let value = ant || 0;
                value +=
                  prox.attributes?.ObjetosEducacionais?.data.reduce((a, p) => {
                    let v: number = a || 0;
                    v +=
                      p.attributes?.SubAreaConhecimento?.data.findIndex(
                        (e) =>
                          e.attributes?.area_de_conhecimento?.data?.id?.toString() ==
                          i.id.toString()
                      ) != -1
                        ? 1
                        : 0;
                    return v;
                  }, 0) || 0;
                return value;
              }, 0) || 0;
            return {
              ...i,
              qtd,
            };
          }),
          metodoEnsino: state.filters.metodoEnsino?.map((i: any) => {
            const qtd =
              resultadoOE?.reduce((ant, prox) => {
                let value = ant || 0;
                value +=
                  prox.attributes?.ObjetosEducacionais?.data.reduce((a, p) => {
                    let v = a || 0;
                    v +=
                      p.attributes?.Rotulos?.data.findIndex(
                        (e) => e.id == i.id
                      ) != -1
                        ? 1
                        : 0;
                    return v;
                  }, 0) || 0;
                return value;
              }, 0) || 0;
            return {
              ...i,
              qtd,
            };
          }),
          tipoMidia: state.filters.tipoMidia?.map((i: any) => {
            const qtd =
              resultadoOE?.reduce((ant, prox) => {
                let value = ant || 0;
                value +=
                  prox.attributes?.ObjetosEducacionais?.data.reduce((a, p) => {
                    let v = a || 0;
                    v +=
                      p.attributes?.TipoObjeto?.data.findIndex(
                        (e) => e.id == i.id
                      ) != -1
                        ? 1
                        : 0;
                    return v;
                  }, 0) || 0;
                return value;
              }, 0) || 0;
            return {
              ...i,
              qtd,
            };
          }),
        },
      });
    }
  }, [carregandoOEs]);

  useEffect(() => search(), [props.location.search]);

  const search = (page: number = 0, _state?: OePesquisaState) => {
    const _ = _state || state;

    const keyWord =
      !!queryParamsObject && !!queryParamsObject.search
        ? queryParamsObject.search[0]
        : "";

    let where: any = {
      where: {
        ObjetosEducacionais: {
          Autores: {
            id: {
              eq: params.idAutor,
            },
          },
        },
      },
    };

    const whereObjetoEducacional: any = {
      Autores: {
        id: {
          eq: params.idAutor,
        },
      },
    };

    if (!!_.filters.tipoOe) {
      where["TipoObjeto"] = [parseInt(_.filters.tipoOe)];
    }

    if (!!_.filters.possuiDeclaracao) {
      where.PossuiCertificado = true;
    }

    if (!!keyWord) {
      where["Titulo_contains"] = keyWord;
    }

    const whereUser = {
      id: "0",
    };

    if (!!user) {
      whereUser: {
        id: {
          eq: user.id!;
        }
      }
    }
    scrollTop();

    let variables: any = { where: {}, whereObjetoEducacional: {} };
    /*const publicoAlvo = _.filters.areasAtuacao.map((a: any) => Number(a.id));
        if (publicoAlvo && publicoAlvo.length > 0) {
            variables["where"] = {
                ObjetosEducacionais: {
                    PublicoAlvo: {
                        id: {
                            in: publicoAlvo
                        }
                    },
                    ...where.ObjetosEducacionais
                }
            }

            variables["whereObjetoEducacional"] = {
                PublicoAlvo: {
                    id: {
                        in: publicoAlvo
                    } 
                },
                ...whereObjetoEducacional
            }
        }*/

    variables = {
      ...variables,
      whereObjetoEducacional: {
        ...variables.whereObjetoEducacional,
        ...whereObjetoEducacional,
      },
    };

    if (
      areaInteresse &&
      areaInteresse.idAreaInteresse &&
      (variables?.where as any)["area_de_conhecimento"]
    ) {
      //(variables.where as any)["area_de_conhecimento"] = { id: { eq: areaInteresse?.idAreaInteresse } };
      (variables.whereObjetoEducacional as any)["SubAreaConhecimento"][
        "area_de_conhecimento"
      ] = { id: { eq: areaInteresse?.idAreaInteresse } };
    } else {
      where = {
        where: {
          ...where.where,
          area_de_conhecimento: { id: { eq: areaInteresse?.idAreaInteresse } },
        },
      };
      if (areaInteresse && areaInteresse.idAreaInteresse) {
        variables.whereObjetoEducacional = {
          ...variables.whereObjetoEducacional,
          SubAreaConhecimento: {
            area_de_conhecimento: {
              id: { eq: areaInteresse?.idAreaInteresse },
            },
          },
        };
      }
    }

    const metodosEnsino = _.filters.metodoEnsino.map((a: any) => Number(a.id));
    if (metodosEnsino && metodosEnsino?.length > 0) {
      (variables.where as any)["ObjetosEducacionais"] = {
        ...(variables.where as any)["ObjetosEducacionais"],
        Rotulos: {
          id: {
            in: metodosEnsino,
          },
        },
      };

      variables.whereObjetoEducacional = {
        ...variables.whereObjetoEducacional,
        Rotulos: {
          id: {
            in: metodosEnsino,
          },
        },
      };
    }

    const tipoMidia = _.filters.tipoMidia.map((a: any) => a?.id);

    if (tipoMidia && tipoMidia?.length > 0) {
      (variables.where as any)["ObjetosEducacionais"] = {
        ...(variables.where as any)["ObjetosEducacionais"],
        TipoObjeto: {
          id: {
            in: tipoMidia,
          },
        },
      };

      variables.whereObjetoEducacional = {
        ...variables.whereObjetoEducacional,
        TipoObjeto: {
          id: {
            in: tipoMidia,
          },
        },
      };
    }

    if (_.filters.possuiDeclaracao) {
      (variables.where as any)["ObjetosEducacionais"] = {
        ...(variables.where as any)["ObjetosEducacionais"],
        PossuiCertificado: true,
      };

      variables.whereObjetoEducacional = {
        ...variables.whereObjetoEducacional,
        PossuiCertificado: true,
      };
    }

    buscarOes(page, _.sort, undefined, whereUser, where, 99999, variables);
  };

  const handlePageChange = (event: any, page: number) => {
    event.preventDefault();
    setState({
      ...state,
      pagina: page,
    });
    search(page - 1 === 0 ? 0 : page * offset - offset);
  };

  const handleFilterChange = (
    event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | any>,
    valor: any
  ) => {
    const { name, value } = event.target;

    let _state = { ...state };

    switch (name) {
      case "areasAtuacao":
        if (_state.filters[name].find((i: any) => i.id === valor.id) != null) {
          return;
        }
        _state = {
          ..._state,
          filters: {
            ..._state.filters,
            ...{ [name]: [..._state.filters[name], valor] },
          },
        };
        break;

      case "metodoEnsino":
        if (_state.filters[name].find((i: any) => i.id === valor.id) != null) {
          return;
        }
        _state = {
          ..._state,
          filters: {
            ..._state.filters,
            ...{ [name]: [..._state.filters[name], valor] },
          },
        };
        break;
      case "tipoMidia":
        if (
          _state.filters[name].find((i: any) => i.value === valor.id) != null
        ) {
          return;
        }
        _state = {
          ..._state,
          filters: {
            ..._state.filters,
            ...{ [name]: [..._state.filters[name], valor] },
          },
        };
        break;
      case "selectSort":
        _state = {
          ..._state,
          sort: value,
        };
        break;

      default:
        _state = {
          ..._state,
          filters: { ..._state.filters, ...{ [name]: valor } },
        };
        break;
    }

    search(0, _state);

    setState({ ..._state });
  };

  const handleRemove = (item: any, name: string) => {
    let _state = { ...state };
    let index = -1;
    if (item.id) {
      index = _state.filters[name].findIndex((i: any) => i.id === item.id);
    } else {
      index = _state.filters[name].findIndex(
        (i: any) => i.value === item.value
      );
    }
    _state.filters[name].splice(index, 1);
    setState({ ..._state });
    search(0, _state);
  };

  const criarLink = (subAreaConhecimento: SubAreaConhecimentoEntity) => {
    return `/area-de-interesse/${subAreaConhecimento?.attributes?.area_de_conhecimento?.data?.attributes
      ?.titulo!.toLowerCase()
      .replace(/[" "]/g, "-")}/${subAreaConhecimento.attributes?.area_de_conhecimento?.data?.id
      }/${getNome(subAreaConhecimento.attributes!)
        .toLowerCase()
        .replace(/[" "]/g, "-")}/${subAreaConhecimento?.id}`;
  };

  const limparFiltro = () => {
    const _state = {
      ...state,
      filters: {
        palavraChave: "",
        areasAtuacao: [],
        metodoEnsino: [],
        tipoMidia: [],
        possuiDeclaracao: false,
      },
    };
    setState(_state);
    search(0, _state);
  };

  const getNome = (subAreaInteresse: SubAreaConhecimento) => {
    if (subAreaInteresse.Nome.indexOf("/") > -1) {
      return subAreaInteresse.Nome.substring(
        subAreaInteresse.Nome.indexOf("/") + 1
      );
    }
    return subAreaInteresse.Nome;
  };

  return (
    <>
      <Loader
        show={carregandoOEs}
        message="Aguarde enquanto o conteúdo é carregado..."
      />

      <div data-area-conhecimento-page>
        <HeaderSection>
          <Breadcrumb>
            <BreadcrumbItem to="/">Home</BreadcrumbItem>
            <BreadcrumbItem to="/central-de-autorias">
              Central de Autoria
            </BreadcrumbItem>
            <BreadcrumbItem to={`/autor/${params.idAutor}/detalhes`}>
              {author?.attributes?.nome_exibicao}
            </BreadcrumbItem>
            <BreadcrumbItem to={`/autor/${params.idAutor}/detalhes/oe`} active>
              Conteúdos
            </BreadcrumbItem>
          </Breadcrumb>
          <h3 className="text-base md:text-xxl font-semibold leading-none text-white">Conteúdos de {author?.attributes?.nome_exibicao}</h3>
          <strong data-limpa-filtro onClick={() => limparFiltro()}>
            Limpar filtros
          </strong>
        </HeaderSection>
        <br />
        <Container>
          <OePesquisaCabecalho
            search={
              !!queryParamsObject && !!queryParamsObject.search
                ? queryParamsObject.search[0]
                : ""
            }
            total={total}
            handleChange={handleFilterChange}
            handleRemove={handleRemove}
            filtros={state.filters}
            ordem={state.sort}
            categoria={
              (queryParamsObject?.categorias &&
                queryParamsObject?.categorias[0]) ||
              ""
            }
            areaInteresse={
              (queryParamsObject?.areaInteresse &&
                queryParamsObject?.areaInteresse[0]) ||
              ""
            }
            title={
              author?.attributes?.nome_exibicao &&
              author?.attributes?.nome_exibicao
            }
          />

        </Container>
      </div>
      <Container>
        {resultadoOE && resultadoOE.length > 0 && (
          <section>
            {resultadoOE?.map(
              (subAreaInteresse: SubAreaConhecimentoEntity, index) => (
                <>
                  {subAreaInteresse?.attributes?.ObjetosEducacionais &&
                    subAreaInteresse?.attributes?.ObjetosEducacionais?.data
                      ?.length > 0 && (
                      <CardSection
                        key={index}
                        title={getNome(subAreaInteresse.attributes!)}
                        items={
                          subAreaInteresse?.attributes?.ObjetosEducacionais?.data
                        }
                        linkUrl={criarLink(subAreaInteresse)}
                        showAllSee={true}
                        limit={12}
                      ></CardSection>
                    )}
                </>
              )
            )}
          </section>
        )}
        {!resultadoOE ||
          (!resultadoOE.length && (
            <section data-empty>
              <h4>Não existe nenhum resultado!</h4>
            </section>
          ))}
      </Container>
    </>
  );
};
