import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Loader } from "../../../../shared/components";

import { ProfessionalProfile } from "../../../../graphql/collections/queries/profissional-profile.query";

import * as UfService from "../../../../shared/services/uf.service";
import { Estado } from "../../../../shared/services/uf.service";

import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../../../hooks/useGtm";

import { useNovoUsuario } from "../../../../hooks/useNovoUsuario";

import "./styles.scss";
import {
  ProfissaoEntity,
  useGetEspecialidadesLazyQuery,
  useGetProfissoesLazyQuery,
} from "../../../../generated/graphql";
import { useScrollTop } from "../../../../shared/hooks/useScrollTop";
import { Button } from "../../../../shared/components/ui/button";
import { SelectResidencia } from "../../../ResidenciaMedicaFormulario";
import { Input } from "../../../../shared/components/ui/input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../../../shared/components/ui/select";
import { Label } from "../../../../shared/components/ui/label";

export interface StepHandlerState {
  formData: any;
  profiles: ProfessionalProfile[];
  ufs: Estado[];
}

export interface DadosProfissionaisInterface {
  PerfilProfissao: number | null;
  especialidade: number | null;
  UF: number | string | null;
  CRM: string;
  occupation_student: string;
  otherOccupation: string;
  occupation_technical: string;
  formStep: number;
  ehEstudante: boolean;
  ehMedico: boolean;
  ehOutro: boolean;
  ehTecnico: boolean;
}

export const DadosProfissionaisV2: React.FC = () => {
  const history = useHistory();

  const [showAllErrorsMessages, setShowAllErrorsMessages] = useState(false);
  const [fullProfiles, setFullProfiles] = useState<ProfissaoEntity[]>([]);
  const [profiles, setProfiles] = useState<ProfissaoEntity[]>([]);
  const [ufs, setUfs] = useState<Estado[]>([]);

  const [estudanteList, setEstudanteList] = useState<ProfissaoEntity[]>([]);
  const [tecnicoAuxList, setTecnicoAuxList] = useState<ProfissaoEntity[]>([]);
  const gtm = useGtm();
  const [loading, setLoading] = useState(true);

  const localUser = useNovoUsuario<DadosProfissionaisInterface>();

  const formik = useFormik<DadosProfissionaisInterface>({
    initialValues: {
      PerfilProfissao: null,
      especialidade: null,
      UF: null,
      CRM: "",
      occupation_student: "",
      otherOccupation: "",
      occupation_technical: "",
      ehMedico: false,
      ehEstudante: false,
      ehOutro: false,
      ehTecnico: false,
      formStep: 2,
    },
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: Yup.object().shape({
      PerfilProfissao: Yup.string()
        .nullable()
        .required("Campo obrigatório")
        .test({
          message: "Campo obrigatório",
          test: (value) => {
            return value !== null && value !== "0";
          },
        }),
      UF: Yup.string()
        .nullable()
        .when("ehMedico", {
          is: true,
          then: Yup.string()
            .required("Campo obrigatório")
            .test({
              message: "Campo obrigatório",
              test: (value) => {
                return !!value && value !== "0";
              },
            }),
        }),
      CRM: Yup.string().when("ehMedico", {
        is: true,
        then: Yup.string().required("Campo obrigatório"),
      }),
      occupation_student: Yup.string().when("ehEstudante", {
        is: true,
        then: Yup.string()
          .required("Campo obrigatório")
          .test({
            message: "Campo obrigatório",
            test: (value) => {
              return !!value && value !== "";
            },
          }),
      }),
      otherOccupation: Yup.string().when("ehOutro", {
        is: true,
        then: Yup.string()
          .required("Campo obrigatório")
          .min(3, "Quantidade mínima de caracteres não atingida")
          .max(255, "Quantidade máxima de caracteres ultrapassada"),
      }),
      occupation_technical: Yup.string().when("ehTecnico", {
        is: true,
        then: Yup.string()
          .required("Campo obrigatório")
          .test({
            message: "Campo obrigatório",
            test: (value) => {
              return !!value && value !== "";
            },
          }),
      }),
      especialidade: Yup.string()
        .nullable()
        .when("ehMedico", {
          is: true,
          then: Yup.string()
            .required("Campo obrigatório")
            .test({
              message: "Campo obrigatório",
              test: (value) => {
                return value !== null && value !== "0";
              },
            }),
        }),
    }),
    onSubmit: (values) => {
      // GTM doc linha 38
      gtm.push(
        EDataLayerEventCategory.ADE_CADASTRO,
        EDataLayerEventAction.CLIQUE,
        {
          label: "cadastro-avançar2",
        },
      );
      values.formStep = 3;
      localUser.save(values);
      history.push("/novo-usuario-v2/areas-de-interesse");
    },
  });

  useEffect(() => {
    localUser.load().then((user) => {
      if (!!user) {
        formik.setValues(user);
        formik.setValues({
          ...user,
          PerfilProfissao: user.PerfilProfissao || null,
          especialidade: user.especialidade || null,
          UF: user.UF || null,
          CRM: user.CRM || "",
          occupation_student: user.occupation_student || "",
          otherOccupation: user.otherOccupation || "",
          occupation_technical: user.occupation_technical || "",
          ehMedico: user.ehMedico || false,
          ehEstudante: user.ehEstudante || false,
          ehOutro: user.ehOutro || false,
          ehTecnico: user.ehTecnico || false,
        });

        return;
      }

      history.push("/novo-usuario-v2/dados-pessoais");
    });
  }, []);

  const [GetEspecialidade, { data: especialidades, refetch }] =
    useGetEspecialidadesLazyQuery({
      variables: {
        filtros: {
          profissaos: {
            id: {
              eq: formik.values.PerfilProfissao?.toString() || "0",
            },
          },
        },
      },
    });

  useEffect(() => {
    refetch();
  }, [GetEspecialidade, formik.values.PerfilProfissao]);

  const [GetProfissoes] = useGetProfissoesLazyQuery({
    onCompleted: (data) => {
      if (!!data) {
        const _data = data.profissaos?.data as ProfissaoEntity[];
        const studentID = _data.find(
          (item) => item?.attributes?.ehEstudante === true,
        );
        const tecnicoAuxID = _data.find(
          (item) => item?.attributes?.ehTecnicoAuxiliar === true,
        );
        setFullProfiles(_data);
        setProfiles([
          ..._data.filter((item) => !item?.attributes?.profissaoPai?.data),
        ]);

        // Cria a combo list para estudantes
        if (studentID) {
          setEstudanteList(
            _data.filter(
              (item) =>
                item?.attributes?.profissaoPai?.data?.id?.toString() ===
                studentID.id,
            ) as [],
          );
        }

        // Cria a combo list para tecnico auxiliar
        if (tecnicoAuxID) {
          setTecnicoAuxList(
            _data.filter(
              (item) =>
                item?.attributes?.profissaoPai?.data?.id?.toString() ===
                tecnicoAuxID.id,
            ) as [],
          );
        }

        setLoading(false);
      }
    },
  });

  useEffect(() => {
    GetProfissoes();
  }, [GetProfissoes]);

  const scroll = useScrollTop();

  useEffect(() => {
    const ufs = UfService.obterTodosEstados();
    setUfs([...ufs]);
    scroll();
  }, []);

  const back = () => {
    localUser
      .save({
        ...formik.values,
        formStep: 1,
      })
      .then(() => {
        gtm.push(
          EDataLayerEventCategory.ADE_CADASTRO,
          EDataLayerEventAction.CLIQUE,
          {
            label: "cadastro-abandono2",
          },
        );

        history.push("/novo-usuario-v2/dados-pessoais");
      });
  };

  const findMedicoProfile = (value: string) => {
    const _ehMedico = profiles.find(
      (profile) =>
        profile.id!.toString() === value && profile.attributes?.PossuiCRM,
    );

    return _ehMedico;
  };

  const findEstudanteProfile = (value: string) =>
    profiles.find(
      (profile) => profile.id === value && profile.attributes?.ehEstudante,
    );

  const findOutroProfile = (value: string) => {
    const _ehOutro = fullProfiles.find(
      (profile) =>
        profile.id!.toString() === value && profile.attributes?.ehOutro,
    );

    return _ehOutro;
  };

  const findTecnicoProfile = (value: string) => {
    const _ehTecnico = profiles.find(
      (profile) =>
        profile.id!.toString() === value &&
        profile.attributes?.ehTecnicoAuxiliar,
    );

    return _ehTecnico;
  };

  const handleProfessionalProfile = async (value: any) => {
    if (!value) {
      return;
    }

    let userData = {
      ...formik.values,
      CRM: "",
      UF: "",
      ehMedico: false,
      ehEstudante: false,
    };

    if (!!findMedicoProfile(value)) {
      userData = {
        ...userData,
        ehMedico: true,
      };
    } else {
      userData = {
        ...userData,
        ehMedico: false,
      };
    }

    if (!!findEstudanteProfile(value)) {
      userData = {
        ...userData,
        ehEstudante: true,
      };
    } else {
      userData = {
        ...userData,
        ehEstudante: false,
        occupation_student: "",
      };
    }

    if (!!findOutroProfile(value.toString())) {
      userData = {
        ...userData,
        ehOutro: true,
      };
    } else {
      userData = {
        ...userData,
        ehOutro: false,
      };
    }

    if (!!findTecnicoProfile(value.toString())) {
      userData = {
        ...userData,
        ehTecnico: true,
      };
    } else {
      userData = {
        ...userData,
        ehTecnico: false,
      };
    }

    userData = {
      ...userData,
      occupation_technical: "",
    };

    if (!userData.PerfilProfissao) {
      userData.PerfilProfissao = 0;
    }

    formik.setValues({
      ...userData,
      especialidade: null,
      UF: null,
    });
  };

  const handleOccupationStudent = async (value: any) => {
    if (!value) {
      return;
    }

    let userData = {
      ...formik.values,
      occupation_student: value,
      ehOutro: false,
    };

    if (!!findOutroProfile(value.toString())) {
      userData = {
        ...userData,
        ehOutro: true,
      };
    } else {
      userData = {
        ...userData,
        ehOutro: false,
      };
    }

    formik.setValues({
      ...userData,
    });
  };

  function sendEmail(emailAddress: string) {
    window.location.href = `mailto:${emailAddress}`;
  }

  return (
    <>
      <Loader
        show={loading === true}
        message="Aguarde enquanto o conteúdo é carregado..."
      />
      {formik.values && (
        <section className="flex justify-center">
          <form
            className="flex  flex-col items-center justify-center rounded-2xl md:border md:py-10"
            onSubmit={formik.handleSubmit}
          >
            <div className="flex w-[22.5rem] flex-col items-center justify-center">
              <h3 className="hidden text-center text-xl text-[#004F92] md:flex">
                Informações Profissionais
              </h3>
              <div className="flex w-full flex-col gap-4 py-10">
                <div className="space-y-2">
                  <Label htmlFor="PerfilProfissao">Formação acadêmica</Label>
                  <Select
                    defaultValue={formik.values.PerfilProfissao?.toString()}
                    value={formik.values.PerfilProfissao?.toString()}
                    onValueChange={(value) => {
                      handleProfessionalProfile(value);
                      if (value === "0") {
                        formik.setFieldValue("PerfilProfissao", null);
                      }
                      if (value) {
                        formik.setFieldValue("PerfilProfissao", value);
                      }
                    }}
                    name="PerfilProfissao"
                  >
                    <SelectTrigger className="w-full bg-[#FAFAFC]">
                      <SelectValue placeholder="Escolha uma opção" />
                    </SelectTrigger>
                    <SelectContent id="PerfilProfissao" className="">
                      <SelectGroup>
                        <SelectLabel key="-1">Escolha uma opção</SelectLabel>
                        {profiles.map(
                          (item) =>
                            item.id && (
                              <SelectItem key={item.id} value={item.id}>
                                {item.attributes?.titulo}
                              </SelectItem>
                            ),
                        )}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                  {formik.touched.PerfilProfissao &&
                    formik.errors.PerfilProfissao && (
                      <p className="text-xs font-medium text-red-500 dark:text-red-400">
                        {formik.errors.PerfilProfissao}
                      </p>
                    )}
                </div>

                {especialidades?.especialidades?.data &&
                  especialidades?.especialidades?.data?.length > 0 && (
                    <div className="space-y-2">
                      <Label htmlFor="especialidade">Especialidade</Label>
                      <Select
                        name="especialidade"
                        defaultValue={formik.values.especialidade?.toString()}
                        value={formik.values.especialidade?.toString()}
                        onValueChange={(e) => {
                          formik.setFieldValue("especialidade", e);
                        }}
                      >
                        <SelectTrigger className="w-full bg-[#FAFAFC]">
                          <SelectValue placeholder="Escolha uma opção" />
                        </SelectTrigger>
                        <SelectContent id="especialidade">
                          <SelectGroup>
                            <SelectLabel key="-1">
                              Escolha uma opção
                            </SelectLabel>
                            {[
                              ...especialidades.especialidades?.data
                                ?.filter((a) => !a.attributes?.ultima)
                                .sort(
                                  (a, b) =>
                                    a.attributes?.nome
                                      ?.toLocaleLowerCase()
                                      .localeCompare(
                                        b?.attributes?.nome?.toLocaleLowerCase()!,
                                        "pt-BR",
                                        { sensitivity: "base" },
                                      )!,
                                )
                                .map((item, idx) => ({
                                  value: item.id,
                                  label: item.attributes?.nome,
                                })),
                              ...especialidades.especialidades?.data
                                ?.filter((a) => !!a.attributes?.ultima)
                                .map((item, idx) => ({
                                  value: item.id,
                                  label: item.attributes?.nome,
                                })),
                            ].map((item: any) => (
                              <SelectItem key={item.value} value={item.value}>
                                {item.label}
                              </SelectItem>
                            ))}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                      {formik.touched.especialidade &&
                        formik.errors.especialidade && (
                          <p className="text-xs font-medium text-red-500 dark:text-red-400">
                            {formik.errors.especialidade}
                          </p>
                        )}
                    </div>
                  )}

                {!!formik.values.ehMedico && (
                  <Input
                    className="min-w-[360px] bg-[#FAFAFC]"
                    type="text"
                    label="CRM"
                    name="CRM"
                    value={formik.values.CRM}
                    onChange={formik.handleChange}
                    errorMessage={
                      (formik.touched.CRM && formik.errors.CRM) || ""
                    }
                  />
                )}

                {!!formik.values.ehMedico && (
                  <div className="space-y-2">
                    <Label htmlFor="uf">UF</Label>
                    <Select
                      defaultValue={formik?.values.UF?.toString()}
                      onValueChange={(e) => {
                        formik.setFieldValue("UF", e);
                      }}
                      value={formik.values.UF?.toString()}
                      name="UF"
                    >
                      <SelectTrigger className="w-full bg-[#FAFAFC]">
                        <SelectValue placeholder="Escolha uma opção" />
                      </SelectTrigger>
                      <SelectContent id="uf">
                        <SelectGroup>
                          <SelectLabel key="-1">Escolha uma opção</SelectLabel>
                          {ufs.map((item) => (
                            <SelectItem key={item.id} value={item.id}>
                              {item.name}
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                    {formik.touched.UF && formik.errors.UF && (
                      <p className="text-xs font-medium text-red-500 dark:text-red-400">
                        {formik.errors.UF}
                      </p>
                    )}
                  </div>
                )}

                {formik.values.ehEstudante && (
                  <SelectResidencia
                    label="Se você é estudante, indique seu curso:"
                    id="occupation_student"
                    name="occupation_student"
                    value={formik.values.occupation_student.toString()}
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleOccupationStudent(e.target.value);
                    }}
                    options={estudanteList.map((item) => ({
                      value: item.id,
                      label: item.attributes?.titulo,
                    }))}
                  />
                )}

                {formik.values.ehTecnico && (
                  <div className="space-y-2">
                    <Label htmlFor="occupation_technical">
                      Se você é técnico ou auxiliar, especifique abaixo:
                    </Label>
                    <Select
                      defaultValue={formik.values.occupation_technical}
                      value={formik.values.occupation_technical}
                      onValueChange={(e) => {
                        formik.setFieldValue("occupation_technical", e);
                      }}
                      name="occupation_technical"
                    >
                      <SelectTrigger className="w-full bg-[#FAFAFC]">
                        <SelectValue placeholder="Escolha uma opção" />
                      </SelectTrigger>
                      <SelectContent id="occupation_technical">
                        <SelectGroup>
                          <SelectLabel key="-1">Escolha uma opção</SelectLabel>
                          {tecnicoAuxList?.map((item) => (
                            <SelectItem key={item.id} value={item.id!}>
                              {item.attributes?.titulo}
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                    {formik.touched.occupation_technical &&
                      formik.errors.occupation_technical && (
                        <p className="text-xs font-medium text-red-500 dark:text-red-400">
                          {formik.errors.occupation_technical}
                        </p>
                      )}
                  </div>
                )}

                {!!formik.values.ehOutro && (
                  <Input
                    className="md:w-[360px]"
                    type="text"
                    label="Qual?"
                    name="otherOccupation"
                    value={formik.values.otherOccupation}
                    onChange={formik.handleChange}
                    errorMessage={
                      (formik.touched.otherOccupation &&
                        formik.errors.otherOccupation) ||
                      ""
                    }
                  />
                )}
              </div>

              <div className="flex gap-3">
                <Button
                  type="button"
                  data-testid="btn-voltar"
                  variant="outline"
                  className="w-[130px]"
                  onClick={() => {
                    gtm.push(
                      EDataLayerEventCategory.ADE_CADASTRO,
                      EDataLayerEventAction.CLIQUE,
                      {
                        label: "cadastro-voltar2",
                      },
                    );
                    back();
                  }}
                >
                  Voltar
                </Button>
                <Button
                  variant="primary"
                  size="default"
                  type="submit"
                  className="w-[147px]"
                  data-testid="btn-avancar"
                  disabled={formik.isSubmitting || formik.isValidating}
                  onClick={() => setShowAllErrorsMessages(true)}
                >
                  {formik.isSubmitting || formik.isValidating
                    ? "Validando... "
                    : "Avançar"}
                </Button>
              </div>
              <div className="flex py-10">
                <span className="text-center text-sm font-medium leading-4 text-[#111316] md:text-start">
                  Problemas no cadastro? <br />
                  Envie email para{" "}
                  <span
                    className="cursor-pointer text-[#004F92] underline"
                    onClick={() => sendEmail("academia.digital@einstein.br")}
                  >
                    academia.digital@einstein.br
                  </span>
                </span>
              </div>
            </div>
          </form>
        </section>
      )}
    </>
  );
};
