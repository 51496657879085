import Container from "../../shared/components/Container";

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "../../shared/components/ui/breadcrumb";

import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "../../shared/components/ui/carousel";
import CardObjetoEducacional from "../../shared/components/oe-card";
import Menu from "../../shared/components/menu";
import { ObjetoPorInteresse } from "../../models/ObjetoPorInteresse";
import { fetchApi } from "../../shared/services/rest-dotnet.service";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useScrollTop } from "../../shared/hooks/useScrollTop";
import VerTudoIcon from "../../assets/svgs/icons/ver-tudo.svg";

async function getNovosConteudos({
  m,
  n,
  t,
  s,
  c,
}: SearchProps): Promise<ObjetoPorInteresse[]> {
  const params = {
    metodosDeEnsino: Array.isArray(m) ? m : m ? [m] : [],
    niveisMaturidade: Array.isArray(n) ? n : n ? [n] : [],
    tipoMidia: Array.isArray(t) ? t : t ? [t] : [],
    ordernar: parseInt(s || "3") || 3,
    possuiCertificado: c === "true",
  };
  const response = await fetchApi(
    "area-conhecimento/get-area-conhecimento-oes-recentes-by-user",
    "POST",
    JSON.stringify(params),
  ).then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      return [];
    }
  });

  return response;
}

interface SearchProps {
  m?: string;
  n?: string;
  t?: string;
  s?: string;
  c?: string;
}

export default function NovosConteudosInteresse() {
  const [novosAssuntosInteresse, setNovosAssuntosInteresse] =
    useState<ObjetoPorInteresse[]>();
  const [searchParams, setSearchParams] = useState<SearchProps>(
    {} as SearchProps,
  );
  const scrollTop = useScrollTop();
  const history = useHistory();

  const location = useLocation();

  const loadNovoAssuntoInteresse = async (search: SearchProps) => {
    setNovosAssuntosInteresse(await getNovosConteudos(search));
  };

  const quantidadeObjetosTotais = novosAssuntosInteresse?.reduce(
    (acc, item) => acc + item.objetosEducacionais.length,
    0,
  );

  useEffect(() => {
    const queryLocation = new URLSearchParams(location.search);
    const search = {
      m: queryLocation.get("m") || undefined,
      n: queryLocation.get("n") || undefined,
      t: queryLocation.get("t") || undefined,
      s: queryLocation.get("s") || undefined,
      c: queryLocation.get("c") || undefined,
    };
    setSearchParams(search);
    loadNovoAssuntoInteresse(search);
  }, [location.search]);

  scrollTop();

  return (
    <>
      <div
        style={{
          background: "linear-gradient(137.1deg, #107B95 6.6%, #1BB28E 98.07%)",
        }}
        className="flex h-32 w-full"
      >
        <Container className="py-9 ">
          <Breadcrumb className="text-start  font-normal text-white-200">
            <BreadcrumbList className="flex justify-start text-start">
              <BreadcrumbItem>
                <BreadcrumbLink href="/">Home</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbLink href="/novos-conteudos-interesse">
                  Mais recentes do seu interesse
                </BreadcrumbLink>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
          <h2 className="leading-6.5 mt-3 text-base font-semibold normal-case text-white-200 md:text-1.5xl">
            Mais recentes do seu interesse
          </h2>
        </Container>
      </div>
      <Container className="h-full min-h-screen  py-9 ">
        <div className="flex flex-col gap-10 ">
          <Menu
            typeFiltro="AREA_INTERESSE_USUARIO"
            qtdConteudos={quantidadeObjetosTotais}
          />
          <div className="mt-8">
            {novosAssuntosInteresse?.map((item: ObjetoPorInteresse) => {
              if (item.objetosEducacionais.length > 0) {
                return (
                  <div key={item.id} className="flex flex-col gap-6 ">
                    <div className="flex justify-between">
                      <h3 className="leading-6.5 text-1.5xl font-semibold text-brand-blue">
                        {item.titulo}
                      </h3>
                      <div
                        onClick={() => {
                          history.push(
                            `/area-de-interesse/${item.titulo
                              .toString()
                              .toLowerCase()
                              .trim()
                              .replace(/ /g, "-")}/${item.id}`,
                            {
                              id: item.id,
                              titulo: item.titulo,
                            },
                          );
                        }}
                        className="flex cursor-pointer flex-nowrap items-center gap-1 self-center whitespace-nowrap text-xxs font-semibold text-gray-900 md:gap-2 md:text-sm"
                      >
                        VER TUDO
                        <img
                          src={VerTudoIcon}
                          alt="icon-video"
                          width={24}
                          height={24}
                        />
                      </div>
                    </div>
                    <Carousel
                      opts={{
                        breakpoints: {
                          "(min-width: 768px)": { slidesToScroll: 3 },
                        },
                      }}
                      className="w-full"
                    >
                      <CarouselContent className="-ml-1">
                        {item.objetosEducacionais.map(
                          (_: any, index: number) => (
                            <CarouselItem
                              key={index}
                              className="overflow-hidden rounded-xl pl-1 md:basis-1/2 lg:basis-1/3"
                            >
                              <div className="p-1">
                                <CardObjetoEducacional objetoEducacional={_} />
                              </div>
                            </CarouselItem>
                          ),
                        )}
                      </CarouselContent>
                    </Carousel>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </Container>
    </>
  );
}
