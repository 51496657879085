import React from "react";
import "./styles.scss";
import Container from "../Container";
import { HeaderSection } from "../HeaderSection";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbSeparator } from "../ui/breadcrumb";

export interface ItemBreadrumb {
  to?: string;
  title: string;
}

export interface HeaderPageBreadcrumbProps {
  itens: ItemBreadrumb[];
  title: string;
  children?: React.ReactNode;
  onClickClearSearch?: () => void;
  color?:
  | "conexao-de-ideias"
  | "deep-dive"
  | "desafio-clinico"
  | "descomplicando-ciencia"
  | "einstein-practice"
  | "fast-challenge"
  | "opiniao-do-especialista"
  | "pilula-de-conhecimento"
  | "reuniao-cientifica"
  | "curso-online"
  | "default"
  | any;
}

const HeaderPageBreadcrumb: React.FC<HeaderPageBreadcrumbProps> = ({
  itens,
  title,
  onClickClearSearch,
  children,
  color,
}) => {
  return (
    <HeaderSection classe={`!px-0 py-9 ${color}`}>
      <Breadcrumb className="flex justify-start !text-xxs font-normal text-white-200 md:text-xs">
        <BreadcrumbList>
          {itens.map((item, index) => (
            <React.Fragment key={index}>
              <BreadcrumbItem>
                <BreadcrumbLink href={item.to}>{item.title}</BreadcrumbLink>
              </BreadcrumbItem>
              {index < itens.length - 1 && <BreadcrumbSeparator key={index} /> }
            </React.Fragment>
          ))}
        </BreadcrumbList>
      </Breadcrumb>
      <h2 className="flex flex-row leading-6.5 mt-3 text-base font-semibold normal-case text-white-200 md:text-1.5xl">
          {title}{" "}
      </h2>
      {onClickClearSearch && (<div data-container-page-breadcrumb>
        {onClickClearSearch && (
          <div style={{ marginBottom: 15 }}>
            <span
              className="text-xs text-white underline cursor-pointer"
              data-limpa-filtro-page-breadcrumb
              onClick={() => onClickClearSearch()}
            >
              Limpar filtros
            </span>
          </div>
        )}
      </div>)}
    </HeaderSection>
  );
};

export default HeaderPageBreadcrumb;
