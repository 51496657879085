import React, { useContext, useEffect, useState } from "react";
import HtmlParse from "html-react-parser";
import { useHistory } from "react-router-dom";
import {
  ComponentSecaoVideoAudioVideoAudio,
  ComponentTextoSecaoPdf,
  ObjetoEducacionalEntity,
  UsersPermissionsUserEntity,
} from "../../../../generated/graphql";

import {
  BannerSection,
  RotuloCategoria,
  SelectField,
} from "../../../../shared/components";
import { UserContext } from "../../../../context/UserContext";
import { format } from "date-fns";
import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../../../hooks/useGtm";

import { TIPO_AREA_PAGINA, TIPO_PAGINA } from "../../../../hooks/useBannerApi";
import "./styles.scss";
import ptBR from "date-fns/esm/locale/pt-BR/index.js";
import TimerReaderBlue from "../../../../assets/svgs/timer-reader-blue.svg";
import CalendarBlue from "../../../../assets/svgs/calendar-blue.svg";
import { Tags } from "../../../../shared/components/Tags";
import AddBlue from "../../../../assets/svgs/add.svg";
import { Close } from "@material-ui/icons";
import IconTextBlue from "../../../../assets/svgs/icon-text-blue.svg";
import IconImageBlue from "../../../../assets/svgs/icon-image-blue.svg";
import IconVideoBlue from "../../../../assets/svgs/icon-video-blue.svg";
import IconWebcastBlue from "../../../../assets/svgs/icon-webcast-blue.svg";
import { TypeOE } from "../OePreviewDesktop";
import { useUtils } from "../../../../shared/utils";
import {
  Banner,
  loadBanner,
} from "../../../../graphql/collections/queries/banner.query";
import Container from "../../../../shared/components/Container";
import {
  ArrowLeft,
  AudioLines,
  Calendar,
  Clock4,
  PieChartIcon,
  Play,
  Text,
  Video,
} from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../shared/components/ui/dialog";
import Rotulo from "../../../../shared/components/rotulos";
import { cn } from "../../../../shared/utils/utils";
import { Button } from "../../../../shared/components/ui/button";
import { Badge } from "../../../../shared/components/ui/badge";

interface OePreviewProps {
  oe: ObjetoEducacionalEntity;
  history?: any;
  location?: any;
  match?: any;
  user?: UsersPermissionsUserEntity;
  type: TypeOE;
}

export const OePreviewMobile: React.FC<OePreviewProps> = (props) => {
  const gtm = useGtm();

  const { user } = useContext(UserContext);
  const history = useHistory();

  const [showBanner, setShowBanner] = useState(true);
  const [estaAovivo, setEstaAovivo] = useState(false);
  const [arquivoAtivo, setArquivoAtivo] = useState<
    ComponentTextoSecaoPdf | ComponentSecaoVideoAudioVideoAudio | undefined
  >(undefined);
  const [limitTag, setLimitTag] = useState<boolean>(true);
  const [tipoOE, setTipoOe] = useState<TypeOE>(props.type);

  const [banners, setBanners] = useState<Banner[]>([]);

  const carregarBanner = async () => {
    const resultBanner = await loadBanner({
      homePage: false,
      objetoEducacionalId: props?.oe.id! ? Number(props?.oe.id!) : undefined,
      areaDeConhecimentoId:
        props?.oe.attributes?.SubAreaConhecimento?.data
          ?.filter((sub) => {
            return !!sub.attributes?.area_de_conhecimento?.data?.id;
          })
          ?.map((a) => Number(a.attributes?.area_de_conhecimento?.data?.id!)) ||
        [],
      subAreaDeConhecimentoId:
        props?.oe.attributes?.SubAreaConhecimento?.data?.map((a) =>
          Number(a.id!),
        ) || [],
      publicoAlvoId:
        props?.oe.attributes?.PublicoAlvo?.data?.map((a) => Number(a.id!)) ||
        [],
    });

    setBanners(resultBanner);
  };

  useEffect(() => {
    carregarBanner();
  }, [props.oe]);

  useEffect(() => {
    if (props.oe.attributes?.PDF && props.oe.attributes?.PDF[0]) {
      setArquivoAtivo(props.oe.attributes?.PDF[0]);
      setTipoOe(TypeOE.PDF);
    }
    if (props.oe.attributes?.VideoAudio && props.oe.attributes?.VideoAudio[0]) {
      setArquivoAtivo(props.oe.attributes?.VideoAudio[0]);
    }
  }, [props]);

  const closeBanner = () => {
    setShowBanner(!showBanner);
  };

  const getUrlLerAgora = (arquivo = arquivoAtivo): string => {
    switch (tipoOE) {
      case TypeOE.PDF:
        if (props.oe.id && arquivo?.id) {
          return `/oe/${props.oe.id}/pdf/${arquivo?.id}`;
        }
        return `/oe/${props.oe.id}/pdf`;
      case TypeOE.Texto:
        return `/oe/${props.oe.id}/leitura`;
      case TypeOE.Imagem:
        return `/oe/${props.oe.id}/imagem`;
      case TypeOE.Video:
      case TypeOE.Webcast:
        if (props.oe.id && arquivo?.id) {
          if (
            props.oe.attributes?.TipoObjeto?.data.find(
              (e) => e.attributes?.titulo == "Vídeo",
            )
          ) {
            return `/oe/${props.oe.id}/video/${arquivo?.id}`;
          } else {
            return `/oe/${props.oe.id}/webcast/${arquivo?.id}`;
          }
        }
        return `/oe/${props.oe.id}/video`;
      default:
        return "";
    }
  };

  const getIcon = () => {
    switch (tipoOE) {
      case TypeOE.PDF:
        return <div data-pdf-info-icon>PDF</div>;
      case TypeOE.Texto:
        return <Text size={17.76} />;
      case TypeOE.Imagem:
        return <PieChartIcon size={17.76} />;
      case TypeOE.Video:
      case TypeOE.Webcast:
        return props.oe.attributes?.TipoObjeto?.data?.find(
          (e) => e.attributes?.titulo == "Vídeo",
        ) ? (
          <Video size={17.76} />
        ) : (
          <AudioLines size={17.76} />
        );
      default:
        return <></>;
    }
  };

  const getTextoTipo = () => {
    switch (tipoOE) {
      case TypeOE.PDF:
      case TypeOE.Texto:
      case TypeOE.Imagem:
        return "Leitura";
      case TypeOE.Video:
      case TypeOE.Webcast:
        return props.oe?.attributes?.TipoObjeto?.data.find(
          (e) => e.attributes?.titulo === "Vídeo",
        )
          ? "Vídeo"
          : "Vídeocast";
      default:
        return "";
    }
  };

  const getLabelButton = () => {
    switch (tipoOE) {
      case TypeOE.PDF:
        return "Ler agora";
      case TypeOE.Texto:
        return "Ler agora";
      case TypeOE.Imagem:
        return "Ver Infográfico";
      case TypeOE.Video:
      case TypeOE.Webcast:
        return "Assistir agora";
      default:
        return "";
    }
  };

  const fundoBlack = () => {
    switch (tipoOE) {
      case TypeOE.PDF:
      case TypeOE.Texto:
      case TypeOE.Imagem:
        return false;
      default:
        return true;
    }
  };

  const tempoMeioDuracao: string | number = parseInt(
    props.oe?.attributes?.TempoMedioDuracao.replace(/[^\d]/g, "") || "0",
  );

  const tempoFormatado = tempoMeioDuracao + " minutos";

  const tags = limitTag
    ? props.oe?.attributes?.palavras_chave?.data.slice(0, 1)
    : props.oe?.attributes?.palavras_chave?.data;

  const { formatTitle } = useUtils();

  function moveNameToFront(array: string[], name: string): string[] {
    const index = array.indexOf(name);

    if (index > -1) {
      // Remove o nome do array
      array.splice(index, 1);
      // Adiciona o nome na primeira posição
      array.unshift(name);
    }

    return array;
  }

  const userProfissao = (user?.attributes?.PerfilProfissao?.data?.length || 0) > 0 ? user?.attributes?.PerfilProfissao?.data[0].attributes?.titulo || '' : ''
  const publicosAlvos = moveNameToFront(props.oe.attributes?.PublicoAlvo?.data.map(a => a.attributes?.titulo || '') || [], userProfissao);


  return (
    <>
      <section
        className="relative flex h-[200px] w-full bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${props.oe.attributes?.ImagemPreview?.data?.attributes?.url})`,
        }}
      >
        <button
          onClick={() => {
            // GTM doc linha 86

            gtm.push(
              EDataLayerEventCategory.ADE_OE_INTERACAO_VIDEO,
              EDataLayerEventAction.CLIQUE,
              {
                label: `iniciar-video:${formatTitle(
                  props.oe.attributes?.Titulo!,
                )}`,
              },
            );
            history.push(getUrlLerAgora());
          }}
          className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 cursor-pointer"
        >
          <Play size={34} color="white" />
        </button>
        <button className="absolute left-5 top-4 cursor-pointer">
          <ArrowLeft size={34} color="white" />
        </button>
      </section>
      <div
        className={cn(
          "h-auto min-h-[492.19px] w-full !py-9",
          fundoBlack() ? "bg-black-900" : "bg-white",
        )}
      >
        <Container className="relative">
          <div className="flex h-full w-full flex-col items-start justify-center gap-6">
            <div className="flex flex-col gap-3">
              <div className="flex w-auto gap-2">
                {props.oe.attributes?.Rotulos?.data.map((rotulo, index) => (
                  <Rotulo
                    key={index}
                    id={Number(rotulo?.id!)}
                    rotuloName={rotulo.attributes?.titulo ?? ""}
                  />
                ))}
                {props?.oe?.attributes?.VideoAudio &&
                  props.oe.attributes.VideoAudio.length > 1 && (
                    <Badge className="border bg-transparent text-white-200">
                      {`${props.oe.attributes.VideoAudio.length} Episódios`}
                    </Badge>
                  )}
              </div>
              <h3
                className={`text-lg font-medium  leading-[24.82px] ${
                  fundoBlack() ? "text-[#FDFEFF] " : "text-[#004F92]"
                }`}
              >
                {formatTitle(props.oe.attributes?.Titulo!)}
              </h3>
              <div>
                <Dialog>
                  <DialogTrigger asChild>
                    <div className="text-sm font-medium leading-[17.07px]">
                      <p
                        className={`!line-clamp-2 inline-block  ${
                          fundoBlack() ? "text-[#FDFEFF] " : "text-[#111316]"
                        }`}
                      >
                        {props.oe.attributes?.Descricao &&
                          HtmlParse(
                            props.oe.attributes?.Descricao.replace(
                              "<p>",
                              "<p className={`truncate line-clamp-2 text-sm font-medium leading-[17.07px]`}>",
                            ),
                          )}
                      </p>
                      <span
                        className={`font-bold  underline ${
                          fundoBlack() ? "text-[#FDFEFF] " : "text-[#111316]"
                        }`}
                      >
                        Mostrar mais
                      </span>
                    </div>
                  </DialogTrigger>
                  <DialogContent className="max-w-sm rounded-lg py-8">
                    <DialogHeader className="flex w-full items-start justify-start">
                      <DialogTitle className="text-base  font-bold normal-case leading-22 text-[#004F92]">
                        Descrição
                      </DialogTitle>
                    </DialogHeader>
                    <div className="flex items-center space-x-2">
                      <div className="grid flex-1 gap-2">
                        <p
                          className={`inline-block text-sm font-normal leading-[20px] text-[#313335]`}
                        >
                          {props.oe.attributes?.Descricao &&
                            HtmlParse(
                              props.oe.attributes?.Descricao.replace(
                                "<p>",
                                "<p className={` text-sm font-normal leading-[20px] text-[#313335]`}>",
                              ),
                            )}
                        </p>
                      </div>
                    </div>
                  </DialogContent>
                </Dialog>
              </div>
            </div>
            <div className="flex flex-row gap-3">
              <div
                className={`flex items-center gap-1
              ${fundoBlack() ? "text-[#FDFEFF] " : "text-[#111316]"}`}
              >
                {getIcon()}
                <span
                  className={cn(
                    {
                      colorWhite: true,
                    },
                    "text-xs font-bold uppercase leading-[30px]",
                  )}
                >
                  {getTextoTipo()}
                </span>
              </div>
              <div
                className={`flex items-center gap-1 ${
                  fundoBlack() ? "text-[#FDFEFF] " : "text-[#111316]"
                }`}
              >
                <Calendar size={17.76} />
                <span
                  className={cn(
                    {
                      colorWhite: fundoBlack(),
                    },
                    "text-xs font-bold uppercase leading-[30px]",
                  )}
                >
                  {props.oe.attributes?.DataProducao
                    ? format(
                        new Date(
                          `${props.oe.attributes?.DataProducao}T00:00:00`,
                        ),
                        "dd MMM yy",
                        { locale: ptBR },
                      )
                    : ""}
                </span>
              </div>
              <div
                className={`flex items-center gap-1 ${
                  fundoBlack() ? "text-[#FDFEFF] " : "text-[#111316]"
                }`}
              >
                <Clock4 size={17.76} />
                <span
                  className={cn(
                    {
                      colorWhite: fundoBlack(),
                    },
                    "text-xs font-bold uppercase leading-[30px]",
                  )}
                >
                  {tempoFormatado}
                </span>
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <div className="flex flex-col flex-nowrap">
                <div className="flex flex-col gap-3">
                  <p
                    className={`text-sm font-bold leading-[17.07px] ${
                      fundoBlack() ? "text-[#FDFEFF] " : "text-[#111316]"
                    }`}
                  >
                    Recomendado:
                  </p>

                  <Dialog>
                    <DialogTrigger asChild>
                      <div className="text-sm font-medium leading-[17.07px]">
                        <span
                          className={`!line-clamp-2 inline-block text-sm font-medium leading-[17.07px] ${
                            fundoBlack() ? "text-[#FDFEFF] " : "text-[#111316]"
                          }`}
                        >
                          {publicosAlvos.map(
                            (p, index, arr) =>
                              index === arr.length - 1
                                ? `${p}`
                                : `${p}, `,
                          )}
                        </span>
                        {publicosAlvos.length! >
                          6 && (
                          <span className="font-bold text-[#FDFEFF] underline">
                            Mostrar mais
                          </span>
                        )}
                      </div>
                    </DialogTrigger>
                    <DialogContent className="py-8">
                      <DialogHeader className="flex w-full items-start justify-start">
                        <DialogTitle className="text-base  font-bold normal-case leading-22 text-[#004F92]">
                          Recomendado
                        </DialogTitle>
                      </DialogHeader>
                      <div className="flex items-center space-x-2">
                        <div className="grid flex-1 gap-2">
                          <p
                            className={`inline-block text-sm font-normal leading-[20px] text-[#313335]`}
                          >
                            {publicosAlvos.map(
                              (p, index, arr) =>
                                index === arr.length - 1
                                  ? `${p}`
                                  : `${p}, `,
                            )}
                          </p>
                        </div>
                      </div>
                    </DialogContent>
                  </Dialog>
                </div>
              </div>
            </div>
            <div className="flex w-full flex-col gap-3">
              <p
                className={`text-sm font-bold leading-[17.07px] ${
                  fundoBlack() ? "text-[#FDFEFF] " : "text-[#111316]"
                }`}
              >
                Palavras-chave:
              </p>

              <div className="flex flex-wrap gap-[6px]">
                {props.oe.attributes?.palavras_chave?.data?.map((m, index) => (
                  <Tags
                    className={cn(
                      fundoBlack()
                        ? ""
                        : "hover:brightness-40 bg-[#F5F8FF] shadow-sm [&>span]:text-[#004F92]",
                    )}
                    key={index}
                    isSearchable={true}
                    title={m.attributes?.titulo!}
                  />
                ))}
              </div>
              <div className="flex w-full">
                <Button
                  variant="primary"
                  data-testid="button-ler-agora"
                  className="mt-3 flex h-[50px] w-full"
                  onClick={() => {
                    // GTM doc linha 86
                    history.push(getUrlLerAgora());
                    gtm.push(
                      EDataLayerEventCategory.ADE_OE_INTERACAO_TEXTO,
                      EDataLayerEventAction.CLIQUE,
                      {
                        label: `${getLabelButton()
                          .split(" ")
                          .join("-")
                          .toLowerCase()}:${formatTitle(
                          props.oe.attributes?.Titulo!,
                        )}`,
                      },
                    );
                  }}
                >
                  <span>{getLabelButton()}</span>
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {(() => {
        let bannersFixo = banners?.filter(
          (a) =>
            a.tipoPagina === TIPO_PAGINA.OE &&
            a.areaPagina === TIPO_AREA_PAGINA.FIXO &&
            ((!!user?.id && a.ocultarLogado === false) || !user?.id),
        );

        return (
          <>
            {showBanner && !!bannersFixo && !!bannersFixo.length && (
              <div
                data-banner
                style={{
                  zIndex: 99999,
                }}
                className={
                  !user
                    ? "deslogado"
                    : "fixed bottom-0 left-0 right-0 z-50 mx-auto flex w-full flex-col items-center justify-center"
                }
              >
                <BannerSection
                  items={bannersFixo}
                  fixo={true}
                  onClose={closeBanner}
                  showBanner={showBanner}
                />
              </div>
            )}
          </>
        );
      })()}
    </>
  );
};
