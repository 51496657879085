import { useRef } from "react";
import { SwiperSlide } from "swiper/react";

import EnsinoEinstein1 from "../../../assets/svgs/ensino-einstein-1.svg";
import EnsinoEinstein2 from "../../../assets/svgs/ensino-einstein-2.svg";
import EnsinoEinstein3 from "../../../assets/svgs/ensino-einstein-3.svg";
import EnsinoEinstein4 from "../../../assets/svgs/ensino-einstein-4.svg";
import EnsinoEinstein5 from "../../../assets/svgs/ensino-einstein-5.svg";
import EnsinoEinstein6 from "../../../assets/svgs/ensino-einstein-6.svg";
import Container from "../../../shared/components/Container";
import CustomSwiper from "../../../shared/components/CustomSwiper";

const Info = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const refPagination = useRef<HTMLDivElement>(null);

  const renderItens = () => {
    return [
      <div key={1} className="flex flex-column w-52 h-40 items-center m-0">
        <h2 className="text-[#004F92] text-3xl font-bold normal-case">
          {" "}
          <img src={EnsinoEinstein1} alt="" className="w-20 h-20" />
        </h2>
        <span className="mt-6 text-base font-normal text-center leading-5">
          Mais de <span className="font-bold">30 anos de experiência</span> no
          Ensino em Saúde
        </span>
      </div>,

      <div key={2} className="flex flex-column w-52 h-40 box-border items-center">
        <h2 className="text-[#004F92] text-3xl font-bold normal-case">
          {" "}
          <img src={EnsinoEinstein2} alt="" className="w-20 h-20" />
        </h2>
        <span className="mt-6 text-base font-normal text-center leading-5">
          <span className="font-bold">Corpo docente </span>
          <span>formado por </span>
          <span className="font-bold">profissionais do Einstein</span>
        </span>
      </div>,
      <div key={3} className="flex flex-column w-52 h-40 box-border items-center">
        <h2 className="text-[#004F92] text-3xl font-bold normal-case">
          {" "}
          <img src={EnsinoEinstein3} alt="" className="w-20 h-20" />
        </h2>
        <span className="mt-6 text-base font-normal text-center leading-5">
          <span className="font-bold">70% dos alunos </span>
          <span className="">foram </span>
          <span className="font-bold">contratados </span>
          <span className="">pela instituição</span>
        </span>
      </div>,
      <div key={4} className="flex flex-column w-52 h-40 box-border items-center">
        <h2 className="text-[#004F92] text-3xl font-bold normal-case">
          {" "}
          <img src={EnsinoEinstein4} alt="" className="w-20 h-20" />
        </h2>
        <span className="mt-6 text-base font-normal text-center leading-5">
          Mais de <span className="font-bold">30 mil alunos ativos</span>
        </span>
      </div>,
      <div key={5} className="flex flex-column w-52 h-40 box-border items-center">
        <h2 className="text-[#004F92] text-3xl font-bold normal-case">
          {" "}
          <img src={EnsinoEinstein5} alt="" className="w-20 h-20" />
        </h2>
        <span className="mt-6 text-base font-normal text-center leading-5">
          <span className="font-bold">Cursos </span>
          para profissionais de
          <span className="font-bold"> Assistência e Gestão</span>
        </span>
      </div>,
      <div key={6} className="flex flex-column w-52 h-40 box-border items-center">
        <h2 className="text-[#004F92] text-3xl font-bold normal-case">
          {" "}
          <img src={EnsinoEinstein6} alt="" className="w-20 h-20" />
        </h2>
        <span className="mt-6 text-base font-normal text-center leading-5">
          Mais de
          <span className="font-bold"> 40 unidades </span>
          de saúde para
          <span className="font-bold"> estágios</span>
        </span>
      </div>,
    ];
  };

  return (
    <>
      <div className="bg-white">
        <Container>
          <div className="flex justify-center mt-4">
            <div className="hidden md:flex lg:flex xl:flex flex-row justify-center items-center my-10 gap-4 max-w-3xl flex-wrap">
              {renderItens()}
            </div>
            <div className=" h-full w-full md:hidden ">
              <CustomSwiper
                className="rounded-xl w-auto h-[190px]"
                slidesPerView={1}
                spaceBetween={1}
                pagination={true}
                navigation={false}
                previousRef={prevRef}
                nextRef={nextRef}
                refPagination={refPagination}
                breakpoints={{
                  640: {
                    slidesPerView: 3,
                  },
                  768: {
                    slidesPerView: 3,
                  },
                  1024: {
                    slidesPerView: 4,
                  },
                }}
                quantidade={renderItens().length}
              >
                {renderItens().map((item, index) => (
                  <SwiperSlide key={index}>
                    <div className="flex w-full justify-center h-auto">
                      {item}
                    </div>
                  </SwiperSlide>
                ))}
              </CustomSwiper>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Info;
