import { useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { withStyles } from "@material-ui/styles";
import { Theme, Tooltip } from "@material-ui/core";

import {
  Enum_Objetoeducacional_Status,
  ObjetoEducacionalEntity,
  ParceriaEntity,
  useGetAreasInteresseByParceriaLazyQuery,
  useGetCategoriasByParceriaLazyQuery,
  useGetOesLazyQuery,
  useGetTiposOeByParceriaLazyQuery,
} from "../../generated/graphql";

import InfoPatrocinadorBrancoHover from "../../assets/svgs/info-patrocinador-branco-hover.svg";

import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../hooks/useGtm";
import {
  Breadcrumb,
  BreadcrumbItem,
  HeaderSection,
  Loader,
  SearchOE,
  ResultOeSearch,
} from "../../shared/components";

import { UserContext } from "../../context/UserContext";

import "./styles.scss";
import { IListFiltersSearchOe } from "../../shared/components/SearchOEs";
import { IItemSelectField } from "../../shared/components/SelectField";
import { useScrollTop } from "../../shared/hooks/useScrollTop";
import { BannerParceiriaComponent } from "./components/BannerParceiriaPage";

export const ParceriaPage: React.FC = () => {
  const gtm = useGtm();
  const params = useParams<{ name: string; id: string }>();
  const { user, loadingUser } = useContext(UserContext);

  const [parceria, setParceria] = useState<ParceriaEntity>();
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSearch, setLoadingSearch] = useState<boolean>(true);
  const [oes, setOes] = useState<ObjetoEducacionalEntity[]>([]);
  const scrollTop = useScrollTop();
  const [resetSearch, setResetSearch] = useState(0);


  const [GetOes] = useGetOesLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (response) => {
      if (response.objetoEducacionals?.data) {
        setOes(
          response.objetoEducacionals.data.filter(
            (oes) =>
              oes?.attributes?.Status === Enum_Objetoeducacional_Status.Ativo
          ) as ObjetoEducacionalEntity[]
        );

        if (
          !!response.objetoEducacionals &&
          response.objetoEducacionals.data.length > 0
        ) {
          setParceria(
            response.objetoEducacionals.data[0].attributes?.parceria
              ?.data as ParceriaEntity
          );
        }
      } else {
        setOes([]);
      }

      setLoadingSearch(false);
      setLoading(false);
    },
    onError: () => {
      setLoadingSearch(false);
      setLoading(false);
    },
  });

  useEffect(() => {
    if (!!loadingUser) return;

    scrollTop();
    setLoadingSearch(true);

    GetOes({
      variables: {
        start: 0,
        sort: "id:asc",
        where: {
          parceria: {
            id: {
              eq: params.id,
            },
          },
          Status: {
            eq: "Ativo",
          },
        },
        whereUser: {
          id: {
            eq: !user ? "0" : user.id,
          },
        },
      },
    });
  }, [user, loadingUser]);

  useEffect(() => {
    return () => {
      // GTM doc linha 64
      if (params && params.id) {
        gtm.push(
          EDataLayerEventCategory.PAGE_VIEW,
          EDataLayerEventAction.NAVEGACAO,
          {
            label: `parcerias -- parcerias: ${params.id}`,
          }
        );
      }
    };
  }, []);

  useEffect(() => {
    if (params && params.id) {
      getAreasInteresse({
        variables: {
          parceriaId: params.id,
        },
      });

      getRotulos({
        variables: {
          parceriaId: params.id,
        },
      });

      getTiposOEs({
        variables: {
          parceriaId: params.id,
        },
      });
    }
  }, []);

  const ParceriaTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fontSize: 12,
      fontStyle: "italic",
    },
  }))(Tooltip);

  const [listFilters, setListFilters] = useState<IListFiltersSearchOe>({
    areaConhecimento: [],
    rotulo: [],
    tipo: [],
    ordenacao: [
      {
        value: "DataAtualizacaoProducao:desc",
        label: "Ordenar por: Data de criação mais recente",
      },
      {
        value: "DataAtualizacaoProducao:asc",
        label: "Ordenar por: Data de criação mais antigo",
      },
      {
        value: "Titulo:asc",
        label: "Ordenar por: Ordem alfabética A-Z",
      },
      {
        value: "Titulo:desc",
        label: "Ordenar por: Ordem alfabética Z-A",
      },
    ],
  });

  const [getAreasInteresse] = useGetAreasInteresseByParceriaLazyQuery({
    onCompleted: (response) =>
      setListItem(response)(
        "areaConhecimento",
        "areasDeConhecimentoByParceria"
      ),
  });

  const [getRotulos] = useGetCategoriasByParceriaLazyQuery({
    onCompleted: (response) =>
      setListItem(response)("rotulo", "rotulosByParceria"),
  });

  const [getTiposOEs] = useGetTiposOeByParceriaLazyQuery({
    onCompleted: (response) =>
      setListItem(response)("tipo", "tiposOesByParceria"),
  });

  const setListItem =
    (response: any) => (listName: string, responseName: string) => {
      setListFilters((value) => {
        (value as any)[listName] = (
          (response[responseName] as any[]) || []
        ).map(
          (item) =>
            ({
              label: item.titulo,
              value: item.id,
            } as IItemSelectField)
        );
        return value;
      });
    };

  return (
    <>
      <Loader
        show={loading}
        message="Aguarde enquanto o conteúdo é carregado..."
      />

      <div data-patrocinador-page>
        <HeaderSection>
          <Breadcrumb>
            <BreadcrumbItem to="/">Home</BreadcrumbItem>
            <BreadcrumbItem to="/parcerias">Parcerias</BreadcrumbItem>
          </Breadcrumb>

          <div data-header-section-sub-title>
            <h3>{parceria?.attributes?.titulo} </h3>
            <ParceriaTooltip
              title="Este conteúdo foi desenvolvido pela Sociedade Beneficente Israelita Brasileira Albert Einstein e tem por objetivo a difusão de conhecimento técnico-científico acerca do tema apresentado, contribuindo com o desenvolvimento das atividades de saúde. A Sociedade se compromete com a transparência e com a imparcialidade do conteúdo acadêmico, livre de qualquer propósito comercial ou vinculação com contratos de aquisição de produtos de eventual apoiador ou patrocinador."
              placement="bottom-start"
            >
              <img data-header-info src={InfoPatrocinadorBrancoHover} />
            </ParceriaTooltip>
          </div>

          <BannerParceiriaComponent bannersParceiria={parceria?.attributes} />
        </HeaderSection>

        <div data-patrocinador-content>
          <div data-search-oe-wrapper>
            <span>FILTRAR:</span>
            <SearchOE
              resetSearch={resetSearch}
              handleFilter={(filter) => {
                const variables = {
                  start: 0,
                  sort: "id:asc",
                  where: {
                    parceria: {
                      id: {
                        eq: params.id,
                      },
                    },
                    Status: {
                      eq: "Ativo",
                    },
                    AreasConhecimento: undefined as any,
                    Rotulos: undefined as any,
                    TipoObjeto: undefined as any,
                    PossuiCertificado: undefined as any,
                  },
                  whereUser: {
                    id: {
                      eq: !user ? "0" : user.id,
                    },
                  },
                };

                if (filter.areaConhecimento) {
                  variables.where.AreasConhecimento = [filter.areaConhecimento];
                }

                if (filter.rotulo) {
                  variables.where.Rotulos = [filter.rotulo];
                }

                if (filter.tipo) {
                  variables.where.TipoObjeto = [filter.tipo];
                }

                if (filter.ordenacao) {
                  variables.sort = filter.ordenacao.map((a: any) => a.value);
                }

                if (filter.possuiCertificado !== undefined) {
                  variables.where.PossuiCertificado =
                    filter.possuiCertificado || undefined;
                }

                setLoadingSearch(true);

                GetOes({ variables });
              }}
              theme="dark"
              enableAreaConhecimento
              enableOrdenacao
              enableRotulo
              enableTipo
              listFilters={listFilters}
            ></SearchOE>
          </div>

          <ResultOeSearch
            loading={loading}
            loadingSearch={loadingSearch}
            oes={oes}
          />
        </div>
      </div>
    </>
  );
};
