import React from "react";
import CertificadoBlue from "../../../assets/svgs/certificado-blue.svg";
import { ColorStyleType } from "../OeMedia/FooterOeMedia";

import "./styles.scss";

interface CertificadoCardProps {
  possuiCertificado: boolean;
  color: ColorStyleType;
}

const CertificadoCard: React.FC<CertificadoCardProps> = (props) => {
  return (
    <>
      {props.possuiCertificado && (
        <>
          <div data-certificado-card className="">
            <img src={CertificadoBlue} alt="" />
            <a>Possui declaração</a>
          </div>
        </>
      )}
    </>
  );
};

export default CertificadoCard;
