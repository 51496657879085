import axios from "axios";
import URL from "../constants/url.const";
import { TOKEN_SECRECT } from "../constants/auth-types";

export const request = (endPoint: string) => {
  const _ = `${URL.BASE_DOTNET}/${endPoint}`;
  return axios.get(_);
};

export const fetchApi = async (
  endPoint: string,
  method: "GET" | "POST" | "DELETE",
  body?: string,
  jwt?: string,
  maxRetries = 3,
): Promise<Response> => {
  const token = localStorage.getItem(TOKEN_SECRECT);
  const userData = token ? JSON.parse(token) : null;

  const headers: { "content-type": string; Authorization?: string } = {
    "content-type": "application/json",
  };

  const URL = `${process.env.REACT_APP_ROOT_DOTNET}/api/${endPoint}`;

  if (userData) {
    headers.Authorization = `Bearer ${userData.jwt}`;
  }

  let attempts = 0;
  let response = {
    ok: false,
    status: 400,
    statusText: "",
  } as Response;

  const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  while (attempts < maxRetries) {
    attempts++;
    try {
      response = await fetch(URL, {
        method,
        headers,
        body,
      });

      if (
        !response.ok &&
        (response.status === 400 || response.status === 403)
      ) {
        if (attempts < maxRetries) {
          console.log(`Tentativa ${attempts} falhou. Retentando...`);
          await delay(1000); // Aguarda 1 segundo antes de tentar novamente
          continue; // Tenta novamente
        } else {
          throw new Error(`Erro ${response.status}: ${response.statusText}`);
        }
      }

      return response; // Sucesso
    } catch (error) {
      console.log(`Erro na tentativa ${attempts}:`, error);
      if (attempts >= maxRetries) {
        throw error; // Lança o erro após atingir o número máximo de tentativas
      }
    }
  }

  return response;
};

export default fetchApi;
