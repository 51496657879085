import React from "react";

import BtnArrowBackIcon from "../../../assets/svgs/button-arrow-back.svg";

import { useBrowserHistory } from "../../../hooks/useBrowserHistory";

import "./styles.scss";
import { Button } from "../ui/button";
import { ArrowLeft } from "lucide-react";

export const OEBackButton: React.FC = () => {
  const browserHistory = useBrowserHistory();

  return (
    <button
      data-testid="btn-volta-pagina-anterior"
      style={{ display: browserHistory.history.length ? "flex" : "none" }}
      onClick={() => browserHistory.back()}
    >
      <ArrowLeft className="size-10 text-white-200" />
    </button>
  );
};
