import React from "react";
import ZoomInIcon from "../../../assets/svgs/toolbar-zoom-in-icon.svg";
import ZoomOutIcon from "../../../assets/svgs/toolbar-zoom-out-icon.svg";
import useZoomControls from "../../../hooks/useZoomControls";

interface ZoomControlsProps {
  /* O valor inicial do Zoom, default: 1 */
  current: number;
  /* O maximo de zoom permitido, default: 10*/
  max: number;
  /* Zoom In/Out callback handler, retorna o valor do zoom atual como primeiro argumento.*/
  handleChange?: (count: number) => void;
}

export const ZoomControls: React.FC<ZoomControlsProps> = ({
  current,
  max,
  handleChange,
}) => {
  const { zoomIn, zoomOut } = useZoomControls({
    current,
    max,
    handleChange,
  });

  return (
    <>
      <div onClick={zoomIn}>
        <img src={ZoomInIcon} alt="" />
      </div>
      <div onClick={zoomOut}>
        <img src={ZoomOutIcon} alt="" />
      </div>
    </>
  );
};
