import React from "react";
import { Parceria } from "../../../../generated/graphql";
import { criarUrl } from "../../../CategoriaPatrocinadoresPage";

import "./styles.scss";

interface BannerParceiriaInterface {
  bannersParceiria: Parceria | undefined;
}

export const BannerParceiriaComponent: React.FC<BannerParceiriaInterface> =
  ({ bannersParceiria }) => {
    return (
      <>
        {bannersParceiria && (
          <div data-banner-parceria-page>
            <div data-banner-parceria-carousel>
              {bannersParceiria.logo && (
                <img src={criarUrl(bannersParceiria?.logo?.data?.attributes?.url)} alt="" />
              )}
            </div>
          </div>
        )}
      </>
    );
  };
