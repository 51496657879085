import React from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
} from "../../../../shared/components/Breadcrumb";

interface BreadCrumbQuizProps {
  isTesteConhecimento?: boolean;
}
export const BreadCrumbQuiz: React.FC<BreadCrumbQuizProps> = ({
  isTesteConhecimento,
}) => {
  return (
    <Breadcrumb>
      <BreadcrumbItem to="/">Home</BreadcrumbItem>
      <BreadcrumbItem to="/metodos-ensino">Métodos de ensino</BreadcrumbItem>
      <BreadcrumbItem active>
        {isTesteConhecimento ? "Teste seus conhecimentos" : "Fast Challenge"}
      </BreadcrumbItem>
    </Breadcrumb>
  );
};
