import React from "react";
import { Tooltip } from "@material-ui/core";
import { useRouteMatch } from "react-router-dom";
import classNames from "classnames";
import { tv, VariantProps } from "tailwind-variants";

import {
  ObjetoEducacionalEntity,
  RotuloEntity,
  TipoOeEntity,
} from "../../../generated/graphql";
import { splitOeTitle } from "../../../helpers/oe-card.helper";
import {
  useGtm,
  EDataLayerEventAction,
  EDataLayerEventCategory,
} from "../../../hooks/useGtm";

import { DateView, SmartLink, RotuloCategoria } from "..";
import FooterOeMedia from "../OeMedia/FooterOeMedia";

import "./MediaCard.scss";
import { useUtils } from "../../utils";
interface MediaCardProps {
  id: string | number;
  title: string;
  image?: string;
  badgeRotulos?: RotuloEntity[];
  badgeSeen?: boolean;
  media?: {
    type?: TipoOeEntity[];
    duration?: string;
    favorite?: boolean | undefined;
  };
  oe: ObjetoEducacionalEntity;
  homePage?: boolean | undefined;
}

const div = tv({
  base: "flex flex-col w-full rounded-2xl bg-[#6d6e70]",
});

type MediaCardVariantProps = VariantProps<typeof div>;

export const MediaCard: React.FC<MediaCardProps & MediaCardVariantProps> = ({
  id,
  title,
  image,
  badgeRotulos,
  badgeSeen,
  media,
  oe,
  homePage,
  ...props
}) => {
  const gtm = useGtm();
  const { path } = useRouteMatch();
  const { formatTitle } = useUtils();

  const isPatrocinador = oe?.attributes?.patrocinador?.data
    ? (oe?.attributes?.patrocinador as any)?.data.id > 0
    : (oe?.attributes?.patrocinador as any).id;

  const isParceria =
    badgeRotulos?.find((f) => f.attributes?.titulo === "Cursos Online") != null;

  return (
    <div
      data-einstein-talks
      className={classNames(div(props), {
        "border-3 border-[#6d6e70]  h-[282px] ": isPatrocinador,
        "h-[282px]": !isPatrocinador,
      })}
    >
      <div
        className="relative w-full h-full bg-cover bg-center rounded-2xl !p-[1.875rem]"
        style={{
          backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.15) 0%, rgba(0,0,0,.4) 100%), 
          url(${image})
        `,
        }}
        onClick={(ev) => {
          ev.stopPropagation();
          if (path === "/favoritos") {
            gtm.push(
              EDataLayerEventCategory.ADE_MEU_PERFIL,
              EDataLayerEventAction.CLIQUE,
              {
                label: "acessar-favoritos",
              }
            );
          }
        }}
      >
        <SmartLink oe={oe}>
          <div className="h-full">
            <Tooltip title={title}>
              <h3
                className={classNames(
                  "text-base font-semibold text-white leading-normal max-w-[290px]",
                  {
                    mt: "6",
                  }
                )}
              >
                {splitOeTitle(title)}
              </h3>
            </Tooltip>
            <div className="flex flex-col justify-start mt-3">
              <div className="">
                {badgeRotulos &&
                  badgeRotulos.map((rotulo, idx) => (
                    <RotuloCategoria
                      key={idx}
                      data={rotulo.attributes?.titulo as string}
                    />
                  ))}
              </div>
              <span className="flex mt-3">
                {homePage && (
                  <DateView
                    color="white"
                    date={oe.attributes?.DataAtualizacaoProducao}
                  />
                )}
              </span>
            </div>
          </div>
        </SmartLink>
        <FooterOeMedia
          item={{
            Descricao: oe.attributes?.Descricao!,
            id: oe.id!,
            imagemPreview: oe.attributes?.ImagemPreview.data?.attributes?.url!,
            Infografico:
              oe.attributes?.Infografico?.map((a) => ({
                id: Number.parseInt(a.id!),
              })) || [],
            PossuiCertificado: oe.attributes?.PossuiCertificado!,
            SecoesTexto:
              oe.attributes?.SecoesTexto?.map((a) => ({
                id: Number.parseInt(a.id!),
              })) || [],
            TempoMedioDuracao: oe.attributes?.TempoMedioDuracao!,
            TipoObjeto:
              oe.attributes?.TipoObjeto?.data?.map((a) => ({
                titulo: a.attributes?.titulo!,
              })) || [],
            titulo: formatTitle(oe.attributes?.Titulo!),
            VideoAudio:
              oe.attributes?.VideoAudio?.map((a) => ({
                id: Number.parseInt(a.id!),
              })) || [],
            VideoId:
              oe.attributes?.VideoAudio && oe.attributes?.VideoAudio?.length > 0
                ? Number.parseInt(oe.attributes?.VideoAudio[0].id)
                : 0,
          }}
          color="white"
          padding={[16]}
          gradient={false}
          favoriteColor="yellow"
        />
      </div>

      {isPatrocinador && (
        <div className="flex relative w-full h-7 bg-[#6d6e70] rounded-2xl">
          <span className="flex items-center  text-white text-[10px] font-bold px-2">
            Conteúdo Patrocinado
          </span>
        </div>
      )}
    </div>
  );
};

// return (
//   <div
//     // data-media-card
//     className={classNames({
//       "oe-parceria": isParceria,
//       "oe-patrocinada": isPatrocinador,
//       "oe-nao-patrocinada": !isPatrocinador && !isParceria,
//     })}
//   >
//     <div
//       className="media-card"
//       style={{
//         backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.15) 0%, rgba(0,0,0,.4) 100%),
//         url(${props.image})
//       `,
//       }}
//     >
//       <SmartLink oe={props?.oe}>
//         <div
//           className="media-card__body"
//           onClick={(ev) => {
//             ev.stopPropagation();

//             if (path === "/favoritos") {
//               gtm.push(
//                 EDataLayerEventCategory.ADE_MEU_PERFIL,
//                 EDataLayerEventAction.CLIQUE,
//                 {
//                   label: "acessar-favoritos",
//                 }
//               );
//             }
//           }}
//         >
//           <Tooltip title={props?.title}>
//             <h3 className="media-card__title">{splitOeTitle(props.title)}</h3>
//           </Tooltip>

//           <div className="media-card__container">
//             <div className="media-card__badges">
//               {props.badgeRotulos &&
//                 props.badgeRotulos.map((rotulo, idx) => (
//                   <RotuloCategoria
//                     key={idx}
//                     data={rotulo.attributes?.titulo as string}
//                   />
//                 ))}
//             </div>
//             <span>
//               {props.homePage && (
//                 <DateView
//                   color="white"
//                   date={props.oe.attributes?.DataAtualizacaoProducao}
//                 />
//               )}
//             </span>
//           </div>
//         </div>
//       </SmartLink>
//       <FooterOeMedia
//         item={{
//           Descricao: props.oe.attributes?.Descricao!,
//           id: props.oe.id!,
//           imagemPreview:
//             props.oe.attributes?.ImagemPreview.data?.attributes?.url!,
//           Infografico:
//             props.oe.attributes?.Infografico?.map((a) => ({
//               id: Number.parseInt(a.id!),
//             })) || [],
//           PossuiCertificado: props.oe.attributes?.PossuiCertificado!,
//           SecoesTexto:
//             props.oe.attributes?.SecoesTexto?.map((a) => ({
//               id: Number.parseInt(a.id!),
//             })) || [],
//           TempoMedioDuracao: props.oe.attributes?.TempoMedioDuracao!,
//           TipoObjeto:
//             props.oe.attributes?.TipoObjeto?.data?.map((a) => ({
//               titulo: a.attributes?.titulo!,
//             })) || [],
//           titulo: props.oe.attributes?.Titulo!,
//           VideoAudio:
//             props.oe.attributes?.VideoAudio?.map((a) => ({
//               id: Number.parseInt(a.id!),
//             })) || [],
//           VideoId:
//             props.oe.attributes?.VideoAudio &&
//             props.oe.attributes?.VideoAudio?.length > 0
//               ? Number.parseInt(props.oe.attributes?.VideoAudio[0].id)
//               : 0,
//         }}
//         color="white"
//         padding={[15]}
//         gradient={isPatrocinador}
//         favoriteColor="yellow"
//       />
//     </div>
//     <footer
//       data-footer-oe-patrocinada={isPatrocinador}
//       data-footer-oe-parceria={isParceria}
//     >
//       <span>
//         {isPatrocinador ? "CONTEÚDO PATROCINADO" : "CONTEÚDO DE PARCEIROS"}
//       </span>
//     </footer>
//   </div>
// );
