import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

const formatarDuracao = (duracao: number): string => {
  if (duracao < 60) {
    return `${duracao} min`;
  } else {
    const hours = Math.floor(duracao / 60);
    const minutes = duracao % 60;
    return `${hours} hora e ${minutes} min`;
  }
};

const formatCustomDate = (date: string): string => {
  const data = new Date(date);
  return format(data, "dd MMM yyyy", { locale: ptBR });
};

const formatDuracaoMinutos = (time: number): string => {
  const hours = Math.floor(time / 60);
  const minutes = time % 60;
  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");
  return `${formattedHours}:${formattedMinutes}`;
};

export { formatarDuracao, formatCustomDate, formatDuracaoMinutos };
