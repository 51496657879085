import React, { useState, useEffect } from "react";

import {
  ObjetoEducacional,
  ObjetoEducacionalEntity,
} from "../../../generated/graphql";

import { LoaderContent, Pagination, Grid, MediaCard } from "../";

import "./styles.scss";
import { useUtils } from "../../utils";
import CardObjetoEducacionalGraphQL from "../oe-card-graphql";

interface ResultOeSearchProps {
  loading: boolean;
  loadingSearch: boolean;
  oes: ObjetoEducacionalEntity[];
}

interface ResultOeSearchState {
  raw: ObjetoEducacional[];
  resultado: ObjetoEducacional[];
  total: number;
  pagina: number;
  error: any;
  ordem: string;
}

export const ResultOeSearch: React.FC<ResultOeSearchProps> = (props) => {
  const tamanhoPagina = 16;
  const [bulletsCount, setBulletsCount] = useState(0);
  const [state, setState] = useState<ResultOeSearchState>({
    raw: [],
    resultado: [],
    total: 0,
    pagina: 1,
    error: null,
    ordem: "desc",
  });

  const { formatTitle } = useUtils();

  useEffect(() => {
    if (!props.oes) {
      return;
    }

    if (bulletsCount === 0) {
      const _bulletsCount = Math.abs(
        Math.ceil(props.oes.length / tamanhoPagina)
      );
      setBulletsCount(_bulletsCount);
    }
  }, [props.oes, bulletsCount]);

  const handlePageChange = (event: any, page: number) => {
    event.preventDefault();
    setState({
      ...state,
      pagina: page,
    });
  };

  return (
    <>
      {props.loadingSearch && (
        <LoaderContent show={props.loadingSearch}></LoaderContent>
      )}

      {!props.loadingSearch && !!props.oes.length && (
        <section data-oe-search-result>
          <div data-pagination-top>
            {props.oes.length > 16 && (
              <Pagination
                count={bulletsCount}
                page={state.pagina}
                onChange={handlePageChange}
                disabled={props.loading}
              />
            )}
          </div>
          <Grid cols={3} gap={4}>
            {props.oes
              .slice(
                (state.pagina - 1) * tamanhoPagina,
                (state.pagina - 1) * tamanhoPagina + tamanhoPagina
              )
              .map((oe: ObjetoEducacionalEntity, index: number) => (
                <div key={index} className="grid-media-card-item">
                  <CardObjetoEducacionalGraphQL
                    objetoEducacional={oe}
                  />
                </div>
              ))}
          </Grid>
          {props.oes.length > 16 && (
            <Pagination
              count={bulletsCount}
              page={state.pagina}
              onChange={handlePageChange}
              disabled={props.loading}
            />
          )}
        </section>
      )}

      {!props.loadingSearch && !props.oes.length && (
        <section data-empty>
          <h4>Não existe nenhum resultado!</h4>
        </section>
      )}
    </>
  );
};
