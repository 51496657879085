import React, { useRef } from "react";
import { SwiperSlide } from "swiper/react";

import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../../hooks/useGtm";

import Container from "../../../shared/components/Container";
import {
  EnsinoEinsteinCursoEntityResponse,
  useGetEnsinoEinsteinCursosQuery,
} from "../../../generated/graphql";

import CustomSwiper from "../../../shared/components/CustomSwiper";

const EnsinoEinsteinCards = ({ data }: EnsinoEinsteinCursoEntityResponse) => {
  const { data: dataEnsino, error } = useGetEnsinoEinsteinCursosQuery();

  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const refPagination = useRef<HTMLDivElement>(null);

  const gtm = useGtm();
  return (
    <div className="bg-[#F4F4F4] py-8">
      <Container>
        <div className="flex w-full flex-col justify-center items-center">
          <span className="text-[#004F92] text-2xl md:text-3xl font-bold normal-case justify-center flex w-full flex-nowrap">
            <span className="hidden md:flex mr-2">
              Do Ensino Médio ao Doutorado.
            </span>
            <span>Conheça nossos cursos</span>
          </span>
        </div>

        <div className="relative h-full w-full py-8 min-h-[300px]">
          <CustomSwiper
            className="rounded-xl"
            breakpoints={{
              640: {
                slidesPerView: 1,
                slidesPerGroup: 1,
              },
              768: {
                slidesPerView: 3,
                slidesPerGroup: 3,
              },
              1024: {
                slidesPerView: 4,
                slidesPerGroup: 4,
              },
            }}
            spaceBetween={20}
            previousRef={prevRef}
            nextRef={nextRef}
            refPagination={refPagination}
            navigation={true}
            pagination={true}
            quantidade={dataEnsino?.ensinoEinsteinCursos?.data?.length || 0}
          >
            {dataEnsino?.ensinoEinsteinCursos?.data.map((card, index) => (
              <SwiperSlide key={index}>
                <div
                  key={index}
                  className="flex flex-row items-end cursor-pointer  justify-center p-8 h-[418px] w-full md:w-full mix-blend-multiply rounded-lg"
                  style={{
                    backgroundImage: `url(${card.attributes?.imagem.data?.attributes?.url})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                  onClick={() => {
                    // GTM doc linha 83
                    gtm.push(
                      EDataLayerEventCategory.ADE_ENSINO_EINSTEIN,
                      EDataLayerEventAction.CLIQUE,
                      {
                        label: card.attributes?.titulo!,
                      }
                    );
                    window.open(card.attributes?.link, "_blank");
                  }}
                >
                  <div className="flex flex-col z-10 w-full">
                    <div className="flex flex-row w-full">
                      <img
                        src={card.attributes?.icone?.data?.attributes?.url}
                        className="w-12"
                      />
                      <div className="border-b-2 w-full border-white" />
                    </div>
                    <span className="uppercase text-[0.95rem] text-white font-bold leading-4 max-w-[200px] flex-wrap mt-9">
                      {card.attributes?.titulo}
                    </span>
                    <span className="text-sm font-normal text-white flex-wrap w-52 leading-4 mt-2">
                      {card.attributes?.descricao}
                    </span>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </CustomSwiper>
        </div>
      </Container>
    </div>
  );
};

export default EnsinoEinsteinCards;
