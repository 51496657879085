import { Card, CardContent, CardFooter, CardHeader } from "./ui/card";
import Rotulo from "./rotulos";
import { cn } from "../utils/utils";
import {
  getDescricaoTipoObjeto,
  getIconeTipoObjeto,
  getTipoObjeto,
} from "../utils/tipo-objeto";
import CalendarBlue from "../../assets/svgs/icons/calendar.svg";
import DeclaracaoIcon from "../../assets/svgs/new/certificado.svg";
import { typeObjetoEducacional } from "../../models/TipoObjetoEducacional";
import { ObjetoEducacionalEntity } from "../../generated/graphql";
import { getUserContext } from "../../context/UserContext";
import { SmartLink } from "./SmartLink";
import { Favoritar } from "./Favoritar";
import React from "react";
import { Badge } from "./ui/badge";

export const getIcon = (type: number) => {
  switch (type) {
    case typeObjetoEducacional.VIDEO:
      return "/assets/svg/tipo-objetos/video.svg";
    case typeObjetoEducacional.QUESTIONARIO:
      return "/assets/svg/tipo-objetos/questionario.svg";
    case typeObjetoEducacional.TEXTO:
      return "/assets/svg/tipo-objetos/texto.svg";
    case typeObjetoEducacional.IMAGEM:
      return "/assets/svg/tipo-objetos/imagem.svg";
    case typeObjetoEducacional.PDF:
      return "/assets/svg/tipo-objetos/pdf.svg";
    case typeObjetoEducacional.AUDIO:
      return "/assets/svg/tipo-objetos/audio.svg";
    default:
      return "";
  }
};

export default function CardObjetoEducacionalGraphQL({
  objetoEducacional,
}: {
  objetoEducacional: ObjetoEducacionalEntity;
}) {
  function formatarTempoMinutos(minutes: string) {
    const totalMinutes = Number(minutes);
    const hours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;
    const formattedMinutes = remainingMinutes.toString().padStart(2, "");
    const formattedSeconds = "00";

    if (hours > 0) {
      return `${hours}:${formattedMinutes}:${formattedSeconds}`;
    } else {
      return `${formattedMinutes}:${formattedSeconds}`;
    }
  }

  const getFinalizado = (item: ObjetoEducacionalEntity) => {
    const user = getUserContext();
    if (!!user) {
      if (
        item.id &&
        ((item.attributes?.VideoAudio &&
          item.attributes?.VideoAudio.length &&
          user?.attributes?.OesConsumidosParcialmente?.data.some(
            (itemConsumo) =>
              item.attributes?.VideoAudio &&
              item.attributes.VideoAudio[0].toString() ===
                itemConsumo?.attributes?.VideoAudioId?.toString() &&
              itemConsumo.attributes?.duracao &&
              itemConsumo.attributes?.segundos &&
              itemConsumo.attributes?.duracao ===
                itemConsumo.attributes?.segundos,
          )) ||
          (item.attributes?.SecoesTexto &&
            item.attributes?.SecoesTexto.length &&
            item.attributes?.SecoesTexto.some((leitura) =>
              user?.attributes?.OesConsumidosParcialmente?.data.some(
                (itemConsumo) =>
                  leitura?.id.toString() ===
                    itemConsumo.attributes?.LeituraOeId?.toString() &&
                  itemConsumo.attributes?.duracao &&
                  itemConsumo.attributes?.segundos &&
                  itemConsumo.attributes?.duracao ===
                    itemConsumo.attributes?.segundos,
              ),
            )) ||
          (item.attributes?.Infografico &&
            item.attributes?.Infografico.length &&
            item.attributes?.Infografico.some((imagem) =>
              user?.attributes?.OesConsumidosParcialmente?.data.some(
                (itemConsumo) =>
                  imagem?.id.toString() ===
                    itemConsumo.attributes?.ImagemOeId?.toString() &&
                  itemConsumo.attributes?.duracao &&
                  itemConsumo.attributes?.segundos &&
                  itemConsumo.attributes?.duracao ===
                    itemConsumo.attributes?.segundos,
              ),
            )))
      ) {
        return true;
      }

      return false;
    }
  };

  return (
    <Card className="flex h-full flex-col gap-2 shadow-none">
      <SmartLink
        oe={objetoEducacional}
        onShowCertificate={() => {}}
        isListCertificados={false}
      >
        <CardHeader className="relative h-[188.42px] md:h-[227.66px]">
          <img
            alt="imagem capa objeto educacional"
            src={
              objetoEducacional?.attributes?.ImagemPreview.data?.attributes
                ?.url ?? ""
            }
            width={300}
            height={150}
            className={cn(
              "absolute left-0 top-0 h-full w-full rounded-xl object-cover object-top ",
              objetoEducacional?.attributes?.patrocinador?.data?.id
                ? "border-4 border-[#626466]"
                : "",
            )}
          />
          <img
            src={getTipoObjeto(
              parseInt(
                objetoEducacional?.attributes?.TipoObjeto?.data[0].id ?? "0",
              ),
            )}
            alt="ícone tipo objeto educacional"
            width={60} //60 52.03
            height={60} //60 52.03
            className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
          />
          <div className="absolute left-[24px] top-[17.78px] max-w-sm md:left-6 md:top-6">
            <Rotulo
              rotuloName={
                objetoEducacional?.attributes?.Rotulos?.data &&
                objetoEducacional?.attributes?.Rotulos?.data?.length > 0
                  ? objetoEducacional?.attributes?.Rotulos?.data[0].attributes
                      ?.titulo ?? ""
                  : ""
              }
              id={
                objetoEducacional?.attributes?.Rotulos?.data &&
                objetoEducacional?.attributes?.Rotulos?.data?.length > 0
                  ? parseInt(
                      objetoEducacional?.attributes?.Rotulos?.data[0].id ?? "0",
                    )
                  : 0
              }
            />
          </div>
          <div className="absolute right-5 top-5 md:right-6 md:top-6">
            <Favoritar
              idOe={objetoEducacional?.id?.toString() || "0"}
              color="yellow"
              item={{
                id: objetoEducacional?.id?.toString()!,
                titulo: objetoEducacional?.attributes?.Titulo!,
              }}
            />
          </div>
          {objetoEducacional && (
            <Badge className="absolute bottom-[19.78px] right-6 border-0 border-none bg-black-900">
              <span className="text-xxs  font-bold text-white-200 md:text-xs">
                {formatarTempoMinutos(
                  objetoEducacional?.attributes?.TempoMedioDuracao ?? "",
                )}
              </span>
            </Badge>
          )}

          {getFinalizado(objetoEducacional) && (
            <span className="absolute bottom-[19.78px] left-[19.78px] text-xxs font-bold text-white-200 md:bottom-6 md:left-6 md:text-xs">
              Visualizado
            </span>
          )}
        </CardHeader>
      </SmartLink>
      <CardContent className="flex flex-col gap-2 p-0">
        {objetoEducacional?.attributes?.patrocinador?.data?.id && (
          <span className="text-[10px] font-bold text-[#626466]">
            CONTEÚDO PATROCINADO
          </span>
        )}
        <span className="text-sm font-semibold leading-4 text-[#111316] md:text-base tablet:text-xs tablet:leading-3">
          <SmartLink
            oe={objetoEducacional}
            onShowCertificate={() => {}}
            isListCertificados={false}
          >
            {objetoEducacional?.attributes?.Titulo}
          </SmartLink>
        </span>
      </CardContent>
      <></>
      <CardFooter className="flex flex-col items-start gap-[3px] p-0">
        <div className="flex flex-row gap-2">
          <div className="flex flex-row items-center gap-1">
            {getIconeTipoObjeto(
              parseInt(
                objetoEducacional?.attributes?.TipoObjeto?.data[0].id ?? "0",
              ),
              "#004F92",
              20,
            )}
            <span className="text-xxs font-semibold text-[#111316] md:text-[11px]">
              {getDescricaoTipoObjeto(
                parseInt(
                  objetoEducacional?.attributes?.TipoObjeto?.data[0].id ?? "0",
                ),
              )}
            </span>
          </div>

          <div className="flex flex-row items-center gap-1">
            <img
              src={CalendarBlue}
              alt="icon-video"
              width={20} //24
              height={20} //24
            />
            <span className="text-xxs font-semibold text-[#111316] md:text-[11px]">
              {objetoEducacional?.attributes?.DataAtualizacaoProducao &&
                objetoEducacional?.attributes?.DataAtualizacaoProducao.split(
                  "-",
                )
                  .reverse()
                  .join("/")}
            </span>
            {objetoEducacional?.attributes?.PossuiCertificado && (
              <div className="flex flex-row items-center gap-[3px]">
                <img
                  src={DeclaracaoIcon}
                  alt="ícone certificado"
                  width={20} //24
                  height={20} //24
                />

                <span className="text-xxs font-semibold leading-18 text-[#111316] md:text-xs md:leading-4 tablet:text-xxs">
                  Declaração de participação
                </span>
              </div>
            )}
          </div>
        </div>
      </CardFooter>
    </Card>
  );
}
