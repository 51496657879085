/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import HtmlParse from "html-react-parser";
import { useHistory } from "react-router-dom";
import {
  ComponentSecaoVideoAudioVideoAudio,
  ComponentTextoSecaoPdf,
  ObjetoEducacionalEntity,
  UsersPermissionsUserEntity,
} from "../../../../generated/graphql";

import { UserContext } from "../../../../context/UserContext";
import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../../../hooks/useGtm";

import { TIPO_AREA_PAGINA, TIPO_PAGINA } from "../../../../hooks/useBannerApi";
import { Tags } from "../../../../shared/components/Tags";

import AddBlue from "../../../../assets/svgs/add.svg";
import { format } from "date-fns";
import ptBR from "date-fns/esm/locale/pt-BR/index.js";
import Container from "../../../../shared/components/Container";
import { useUtils } from "../../../../shared/utils";
import {
  Banner,
  loadBanner,
} from "../../../../graphql/collections/queries/banner.query";
import {
  AudioLines,
  Calendar,
  Clock4,
  PieChart,
  Play,
  Text,
  Video,
} from "lucide-react";
import { cn } from "../../../../lib/utils";
import Rotulo from "../../../../shared/components/rotulos";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../shared/components/ui/dialog";
import { BannerSection } from "../../../../shared/components";
import { Badge } from "../../../../shared/components/ui/badge";

interface OePreviewProps {
  oe: ObjetoEducacionalEntity;
  history?: any;
  location?: any;
  match?: any;
  user?: UsersPermissionsUserEntity;
  type: TypeOE;
}

export enum TypeOE {
  Video = "video",
  PDF = "pdf",
  Texto = "texto",
  Imagem = "imagem",
  Webcast = "webcast",
  Audio = "audio",
}

export const OePreviewDesktop: React.FC<OePreviewProps> = (props) => {
  const gtm = useGtm();
  const { user } = useContext(UserContext);
  const history = useHistory();
  const [limitTag, setLimitTag] = useState<boolean>(true);

  const [showBanner, setShowBanner] = useState(true);
  const [arquivoAtivo, setArquivoAtivo] = useState<
    ComponentTextoSecaoPdf | ComponentSecaoVideoAudioVideoAudio | undefined
  >(undefined);
  const [tipoOE, setTipoOe] = useState<TypeOE>(props.type);
  const { formatTitle } = useUtils();

  const [banners, setBanners] = useState<Banner[]>([]);

  const carregarBanner = async () => {
    const resultBanner = await loadBanner({
      homePage: false,
      objetoEducacionalId: props?.oe.id! ? Number(props?.oe.id!) : undefined,
      areaDeConhecimentoId:
        props?.oe.attributes?.SubAreaConhecimento?.data
          ?.filter((sub) => {
            return !!sub.attributes?.area_de_conhecimento?.data?.id;
          })
          ?.map((a) => Number(a.attributes?.area_de_conhecimento?.data?.id!)) ||
        [],
      subAreaDeConhecimentoId:
        props?.oe.attributes?.SubAreaConhecimento?.data?.map((a) =>
          Number(a.id!),
        ) || [],
      publicoAlvoId:
        props?.oe.attributes?.PublicoAlvo?.data?.map((a) => Number(a.id!)) ||
        [],
    });

    setBanners(resultBanner);
  };

  useEffect(() => {
    carregarBanner();
  }, [props.oe]);

  useEffect(() => {
    if (props.oe.attributes?.PDF && props.oe.attributes?.PDF[0]) {
      setArquivoAtivo(props.oe.attributes?.PDF[0]);
      setTipoOe(TypeOE.PDF);
    }
    if (props.oe.attributes?.VideoAudio && props.oe.attributes?.VideoAudio[0]) {
      setArquivoAtivo(props.oe.attributes?.VideoAudio[0]);
    }
  }, [props]);

  const closeBanner = () => {
    setShowBanner(!showBanner);
  };

  const getUrlLerAgora = (arquivo = arquivoAtivo): string => {
    switch (tipoOE) {
      case TypeOE.PDF:
        if (props.oe.id && arquivo?.id) {
          return `/oe/${props.oe.id}/pdf/${arquivo?.id}`;
        }
        return `/oe/${props.oe.id}/pdf`;
      case TypeOE.Texto:
        return `/oe/${props.oe.id}/leitura`;
      case TypeOE.Imagem:
        return `/oe/${props.oe.id}/imagem`;
      case TypeOE.Video:
        if (props.oe.id && arquivo?.id) {
          if (
            props.oe.attributes?.TipoObjeto?.data.find(
              (e) => e.attributes?.titulo == "Vídeo",
            )
          ) {
            return `/oe/${props.oe.id}/video/${arquivo?.id}`;
          } else {
            return `/oe/${props.oe.id}/webcast/${arquivo?.id}`;
          }
        }
        return `/oe/${props.oe.id}/video`;
      default:
        return "";
    }
  };

  const getIcon = () => {
    switch (tipoOE) {
      case TypeOE.PDF:
        return <div>PDF</div>;
      case TypeOE.Texto:
        return <Text size={16.94} />;
      case TypeOE.Imagem:
        return <PieChart size={16.94} />;
      case TypeOE.Video:
        return props.oe.attributes?.TipoObjeto?.data.find(
          (e) => e.attributes?.titulo === "Vídeo",
        ) ? (
          <Video size={16.94} />
        ) : (
          <AudioLines size={16.94} />
        );
      default:
        return <></>;
    }
  };

  const getTextoTipo = () => {
    switch (tipoOE) {
      case TypeOE.PDF:
      case TypeOE.Texto:
      case TypeOE.Imagem:
        return "Leitura";
      case TypeOE.Video:
        return props.oe?.attributes?.TipoObjeto?.data.find(
          (e) => e.attributes?.titulo == "Vídeo",
        )
          ? "Vídeo"
          : "Vídeocast";
      default:
        return "";
    }
  };

  const getLabelButton = () => {
    switch (tipoOE) {
      case TypeOE.PDF:
        return "Ler agora";
      case TypeOE.Texto:
        return "Ler agora";
      case TypeOE.Imagem:
        return "Ler agora";
      default:
        return "";
    }
  };

  const fundoBlack = () => {
    switch (tipoOE) {
      case TypeOE.PDF:
      case TypeOE.Texto:
      case TypeOE.Imagem:
        return false;
      default:
        return true;
    }
  };

  const getIconTimer = () => {
    switch (tipoOE) {
      case TypeOE.PDF:
      case TypeOE.Texto:
      case TypeOE.Imagem:
        return <Clock4 size={16.94} />;
      default:
        return <Clock4 size={16.94} />;
    }
  };

  const getIconCalendar = () => {
    switch (tipoOE) {
      case TypeOE.PDF:
      case TypeOE.Texto:
      case TypeOE.Imagem:
        return <Calendar size={16.94} />;
      default:
        return <Calendar size={16.94} />;
    }
  };

  const getButtonIniciar = () => {
    switch (tipoOE) {
      case TypeOE.PDF:
      case TypeOE.Texto:
      case TypeOE.Imagem:
        return (
          <a
            className="text-white text-white mt-1 flex h-[58px] w-auto cursor-pointer items-center justify-center  rounded-[30px] bg-[#0A589B]
             !px-10 !py-5 text-ssm font-semibold hover:bg-[#0b3962]"
            onClick={() => {
              // GTM doc linha 86
              history.push(getUrlLerAgora());
              gtm.push(
                EDataLayerEventCategory.ADE_OE_INTERACAO_TEXTO,
                EDataLayerEventAction.CLIQUE,
                {
                  label: `${getLabelButton()
                    .split(" ")
                    .join("-")
                    .toLowerCase()}:${formatTitle(
                    props.oe.attributes?.Titulo!,
                  )}`,
                },
              );
            }}
          >
            <span>{getLabelButton()}</span>
          </a>
        );
      default:
        return <div className=""></div>;
    }
  };

  const tempoMeioDuracao: string | number = parseInt(
    props.oe?.attributes?.TempoMedioDuracao.replace(/[^\d]/g, "") || "0",
  );

  const tempoFormatado = tempoMeioDuracao + " minutos";

  const tags = limitTag
    ? props.oe?.attributes?.palavras_chave?.data.slice(0, 6)
    : props.oe?.attributes?.palavras_chave?.data;

  function moveNameToFront(array: string[], name: string): string[] {
    const index = array.indexOf(name);

    if (index > -1) {
      // Remove o nome do array
      array.splice(index, 1);
      // Adiciona o nome na primeira posição
      array.unshift(name);
    }

    return array;
  }

  const userProfissao =
    (user?.attributes?.PerfilProfissao?.data?.length || 0) > 0
      ? user?.attributes?.PerfilProfissao?.data[0].attributes?.titulo || ""
      : "";
  const publicosAlvos = moveNameToFront(
    props.oe.attributes?.PublicoAlvo?.data.map(
      (a) => a.attributes?.titulo || "",
    ) || [],
    userProfissao,
  );

  return (
    <>
      <section
        className="flex h-[592px] w-full bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${props.oe.attributes?.ImagemCapa?.data?.attributes?.url})`,
        }}
      >
        <Container className="relative">
          {fundoBlack() && (
            <button
              onClick={() => {
                // GTM doc linha 86

                gtm.push(
                  EDataLayerEventCategory.ADE_OE_INTERACAO_VIDEO,
                  EDataLayerEventAction.CLIQUE,
                  {
                    label: `iniciar-video:${formatTitle(
                      props.oe.attributes?.Titulo!,
                    )}`,
                  },
                );
                history.push(getUrlLerAgora());
              }}
              className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 cursor-pointer"
            >
              <Play size={60} color="white" />
            </button>
          )}
          <div className="flex h-full w-[364px] flex-col items-start justify-center gap-6">
            <div className="flex flex-col gap-3">
              <div className="flex w-auto gap-2">
                {props.oe.attributes?.Rotulos?.data.map((rotulo, index) => (
                  <Rotulo
                    key={index}
                    id={Number(rotulo?.id!)}
                    rotuloName={rotulo.attributes?.titulo ?? ""}
                  />
                ))}
                {props?.oe?.attributes?.VideoAudio &&
                  props.oe.attributes.VideoAudio.length > 1 && (
                    <Badge className="border bg-transparent text-white-200">
                      {`${props.oe.attributes.VideoAudio.length} Episódios`}
                    </Badge>
                  )}
              </div>
              <h3
                className={`text-xxl font-medium  leading-[26.82px] ${
                  fundoBlack() ? "text-[#FDFEFF] " : "text-[#004F92]"
                }`}
              >
                {formatTitle(props.oe.attributes?.Titulo!)}
              </h3>
              <div>
                <Dialog>
                  <DialogTrigger asChild>
                    <div className="text-sm font-medium leading-[17.07px]">
                      <p
                        className={`!line-clamp-2 inline-block  ${
                          fundoBlack() ? "text-[#FDFEFF] " : "text-[#111316]"
                        }`}
                      >
                        {props.oe.attributes?.Descricao &&
                          HtmlParse(
                            props.oe.attributes?.Descricao.replace(
                              "<p>",
                              "<p className={`!line-clamp-2 text-sm font-medium leading-[17.07px]`}>",
                            ),
                          )}
                      </p>
                      {props.oe.attributes?.Descricao?.length! > 200 && (
                        <span
                          className={`font-bold underline ${
                            fundoBlack() ? "text-[#FDFEFF] " : "text-[#111316]"
                          }`}
                        >
                          Mostrar mais
                        </span>
                      )}
                    </div>
                  </DialogTrigger>
                  <DialogContent className="py-8">
                    <DialogHeader className="flex w-full items-start justify-start">
                      <DialogTitle className="text-base  font-bold normal-case leading-22 text-[#004F92]">
                        Descrição
                      </DialogTitle>
                    </DialogHeader>
                    <div className="flex items-center space-x-2">
                      <div className="grid flex-1 gap-2">
                        <p
                          className={`inline-block text-sm font-normal leading-[20px] text-[#313335]`}
                        >
                          {props.oe.attributes?.Descricao &&
                            HtmlParse(
                              props.oe.attributes?.Descricao.replace(
                                "<p>",
                                "<p className={` text-sm font-normal leading-[20px] text-[#313335]`}>",
                              ),
                            )}
                        </p>
                      </div>
                    </div>
                  </DialogContent>
                </Dialog>
              </div>
            </div>
            <div className="flex flex-row gap-3">
              <div
                className={`flex items-center gap-1
              ${fundoBlack() ? "text-[#FDFEFF] " : "text-[#111316]"}`}
              >
                {getIcon()}
                <span
                  className={cn(
                    {
                      colorWhite: fundoBlack(),
                    },
                    "text-xs font-bold uppercase leading-[30px]",
                  )}
                >
                  {getTextoTipo()}
                </span>
              </div>
              <div
                className={`flex items-center gap-1 ${
                  fundoBlack() ? "text-[#FDFEFF] " : "text-[#111316]"
                }`}
              >
                {getIconCalendar()}
                <span
                  className={cn(
                    {
                      colorWhite: fundoBlack(),
                    },
                    "text-xs font-bold uppercase leading-[30px]",
                  )}
                >
                  {props.oe.attributes?.DataProducao
                    ? format(
                        new Date(
                          `${props.oe.attributes?.DataProducao}T00:00:00`,
                        ),
                        "dd MMM yy",
                        { locale: ptBR },
                      )
                    : ""}
                </span>
              </div>
              <div
                className={`flex items-center gap-1 ${
                  fundoBlack() ? "text-[#FDFEFF] " : "text-[#111316]"
                }`}
              >
                {getIconTimer()}
                <span
                  className={cn(
                    {
                      colorWhite: fundoBlack(),
                    },
                    "text-xs font-bold uppercase leading-[30px]",
                  )}
                >
                  {tempoFormatado}
                </span>
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <div className="flex flex-col flex-nowrap">
                <div className="flex flex-col gap-3">
                  <p
                    className={`text-sm font-bold leading-[17.07px] ${
                      fundoBlack() ? "text-[#FDFEFF] " : "text-[#111316]"
                    }`}
                  >
                    Recomendado:
                  </p>

                  <Dialog>
                    <DialogTrigger asChild>
                      <div className="text-sm font-medium leading-[17.07px]">
                        <span
                          className={`!line-clamp-2 inline-block text-sm font-medium leading-[17.07px] ${
                            fundoBlack() ? "text-[#FDFEFF] " : "text-[#111316]"
                          }`}
                        >
                          {publicosAlvos.map((p, index, arr) =>
                            index === arr.length - 1 ? `${p}` : `${p}, `,
                          )}
                        </span>
                        {publicosAlvos.length! > 6 && (
                          <span
                            className={`font-bold  underline ${
                              fundoBlack()
                                ? "text-[#FDFEFF] "
                                : "text-[#111316]"
                            }`}
                          >
                            Mostrar mais
                          </span>
                        )}
                      </div>
                    </DialogTrigger>
                    <DialogContent className="py-8">
                      <DialogHeader className="flex w-full items-start justify-start">
                        <DialogTitle className="text-base  font-bold normal-case leading-22 text-[#004F92]">
                          Recomendado
                        </DialogTitle>
                      </DialogHeader>
                      <div className="flex items-center space-x-2">
                        <div className="grid flex-1 gap-2">
                          <p
                            className={`inline-block text-sm font-normal leading-[20px] text-[#313335]`}
                          >
                            {publicosAlvos.map((p, index, arr) =>
                              index === arr.length - 1 ? `${p}` : `${p}, `,
                            )}
                          </p>
                        </div>
                      </div>
                    </DialogContent>
                  </Dialog>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <p
                className={`text-sm font-bold leading-[17.07px] ${
                  fundoBlack() ? "text-[#FDFEFF] " : "text-[#111316]"
                }`}
              >
                Palavras-chave:
              </p>

              <div className="flex flex-wrap gap-[6px]">
                {tags?.map((m, index) => (
                  <Tags
                    className={cn(
                      fundoBlack()
                        ? ""
                        : "hover:brightness-40 bg-[#F5F8FF] shadow-sm [&>span]:text-[#004F92]",
                    )}
                    key={index}
                    isSearchable={true}
                    title={m.attributes?.titulo!}
                  />
                ))}
              </div>
            </div>
            {limitTag &&
              props.oe.attributes?.palavras_chave?.data.length !==
                tags?.length && (
                <div
                  className="m-0 flex items-center gap-2"
                  onClick={() => setLimitTag(false)}
                >
                  <img className="m-0 h-6 w-6 p-0" src={AddBlue} alt="" />
                  <span
                    className={`w-[100px] cursor-pointer flex-nowrap text-xs font-bold leading-none text-[#FDFEFF] ${
                      fundoBlack() ? "text-[#FDFEFF] " : "text-[#707070]"
                    }`}
                  >
                    Ver todas
                  </span>
                </div>
              )}
            <nav className="flex">{getButtonIniciar()}</nav>
          </div>
        </Container>
      </section>
      <BannerFixoPreview
        banners={banners}
        user={user}
        showBanner={showBanner}
        closeBanner={closeBanner}
      />
    </>
  );
};

function BannerFixoPreview({
  banners,
  user,
  showBanner,
  closeBanner,
}: {
  banners: Banner[];
  user: UsersPermissionsUserEntity | undefined;
  showBanner: boolean;
  closeBanner: () => void;
}) {
  return (
    <>
      {(() => {
        let bannersFixo = banners?.filter(
          (a) =>
            a.tipoPagina === TIPO_PAGINA.OE &&
            a.areaPagina === TIPO_AREA_PAGINA.FIXO &&
            ((!!user?.id && a.ocultarLogado === false) || !user?.id),
        );

        return (
          <>
            {showBanner && !!bannersFixo && !!bannersFixo.length && (
              <div
                data-banner
                style={{
                  zIndex: 99999,
                }}
                className={
                  !user
                    ? "deslogado"
                    : "fixed bottom-0 left-0 right-0 z-50 mx-auto flex w-[728px] flex-col items-center justify-center"
                }
              >
                <BannerSection
                  fixo={true}
                  items={bannersFixo}
                  onClose={closeBanner}
                  showBanner={showBanner}
                />
              </div>
            )}
          </>
        );
      })()}
    </>
  );
}
