import React, { useEffect, useState } from "react";

import { useFormik } from "formik";
import * as yup from "yup";

import { ADEInputPassword, CardContainer } from "../../shared/components";
import { ModalSolicitaMudancaSenha } from "./components";
import { ModalService } from "../../shared/components/Modal";

import "./styles.scss";
import { Button } from "../../shared/components/ui/button";
import { CircleCheckBig } from "lucide-react";
import { ToastContainer, toast } from "react-toastify";

interface RedefinicaoSenha {
  senha: string;
  confirmacaoSenha: string;
  hash: string;
}

export interface PasswordRules {
  rule: RegExp;
  error: string | undefined | null | any;
}

const EsqueciMinhaSenhaPage: React.FC<any> = (props) => {
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [expired, setExpired] = useState(false);
  const [response, setResponse] = useState<any>({});

  const formik = useFormik<RedefinicaoSenha>({
    initialValues: {
      senha: "",
      confirmacaoSenha: "",
      hash: props.match.params.hash,
    },
    validationSchema: yup.object().shape({
      senha: yup
        .string()
        .required("Campo obrigatório")
        .test({
          test: (value) => {
            const erros = validarSenha(value || "");

            if (!erros.length) {
              return true;
            }

            return new yup.ValidationError(erros.join(", "), null, "senha");
          },
        }),
      confirmacaoSenha: yup
        .mixed()
        .test("senha", "Senha não confere", function () {
          return this.parent.senha === this.parent.confirmacaoSenha;
        }),
    }),
    onSubmit: (dados) => {
      fetch(`${process.env.REACT_APP_CHANGE_PASSWORD_URL}`, {
        method: "POST",
        headers: new Headers({ "content-type": "application/json" }),
        body: JSON.stringify(dados),
      }).then(() => {
        switch (response.status) {
          case 200:
            setSuccess(true);
            break;

          case 410:
            setExpired(true);
            break;

          case 404:
          case 500:
            setFailed(true);
            break;
        }
      });
    },
  });

  useEffect(() => {
    async function _fetch() {
      const response = await fetch(
        `${process.env.REACT_APP_CHANGE_PASSWORD_HASH_VERIFY_URL}`,
        {
          method: "POST",
          headers: new Headers({ "content-type": "application/json" }),
          body: JSON.stringify({
            hash: props.match.params.hash,
          }),
        },
      );

      setResponse(response);

      switch (response.status) {
        case 200:
          break;

        case 404:
          setFailed(true);
          break;

        case 410:
          setExpired(true);
          break;
      }
    }

    _fetch();
  }, []);

  const mostrarModalSolicitacaoMudanca = (e: any) => {
    e.preventDefault();

    ModalService.show({
      content: <ModalSolicitaMudancaSenha />,
    });
  };

  const mostrarMensagemLinkExpirado = () => {
    return (
      <div data-password-reset>
        <ToastContainer />
        <CardContainer shadow>
          <h3>Link expirado</h3>
          <p>
            <a href="" onClick={mostrarModalSolicitacaoMudanca}>
              Clique aqui
            </a>{" "}
            para solicitar novo envio.
          </p>
        </CardContainer>
      </div>
    );
  };

  const mostrarMensagemUrlInvalida = () => {
    return (
      <div data-password-reset>
        <CardContainer shadow>
          <h3>Essa url não é válida</h3>
        </CardContainer>
      </div>
    );
  };

  const mostrarMensagemSucesso = () => {
    return (
      <div data-password-reset>
        <CardContainer shadow>
          <h3>A senha foi trocada com sucesso!</h3>
        </CardContainer>
      </div>
    );
  };

  const renderizarFormulario = () => {
    return (
      <div data-password-reset>
        <CardContainer shadow>
          <h3>Cadastrar nova senha</h3>
          <form onSubmit={formik.handleSubmit}>
            <div data-row>
              <div data-col-2>
                <input
                  type="hidden"
                  name="hash"
                  value={props.match.params.hash}
                />

                <ADEInputPassword
                  formik={formik}
                  label={"NOVA SENHA"}
                  data-testid="senha"
                  field={"senha"}
                />
              </div>

              <div data-col-2>
                <ADEInputPassword
                  data-testid="confirmacao-senha"
                  formik={formik}
                  label={"CONFIRMAR NOVA SENHA"}
                  field={"confirmacaoSenha"}
                />
              </div>
            </div>

            <div data-row>
              <div data-col-1>
                <Button data-testid="btn-ok" type="submit" variant="primary">
                  OK
                </Button>
              </div>
            </div>
          </form>
        </CardContainer>
      </div>
    );
  };

  return (() => {
    return (
      <>
        {expired && mostrarMensagemLinkExpirado()}
        {failed && mostrarMensagemUrlInvalida()}
        {success && mostrarMensagemSucesso()}
        {!expired && !failed && !success && renderizarFormulario()}
      </>
    );
  })();
};

export default EsqueciMinhaSenhaPage;

export function validarSenha(senha: string): PasswordRules[] {
  if (!senha) return [];

  let requiredLowerCase = {
    rule: /([a-z])/,
    error: "um caractere minúsculo",
  };
  let requiredUppercase = {
    rule: /([A-Z])/,
    error: "um caractere maiúsculo",
  };
  let requiredDigit = {
    rule: /[0-9]/,
    error: "pelo menos um digito",
  };
  let requiredLength = {
    rule: /.{8}/,
    error: "8 caracteres",
  };
  let requiredSpecialChars = {
    rule: /(!|@|#|\$|%|ˆ|&|\*|\+|=|\)|\(|\\}|\\{|\[|\\]|\?|<|>)/,
    error: "um caractere especial ex: @#$%^&+?}{()",
  };

  const rules: PasswordRules[] = [
    requiredLowerCase,
    requiredUppercase,
    requiredDigit,
    requiredLength,
    requiredSpecialChars,
  ];

  let errorMessages: PasswordRules[] = [];

  rules.forEach((item) => {
    if (!item.rule.test(senha)) {
      errorMessages.push(item.error);
    }
  });
  return errorMessages;
}
