import React, { useEffect, useState } from "react";

import VideoIconWhite from "../../../assets/svgs/oetypes/video-icon-white.svg";
import VideoIconGreen from "../../../assets/svgs/oetypes/video-icon-green.svg";
import VideoIconBlue from "../../../assets/svgs/oetypes/video-icon-blue.svg";
import VideoIconGreenBlue from "../../../assets/svgs/oetypes/video-icon-green-blue.svg";

import WebcastIconWhite from "../../../assets/svgs/oetypes/webcast-icon-white.svg";
import WebcastIconGreen from "../../../assets/svgs/oetypes/webcast-icon-green.svg";
import WebcastIconBlue from "../../../assets/svgs/oetypes/webcast-icon-blue.svg";
import WebcastIconGreenBlue from "../../../assets/svgs/oetypes/webcast-icon-green-blue.svg";

import TextoIconWhite from "../../../assets/svgs/oetypes/texto-icon-white.svg";
import TextoIconGreen from "../../../assets/svgs/oetypes/texto-icon-green.svg";
import TextoIconBlue from "../../../assets/svgs/oetypes/texto-icon-blue.svg";
import TextoIconGreenBlue from "../../../assets/svgs/oetypes/texto-icon-green-blue.svg";

import ImagemIconWhite from "../../../assets/svgs/oetypes/imagem-icon-white.svg";
import ImagemIconGreen from "../../../assets/svgs/oetypes/imagem-icon-green.svg";
import ImagemIconBlue from "../../../assets/svgs/oetypes/imagem-icon-blue.svg";
import ImagemIconGreenBlue from "../../../assets/svgs/oetypes/imagem-icon-green-blue.svg";

import QuizIconWhite from "../../../assets/svgs/oetypes/quiz-icon-white.svg";
import QuizIconGreen from "../../../assets/svgs/oetypes/quiz-icon-green.svg";
import QuizIconBlue from "../../../assets/svgs/oetypes/quiz-icon-blue.svg";
import QuizIconGreenBlue from "../../../assets/svgs/oetypes/quiz-icon-green-blue.svg";

import AudioIconWhite from "../../../assets/svgs/oetypes/audio-icon-white.svg";
import AudioIconGreen from "../../../assets/svgs/oetypes/audio-icon-green.svg";
import AudioIconBlue from "../../../assets/svgs/oetypes/audio-icon-blue.svg";
import AudioIconGreenBlue from "../../../assets/svgs/oetypes/audio-icon-green-blue.svg";

import PDFIconWhite from "../../../assets/svgs/icon-pdf.svg";
import PDFIconBlue from "../../../assets/svgs/icon-pdf-blue.svg";

import LiveIconWhite from "../../../assets/svgs/oetypes/live-icon-white.svg";

import { ColorStyleType } from "../OeMedia/FooterOeMedia";

import "./styles.scss";

interface ItemProps {
  tipoObjeto: string;
}

interface SmartIconProps {
  item?: ItemProps;
  color: ColorStyleType;
}

export const SmartIcon: React.FC<SmartIconProps> = (props) => {
  const [arquivo, setArquivo] = useState("");
  const [estilo] = useState({
    blue: {
      iconVideo: VideoIconBlue,
      iconWebcast: WebcastIconBlue,
      iconImagem: ImagemIconBlue,
      iconTexto: TextoIconBlue,
      iconQuiz: QuizIconBlue,
      iconAudio: AudioIconBlue,
      iconLive: LiveIconWhite,
      iconPDF: "",
    },
    greenBlue: {
      iconVideo: VideoIconGreenBlue,
      iconWebcast: WebcastIconGreenBlue,
      iconImagem: ImagemIconGreenBlue,
      iconTexto: TextoIconGreenBlue,
      iconQuiz: QuizIconGreenBlue,
      iconAudio: AudioIconGreenBlue,
      iconLive: LiveIconWhite,
      iconPDF: PDFIconBlue,
    },
    white: {
      iconVideo: VideoIconWhite,
      iconWebcast: WebcastIconWhite,
      iconImagem: ImagemIconWhite,
      iconTexto: TextoIconWhite,
      iconQuiz: QuizIconWhite,
      iconAudio: AudioIconWhite,
      iconLive: LiveIconWhite,
      iconPDF: PDFIconWhite,
    },
    green: {
      iconVideo: VideoIconGreen,
      iconWebcast: WebcastIconGreen,
      iconImagem: ImagemIconGreen,
      iconTexto: TextoIconGreen,
      iconQuiz: QuizIconGreen,
      iconAudio: AudioIconGreen,
      iconLive: "",
      iconPDF: "",
    },
    yellow: {
      iconVideo: "",
      iconWebcast: "",
      iconImagem: "",
      iconTexto: "",
      iconQuiz: "",
      iconAudio: "",
      iconLive: "",
      iconPDF: "",
    },
    gray: {
      iconVideo: "",
      iconWebcast: "",
      iconImagem: "",
      iconTexto: "",
      iconQuiz: "",
      iconAudio: "",
      iconLive: "",
      iconPDF: "",
    },
  });

  useEffect(() => {
    const definirIcone = (oeType: string) => {
      const cor = props.color || "blue";
      const styleBundle = estilo[cor];

      switch (oeType) {
        case "Áudio":
          return styleBundle.iconAudio;
        case "Imagem":
          return styleBundle.iconImagem;
        case "Questionário":
          return styleBundle.iconQuiz;
        case "Quiz":
          return styleBundle.iconQuiz;
        case "Quizz":
          return styleBundle.iconQuiz;
        case "Simulação":
          return styleBundle.iconQuiz;
        case "Texto":
        case "Leitura":
          return styleBundle.iconTexto;
        case "Vídeo":
          return styleBundle.iconVideo;
        case "Webcast":
          return styleBundle.iconWebcast;
        case "Live":
          return styleBundle.iconLive;
        case "PDF":
          return styleBundle.iconPDF;
        default:
          return ""; // styleBundle.iconImagem;
      }
    };

    if (!props.item) {
      return;
    }

    let tipo = "";
    if (props.item) {
      tipo = (props.item.tipoObjeto && props?.item.tipoObjeto) || "";
    }

    const _imgFile = definirIcone(tipo);
    setArquivo(_imgFile);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.item, props.color]);

  return arquivo ? <img data-smart-icon src={arquivo} alt="" /> : null;
};
