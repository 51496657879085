import { useEffect } from "react";
import BannerRodape from "./banner-rodape";
import BannerTopo from "./banner-topo";
import Contadores from "./contadores";
import ConteudosInteresse from "./conteudos-interesse";
import ContinueAssistindo from "./continue-assistindo";
import DestaqueSemana from "./destaque-semana";
import IndicacoesConteudos from "./indicacoes-conteudos";
import SuasAreasInteresse from "./suas-areas-interesse";

export default function Autenticado() {

  return (
    <div className="min-h-screen">
      <DestaqueSemana />
      <BannerRodape />
      <ContinueAssistindo />
      <ConteudosInteresse />
      <BannerTopo />
      <SuasAreasInteresse />
      <IndicacoesConteudos />
      <Contadores />
    </div>
  );
}
