import React, { useRef, useState, forwardRef } from "react";
import {
  ObjetoEducacionalEntity,
  UsersPermissionsUserEntity,
} from "../../../../generated/graphql";
import { Curtir, Favoritar } from "../../../../shared/components";
import { Lembrar } from "../../../../shared/components/Lembrar";
import { AcessoDeclaracao } from "../AcessoDeclaracao";
import { Compartilhar } from "../Compartilhar";
import { isFuture } from "date-fns";
import "./styles.scss";
import Container from "../../../../shared/components/Container";
import { useUtils } from "../../../../shared/utils";

interface InteractionBarProps {
  oe: ObjetoEducacionalEntity;
  user: UsersPermissionsUserEntity | undefined;
  live?: boolean;
}

export const FavoritarSection: React.FC<{
  props: InteractionBarProps;
  refFavoritar: React.RefObject<any>;
}> = ({ props, refFavoritar }) => {
  const { formatTitle } = useUtils();

  return (
    <div className="flex h-full min-h-full cursor-pointer flex-col items-center gap-1 md:flex-row md:gap-0 ">
      <Favoritar
        color="blue"
        ref={refFavoritar}
        idOe={props.oe.id}
        item={{
          id: props.oe.id!,
          titulo: formatTitle(props.oe.attributes?.Titulo!),
        }}
      />
      <span
        className="text-[11px] font-normal leading-normal text-[#111316]"
        onClick={() => {
          refFavoritar.current && (refFavoritar.current as any).click();
        }}
      >
        Favoritar
      </span>
    </div>
  );
};

export const CompartilharSection: React.FC<{
  props: InteractionBarProps;
  refCompartilhar: React.RefObject<any>;
}> = ({ props, refCompartilhar }) => {
  const { formatTitle } = useUtils();
  return (
    <div className="flex h-full cursor-pointer flex-col items-center gap-1 md:flex-row md:gap-0 ">
      <Compartilhar
        {...props}
        item={{
          url: props.oe.attributes?.ImagemPreview.data?.attributes?.url!,
          descricao: props.oe.attributes?.Descricao!,
          titulo: formatTitle(props.oe.attributes?.Titulo!),
          id: props.oe.id,
        }}
        ref={refCompartilhar}
      />
      <span
        className="inline-block text-[11px] font-normal leading-normal text-[#111316]"
        onClick={() => {
          refCompartilhar.current && (refCompartilhar.current as any).click();
        }}
      >
        Enviar
      </span>
    </div>
  );
};

const LembrarSection: React.FC<{
  props: InteractionBarProps;
  refLembrar: React.RefObject<any>;
}> = ({ props, refLembrar }) => {
  const { formatTitle } = useUtils();
  return (
    <div className="flex cursor-pointer   flex-col items-center md:flex-row ">
      <Lembrar
        color="blue"
        ref={refLembrar}
        idOe={props.oe.id}
        item={{
          id: props.oe.id!,
          titulo: formatTitle(props.oe.attributes?.Titulo!),
        }}
      />
      <span
        className="text-[11px] font-normal leading-normal text-[#111316] "
        onClick={() => {
          refLembrar.current && (refLembrar.current as any).click();
        }}
      >
        Lembrar
      </span>
    </div>
  );
};

const CertificadoSection: React.FC<{ props: InteractionBarProps }> = ({
  props,
}) => {
  return (
    <div className="flex flex-col justify-center ">
      <AcessoDeclaracao {...props} />
    </div>
  );
};

export const AvaliarSection: React.FC<{ props: InteractionBarProps }> = ({
  props,
}) => {
  return (
    <div className="flex h-full cursor-pointer flex-col items-center gap-1 md:flex-row md:gap-0 ">
      <Curtir {...props} />
      <span className="inline-block text-[11px] font-normal leading-none text-[#111316]">
        Curtir
      </span>
    </div>
  );
};

export const BarraInteracaoOe = forwardRef<HTMLDivElement, InteractionBarProps>(
  (props, ref) => {
    // eslint-disable-next-line
    const [estaAovivo, setEstaAovivo] = useState(
      !isFuture(new Date(props.oe.attributes?.DataHoraLive)),
    );

    const refCompartilhar = useRef<any>();
    const refFavoritar = useRef<any>();
    const refLembrar = useRef<any>();

    return (
      <div className="mx-auto flex w-full items-center justify-center py-2 md:max-w-[560px]">
        <Container>
          <section className="flex w-full items-center justify-center gap-5 md:h-12">
            <FavoritarSection props={props} refFavoritar={refFavoritar} />
            <CompartilharSection
              props={props}
              refCompartilhar={refCompartilhar}
            />
            {!estaAovivo && props.live && (
              <LembrarSection props={props} refLembrar={refLembrar} />
            )}
            {!props.live && <AvaliarSection props={props} />}
            {!props.live && props.oe.attributes?.PossuiCertificado && (
              <CertificadoSection props={props} />
            )}
          </section>
        </Container>
      </div>
    );
  },
);

export default BarraInteracaoOe;
