import { useContext } from "react";
import { upsertUserContext, UserContext } from "../context/UserContext";
import {
  useGetUserByIdLazyQuery as GetUserByIdLazyQuery,
  UsersPermissionsUserEntity,
} from "../generated/graphql";

const ResetUserContext: any = () => {
  const { user, setUser, jwt, setJwt } = useContext(UserContext);

  const [reset] = GetUserByIdLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) =>
      _onCompleted(
        data.usersPermissionsUser?.data as UsersPermissionsUserEntity,
      ),
    onError: (error) => {
      console.error(error);
    },
  });

  const _onCompleted = (user: UsersPermissionsUserEntity) => {
    if (!jwt || !user) {
      return;
    }

    setUser(user);
    setJwt(jwt);

    upsertUserContext({ jwt, user });
  };

  const resetUserContext = (id?: string) => {
    if (!user && !id) return;

    reset({
      variables: {
        id: user?.id || id || "",
      },
    }).then((response) =>
      _onCompleted(
        response.data?.usersPermissionsUser?.data as UsersPermissionsUserEntity,
      ),
    );
  };

  return { resetUserContext } as any;
};

export default ResetUserContext;
