import React, { ChangeEvent, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Resizer from "react-image-file-resizer";

import { from } from "rxjs";
import { take } from "rxjs/operators";

import { TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

import InputMask from "react-input-mask";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { useFormik } from "formik";

import "./styles.scss";

import Masks from "../../../../../../shared/components/Masks";

import { dadosUsuarioValidator } from "./validator/dados-usuario.validator";

import {
  getUserContext,
  getUserWithJwtContext,
} from "../../../../../../context/UserContext";
import {
  UserFragment,
  useUpdateUserMutation,
} from "../../../../../../generated/graphql";

import ResetUserContext from "../../../../../../hooks/ResetUserContext";
import { twMerge } from "tailwind-merge";
import { Button } from "../../../../../../shared/components/ui/button";
import { CircleCheckBig } from "lucide-react";
import { ToastContainer, toast } from "react-toastify";

type PersonalDataType = Pick<
  UserFragment,
  | "nome_completo"
  | "email"
  | "data_nascimento"
  | "cpf"
  | "celular"
  | "isInternationPhoneNumber"
  | "nome_exibicao"
  | "possui_deficiencia"
  | "deficiencia_auditiva"
  | "deficiencia_visual"
  | "deficiencia_fisica"
  | "deficiencia_intelectual_mental"
  | "deficiencia_psicossocial_autismo"
  | "documentNumber"
>;

enum PossuiDeficienciaInterface {
  NAO_SOU_PESSOA_COM_DEFICIENCIA = "nao_sou_pessoa_com_deficiencia",
  SOU_PESSOA_COM_DEFICIENCIA = "sou_pessoa_com_deficiencia",
  PREFIRO_NAO_INFORMAR = "prefiro_nao_informar",
}

export const MyTextField = withStyles((theme) => ({
  root: {
    "& .MuiInputLabel-root": {
      fontSize: "16px",
      fontWeight: "700",
      color: "#626466",
    },
    "& .MuiInput-underline:hover:before": {
      borderColor: "#0A589B",
    },
    "& .MuiInput-underline:before": {
      borderColor: "rgba(112, 112, 112, .3)",
    },
    "& .MuiInput-underline:after": {
      borderColor: "#0A589B",
    },
    "& .MuiInputBase-input": {
      color: "#69737C",
      fontFamily: "Open Sans",
      fontSize: "14px",
    },
  },
}))(TextField);

const PersonalData: React.FC = () => {
  const user = getUserContext();
  const jwt = getUserWithJwtContext()?.jwt;
  const { resetUserContext } = ResetUserContext();

  const [updateUser] = useUpdateUserMutation();
  const [avatar, setAvatar] = useState("");
  const [avatarPreview, setAvatarPreview] = useState("");
  const [backgroundImage, setBackgroundImage] = useState("");
  const [imagemInvalida, setImagemInvalida] = useState(false);
  const [formData, setformData] = useState<any>();
  const [showAllErrorsMessages, setShowAllErrorsMessages] = useState(false);
  const [updatePhoneNumber, setUpdatePhoneNumber] = useState(false);

  const formik = useFormik<PersonalDataType>({
    initialValues: {
      nome_completo: user?.attributes?.nome_completo,
      celular: user?.attributes?.celular,
      cpf: user?.attributes?.cpf,
      email: user?.attributes?.email || "",
      data_nascimento: user?.attributes?.data_nascimento,
      isInternationPhoneNumber: user?.attributes?.isInternationPhoneNumber,
      possui_deficiencia: user?.attributes?.possui_deficiencia,
      deficiencia_auditiva: user?.attributes?.deficiencia_auditiva || false,
      deficiencia_visual: user?.attributes?.deficiencia_visual || false,
      deficiencia_fisica: user?.attributes?.deficiencia_fisica || false,
      deficiencia_intelectual_mental:
        user?.attributes?.deficiencia_intelectual_mental || false,
      deficiencia_psicossocial_autismo:
        user?.attributes?.deficiencia_psicossocial_autismo || false,
      nome_exibicao: user?.attributes?.nome_exibicao,
      documentNumber: user?.attributes?.documentNumber,
    },
    validationSchema: dadosUsuarioValidator,
    onSubmit: (values) => {
      values.data_nascimento = values.data_nascimento
        .split("/")
        .reverse()
        .join("-");

      from(
        updateUser({
          variables: {
            id: user?.id!,
            input: {
              ...values,
            },
          },
        }),
      )
        .pipe(take(1))
        .subscribe(
          () => {
            sendFormData(formData);
            resetUserContext();
            showUpdateSucceedModal();
          },
          (error) => {
            console.error(error);
          },
        );
    },
  });

  useEffect(() => {
    setBackgroundImage(`url(${avatarPreview ? avatarPreview : avatar})`);
  }, [avatarPreview, avatar]);

  const showUpdateSucceedModal = () => {
    toast.success(
      <div className="flex flex-row gap-3 mx-2 items-center">
        <CircleCheckBig className="h-5 w-5" />
        <div className="flex flex-col">
          <div style={{ fontWeight: 600, fontSize: '12px', color: '#FFFFFF' }}>Atualização feita com sucesso!</div>
        </div>
      </div>, {
      icon: false,
      closeButton: false,
      style: {
        backgroundColor: '#21AB27',
        color: 'white',
        borderRadius: '35px',
      }
    });
  };

  const dispatchUploadClick = () => {
    (
      document.querySelector('[name="files"]') as HTMLInputElement
    ).dispatchEvent(new MouseEvent("click"));
  };

  const formatoEhValido = (tipoImagem: string) =>
    ["jpeg", "png", "jpg"].find((extensao) => tipoImagem.includes(extensao));

  const handleFormData = (imagem: File) => {
    const reader = new FileReader();
    const formDataAtual = new FormData();

    const reader2 = new FileReader();

    if (!formatoEhValido(imagem.type)) {
      return;
    }

    reader.readAsArrayBuffer(imagem as Blob);
    reader.onloadend = function (
      this: FileReader,
      ev: ProgressEvent<FileReader>,
    ) {
      if (!user || !user.id) {
        return;
      }

      formDataAtual.append(
        "files",
        new Blob([this.result as ArrayBuffer], { type: imagem.type }),
        imagem.name,
      );

      formDataAtual.append("ref", "plugin::users-permissions.user");
      formDataAtual.append("field", "Avatar");
      formDataAtual.append("refId", user?.id);
      formDataAtual.append("folder", "3");
      formDataAtual.append("path", "USUARIO");
      formDataAtual.append("folderPath", "USUARIO");
      formDataAtual.append("source", "users-permissions");

      setformData(formDataAtual);

      //sendFormData(formData);

      reader2.readAsDataURL(imagem);
      reader2.onloadend = function (
        this: FileReader,
        ev: ProgressEvent<FileReader>,
      ) {
        setAvatarPreview(this.result as string);
        //setTimeout(() => resetUserContext(), 2000);
      };
    };
  };

  useEffect(() => {
    resetUserContext();
  }, [formData]);

  const sendFormData = (form: any) => {
    fetch(`${process.env.REACT_APP_ROOT}/api/upload`, {
      method: "POST",
      headers: {
        authorization: `Bearer ${jwt}`,
      },
      body: form,
    }).then(() => {
      resetUserContext();
    });
  };

  const handleFileSelection = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    const tamanhoPermitido = 500000;
    const tamanho = {
      maximo: 300,
      minimo: 200,
    };
    const dpi = 100;
    const rotacao = 0;

    if (!files || !files.length) {
      return;
    }

    const imagem = files[0];

    setImagemInvalida(!formatoEhValido(imagem.type));

    if (!formatoEhValido(imagem.type)) {
      return;
    }

    if (imagem.size > tamanhoPermitido) {
      Resizer.imageFileResizer(
        imagem,
        tamanho.maximo,
        tamanho.maximo,
        imagem.type,
        dpi,
        rotacao,
        (uri) => handleFormData(uri as File),
        "file",
        tamanho.minimo,
        tamanho.minimo,
      );

      return;
    }

    handleFormData(imagem);
  };

  useEffect(() => {
    formik.values.data_nascimento = !!formik.values.data_nascimento
      ? formik.values.data_nascimento.split("-").reverse().join("/")
      : "";

    if (!!user && !!user.attributes?.Avatar) {
      setAvatar(user.attributes?.Avatar.data?.attributes?.url!);
    }

    if (!!user && user.attributes?.celular && !updatePhoneNumber) {
      const cellphoneOnlyNumbers = user.attributes?.celular.replace(/\D/g, "");
      const countNumbersPhone = cellphoneOnlyNumbers.length;
    }
  }, [user]);

  return (
    <section data-personal-data>
      <ToastContainer />
      <div
        data-avatar
        style={{
          backgroundImage: backgroundImage,
        }}
      >
        <input
          name="files"
          type="file"
          style={{ display: "none" }}
          data-testid="files"
          onChange={handleFileSelection}
        />
        <IconButton onClick={dispatchUploadClick}>
          <EditIcon fontSize="small" color="inherit" />
        </IconButton>
      </div>

      {imagemInvalida && <div>Imagem inválida</div>}

      {!!user && (
        <form
          autoComplete="off"
          name="personal-data-form"
          onSubmit={formik.handleSubmit}
        >
          <fieldset>
            <MyTextField
              id="nome_completo"
              name="nome_completo"
              label="NOME COMPLETO"
              data-testid="nome_completo"
              value={formik.values.nome_completo}
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              error={Boolean(
                formik.touched &&
                  (formik.values.nome_completo || showAllErrorsMessages) &&
                  formik.errors.nome_completo,
              )}
              helperText={
                (formik.values.nome_completo || showAllErrorsMessages) &&
                formik.errors.nome_completo
              }
            />

            <MyTextField
              disabled
              name="email"
              data-testid="email"
              label="ENDEREÇO DE EMAIL"
              value={formik.values.email}
              onChange={formik.handleChange}
            />

            <MyTextField
              id="nome_exibicao"
              name="nome_exibicao"
              data-testid="nome_exibicao"
              label="COMO PREFERE SER CHAMADA/O?"
              value={formik.values.nome_exibicao}
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              error={Boolean(
                formik.touched &&
                  (formik.values.nome_exibicao || showAllErrorsMessages) &&
                  formik.errors.nome_exibicao,
              )}
              helperText={
                (formik.values.nome_exibicao || showAllErrorsMessages) &&
                formik.errors.nome_exibicao
              }
            />

            <MyTextField
              id="data_nascimento"
              name="data_nascimento"
              data-testid="data_nascimento"
              label="DATA DE NASCIMENTO"
              InputProps={{
                inputComponent: Masks.DataNascimentoMaskInput as any,
              }}
              value={formik.values.data_nascimento}
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              error={Boolean(
                formik.touched &&
                  (formik.values.data_nascimento || showAllErrorsMessages) &&
                  formik.errors.data_nascimento,
              )}
              helperText={
                (formik.values.data_nascimento || showAllErrorsMessages) &&
                formik.errors.data_nascimento
              }
            />
            {formik.values.cpf ? (
              <MyTextField
                disabled
                name="cpf"
                label="CPF"
                data-testid="cpf"
                InputProps={{
                  inputComponent: Masks.CpfMaskInput as any,
                }}
                value={formik.values.cpf}
                onChange={formik.handleChange}
              />
            ) : (
              <MyTextField
                disabled
                name="documento"
                data-testid="documento"
                className="uppercase"
                label={user?.attributes?.documentType}
                value={formik.values.documentNumber}
                onChange={formik.handleChange}
              />
            )}
            <div className="wrapper-phone-number">
              {formik.values.isInternationPhoneNumber ? (
                <>
                  <label
                    style={{
                      fontWeight: "bold",
                      fontSize: "0.7rem",
                      color: "#5c6268",
                      fontFamily: "Montserrat, sans-serif",
                    }}
                  >
                    TELEFONE INTERNACIONAL
                  </label>
                  <PhoneInput
                    defaultCountry="us"
                    name="celular"
                    data-testid="celular"
                    onChange={(phone) => {
                      formik.setFieldValue("celular", phone);
                    }}
                    className=""
                    value={formik.values.celular}
                  />
                  <label className="mt-1 text-xs font-normal text-red-500 md:text-sm">
                    {formik.touched &&
                      (formik.values.celular || showAllErrorsMessages) &&
                      formik.errors.celular}
                  </label>
                </>
              ) : (
                <>
                  <InputMask
                    mask={
                      formik.values.isInternationPhoneNumber
                        ? "+** 999 999 9999"
                        : "(99) 9 9999-9999"
                    }
                    onChange={formik.handleChange}
                    value={formik.values.celular}
                  >
                    <MyTextField
                      id="celular"
                      name="celular"
                      type="text"
                      data-testid="celular"
                      className="phone-number"
                      label={
                        formik.values.isInternationPhoneNumber
                          ? "TELEFONE INTERNACIONAL"
                          : "CELULAR"
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={Boolean(
                        formik.touched &&
                          (formik.values.celular || showAllErrorsMessages) &&
                          formik.errors.celular,
                      )}
                      helperText={
                        (formik.values.celular || showAllErrorsMessages) &&
                        formik.errors.celular
                      }
                    ></MyTextField>
                  </InputMask>
                </>
              )}

              <a
                className="field-link"
                data-testid="btn-tenho-nao-tenho"
                onClick={() => {
                  setUpdatePhoneNumber(true);

                  formik.setValues({
                    ...formik.values,
                    isInternationPhoneNumber:
                      !formik.values.isInternationPhoneNumber,
                    celular: "",
                  });
                }}
              >
                {!formik.values.isInternationPhoneNumber && (
                  <span>Não tenho telefone do país (Brasil)</span>
                )}
                {formik.values.isInternationPhoneNumber && (
                  <span>Tenho telefone do país (Brasil)</span>
                )}
              </a>
            </div>

            <div className="mt-4 w-full px-2">
              <span className="text-xs font-bold leading-4">
                PESSOA COM DEFICIÊNCIA?
              </span>

              <div className="mt-3 flex items-center">
                <input
                  type="radio"
                  data-testid="files"
                  name="possui_deficiencia"
                  id="deficiencia_nao_sou_pessoa_com_deficiencia"
                  checked={
                    formik.values.possui_deficiencia ===
                    PossuiDeficienciaInterface.NAO_SOU_PESSOA_COM_DEFICIENCIA
                  }
                  onChange={(e) => {
                    formik.setValues({
                      ...formik.values,
                      deficiencia_auditiva: false,
                      deficiencia_visual: false,
                      deficiencia_fisica: false,
                      deficiencia_intelectual_mental: false,
                      deficiencia_psicossocial_autismo: false,
                    });

                    formik.setFieldValue(
                      "possui_deficiencia",
                      PossuiDeficienciaInterface.NAO_SOU_PESSOA_COM_DEFICIENCIA,
                    );
                  }}
                  className="h-4 w-4 cursor-pointer"
                />
                <label
                  htmlFor="deficiencia_nao_sou_pessoa_com_deficiencia"
                  className={twMerge(
                    "ml-2 text-xs font-medium leading-4",
                    formik.values.possui_deficiencia ===
                      PossuiDeficienciaInterface.NAO_SOU_PESSOA_COM_DEFICIENCIA
                      ? "font-semibold text-[#004F92]"
                      : "font-semibold text-[#626466]",
                  )}
                >
                  Não sou uma pessoa com deficiência
                </label>
              </div>

              <div className="mt-3 flex items-center">
                <input
                  type="radio"
                  name="SOU_PESSOA_COM_DEFICIENCIA"
                  id="SOU_PESSOA_COM_DEFICIENCIA"
                  checked={
                    formik.values.possui_deficiencia ===
                    PossuiDeficienciaInterface.SOU_PESSOA_COM_DEFICIENCIA
                  }
                  onChange={(e) => {
                    formik.setFieldValue(
                      "possui_deficiencia",
                      PossuiDeficienciaInterface.SOU_PESSOA_COM_DEFICIENCIA,
                    );
                  }}
                  className="h-4 w-4 cursor-pointer"
                />
                <label
                  htmlFor="SOU_PESSOA_COM_DEFICIENCIA"
                  className={twMerge(
                    "ml-2 text-xs font-medium leading-4",
                    formik.values.possui_deficiencia ===
                      PossuiDeficienciaInterface.SOU_PESSOA_COM_DEFICIENCIA
                      ? "font-semibold text-[#004F92]"
                      : "font-semibold text-[#626466]",
                  )}
                >
                  Sim, sou uma pessoa com deficiência
                </label>
              </div>

              <div className="mt-3 flex items-center">
                <input
                  type="radio"
                  name="PREFIRO_NAO_INFORMAR"
                  id="PREFIRO_NAO_INFORMAR"
                  checked={
                    formik.values.possui_deficiencia ===
                    PossuiDeficienciaInterface.PREFIRO_NAO_INFORMAR
                  }
                  onChange={(e) => {
                    formik.setValues({
                      ...formik.values,
                      deficiencia_auditiva: false,
                      deficiencia_visual: false,
                      deficiencia_fisica: false,
                      deficiencia_intelectual_mental: false,
                      deficiencia_psicossocial_autismo: false,
                    });

                    formik.setFieldValue(
                      "possui_deficiencia",
                      PossuiDeficienciaInterface.PREFIRO_NAO_INFORMAR,
                    );
                  }}
                  className="h-4 w-4 cursor-pointer"
                />
                <label
                  htmlFor="PREFIRO_NAO_INFORMAR"
                  className={twMerge(
                    "ml-2 text-xs font-medium leading-4",
                    formik.values.possui_deficiencia ===
                      PossuiDeficienciaInterface.PREFIRO_NAO_INFORMAR
                      ? "font-semibold text-[#004F92]"
                      : "font-semibold text-[#626466]",
                  )}
                >
                  Prefiro não informar
                </label>
              </div>
            </div>
            {formik.values.possui_deficiencia ===
              PossuiDeficienciaInterface.SOU_PESSOA_COM_DEFICIENCIA && (
              <div className="mt-4 w-full">
                <span className="text-xs font-bold leading-4">QUAL?</span>

                <div className="mt-3 flex items-center">
                  <input
                    type="checkbox"
                    name="deficiencia_auditiva"
                    id="deficiencia_auditiva"
                    onChange={formik.handleChange}
                    className="h-4 w-4 cursor-pointer"
                    checked={formik.values.deficiencia_auditiva}
                  />
                  <label
                    htmlFor="deficiencia_auditiva"
                    className={twMerge(
                      "ml-2 text-xs font-medium leading-4",
                      formik.values.deficiencia_auditiva
                        ? "font-semibold text-[#004F92]"
                        : "font-semibold text-[#626466]",
                    )}
                  >
                    Pessoa com deficiência auditiva
                  </label>
                </div>

                <div className="mt-3  flex items-center">
                  <input
                    type="checkbox"
                    name="deficiencia_visual"
                    id="deficiencia_visual"
                    value="deficiencia_visual"
                    onChange={formik.handleChange}
                    className="h-4 w-4 cursor-pointer"
                    checked={formik.values.deficiencia_visual}
                  />
                  <label
                    htmlFor="deficiencia_visual"
                    className={twMerge(
                      "ml-2 text-xs font-medium leading-4",
                      formik.values.deficiencia_visual
                        ? "font-semibold text-[#004F92]"
                        : "font-semibold text-[#626466]",
                    )}
                  >
                    Pessoa com deficiência visual
                  </label>
                </div>

                <div className="mt-3  flex items-center">
                  <input
                    type="checkbox"
                    name="deficiencia_fisica"
                    id="deficiencia_fisica"
                    value="deficiencia_fisica"
                    onChange={formik.handleChange}
                    className="h-4 w-4 cursor-pointer"
                    checked={formik.values.deficiencia_fisica}
                  />
                  <label
                    htmlFor="deficiencia_fisica"
                    className={twMerge(
                      "ml-2 text-xs font-medium leading-4",
                      formik.values.deficiencia_fisica
                        ? "font-semibold text-[#004F92]"
                        : "font-semibold text-[#626466]",
                    )}
                  >
                    Pessoa com deficiência física
                  </label>
                </div>

                <div className="mt-3  flex items-center">
                  <input
                    type="checkbox"
                    name="deficiencia_intelectual_mental"
                    id="deficiencia_intelectual_mental"
                    value="deficiencia_intelectual_mental"
                    checked={formik.values.deficiencia_intelectual_mental}
                    onChange={formik.handleChange}
                    className="h-4 w-4 cursor-pointer"
                  />
                  <label
                    htmlFor="deficiencia_intelectual_mental"
                    className={twMerge(
                      "ml-2 text-xs font-medium leading-4",
                      formik.values.deficiencia_intelectual_mental
                        ? "font-semibold text-[#004F92]"
                        : "font-semibold text-[#626466]",
                    )}
                  >
                    Pessoa com deficiência intelectual/mental
                  </label>
                </div>

                <div className="mt-3  flex items-center">
                  <input
                    type="checkbox"
                    name="deficiencia_psicossocial_autismo"
                    id="deficiencia_psicossocial_autismo"
                    checked={formik.values.deficiencia_psicossocial_autismo}
                    value="deficiencia_psicossocial_autismo"
                    onChange={formik.handleChange}
                    className="h-4 w-4 cursor-pointer"
                  />
                  <label
                    htmlFor="deficiencia_psicossocial_autismo"
                    className={twMerge(
                      "ml-2 text-xs font-medium leading-4",
                      formik.values.deficiencia_psicossocial_autismo
                        ? "font-semibold text-[#004F92]"
                        : "font-semibold text-[#626466]",
                    )}
                  >
                    Pessoa com deficiência psicossocial (Autismo)
                  </label>
                </div>
              </div>
            )}
          </fieldset>
          <div data-row-center>
            <Button
              data-testid="btn-salvar"
              type="submit"
              variant="primary"
              onClick={() => setShowAllErrorsMessages(true)}
            >
              Salvar
            </Button>

            <Link
              to="/meu-perfil"
              data-testid="btn-voltar-para-inicio"
              className="button button-secondary-blue hide-for-mobile rounded-5xl !border-[#0a589b] !text-[#0a589b] !border-2 justify-center items-center p-2 px-4"
            >
              Voltar para o início
            </Link>
          </div>
        </form>
      )}
    </section>
  );
};

export default PersonalData;
