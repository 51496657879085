import {
  ObjetoEducacionalEntity,
  ResidenciaMedicaEntity,
} from "../../../generated/graphql";
import Tags from "../../../shared/components/Tags";

export default function DescritivoObjeto({
  objetoEducacionalSelecionado,
  residenciaMedicas,
}: {
  objetoEducacionalSelecionado?: ObjetoEducacionalEntity;
  residenciaMedicas?: ResidenciaMedicaEntity;
}) {
  return (
    <div className="my-8 h-auto">
      <span className="font-semibold leading-normal text-[#004F92]">
        {objetoEducacionalSelecionado?.attributes?.Titulo || "Boas-vindas"}
      </span>
      <p
        dangerouslySetInnerHTML={{
          __html:
            objetoEducacionalSelecionado?.attributes?.Descricao?.replace(
              "font-family",
              "font-family-old",
            ) ||
            residenciaMedicas?.attributes?.descricao?.replace(
              "font-family",
              "font-family-old",
            )!,
        }}
        className="!mt-5 max-w-[750px] !font-[Montserrat] !text-sm !font-normal !leading-5 text-[#111316]"
        style={{
          fontFamily: "Montserrat !important",
        }}
      ></p>
      <div className="mt-3 flex max-w-[750px] flex-wrap">
        {objetoEducacionalSelecionado?.attributes?.palavras_chave?.data?.map(
          (m, index) => (
            <Tags
              key={index}
              isSearchable={true}
              title={m.attributes?.titulo!}
            />
          ),
        )}
      </div>
    </div>
  );
}
