"use client";

import React from "react";
import { Link } from "react-router-dom";
import { cn } from "../utils/utils";
import { RotuloType, getTagCategoriaPorTipo } from "../utils/rotulo";

interface RotuloProps {
  rotuloName: string;
  id: number;
  key?: number;
  isMetodoEnsino?: boolean;
}

export default function Rotulo({ rotuloName, id, key, isMetodoEnsino = false }: RotuloProps) {
  let color = "";

  const tagCategoria = getTagCategoriaPorTipo(rotuloName);

  switch (tagCategoria) {
    case RotuloType.FAST_CHALLENGE:
      color = "bg-[#9B2FAE]";
      break;
    case RotuloType.OPINIAO_ESPECIALISTA:
      color = "bg-[#8CC152]";
      break;
    case RotuloType.PILULA_CONHECIMENTO:
      color = "bg-[#E62565]";
      break;
    case RotuloType.REUNIAO_CIENTIFICA:
      color = "bg-[#FC5830]";
      break;
    case RotuloType.DEEP_DIVE:
      color = "bg-[#FD9727]";
      break;
    case RotuloType.DESCOMPLICANDO_CIENCIA:
      color = "bg-[#673FB4]";
      break;
    case RotuloType.PATHWAYS:
      color = "bg-[#9A0B83]";
      break;
    case RotuloType.EINSTEIN_PRACTICE:
      color = "bg-[#0A589B]";
      break;
    case RotuloType.DESAFIO_CLINICO:
      color = "bg-[#E62565]";
      break;
    case RotuloType.CONEXAO_IDEIAS:
      color = "bg-[#8CC152]";
      break;
    case RotuloType.TESTE_CONHECIMENTO:
      color = "bg-[#9B2FAF]";
      break;
    default:
      break;
  }

  return isMetodoEnsino ? (
    <div className={cn(
      "box-border flex h-[28px] w-auto cursor-pointer items-center  justify-center rounded-full px-[0.824rem]  py-[0.411rem] text-center hover:brightness-110 tablet:h-[16.75px]",
      color,
    )}>
      <span className="flex-wrap text-xxs font-bold uppercase leading-none text-white-200 tablet:text-[6.1px]">
        {rotuloName}
      </span>
    </div>) : (
    <Link
      key={key}
      to={`${rotuloName !== RotuloType.PATHWAYS
          ? `/metodos-ensino/${rotuloName
            ?.toString()
            .toLowerCase()
            .trim()
            .replace(/ /g, "-")}/${id}`
          : "/submetodos-ensino/pathways/1"
        } `}
      className={cn(
        "box-border flex h-[28px] w-auto cursor-pointer items-center  justify-center rounded-full px-[0.824rem]  py-[0.411rem] text-center hover:brightness-110 tablet:h-[16.75px]",
        color,
      )}
    >
      <span className="flex-wrap text-xxs font-bold uppercase leading-none text-white-200 tablet:text-[6.1px]">
        {rotuloName}
      </span>
    </Link>
  );
}
