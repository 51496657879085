import { useRef } from "react";
import EnsinoProfile2 from "../../../assets/svgs/ensino-profile2.svg";
import EnsinoProfile1 from "../../../assets/svgs/ensino-profile1.svg";
import Container from "../../../shared/components/Container";

const Opinioes = () => {
  const array = [
    <div key={1} className="flex flex-col  w-full md:w-[407px] lg:w-[407px] xl:w-[407px] p-3 box-border items-center rounded-lg shadow-xl bg-[#F4F4F4]">
      <div className="flex h-24">
        <img src={EnsinoProfile2} alt="" className="w-24 h-24 mr-4" />
        <div className="">
          <h3 className="text-lg md:text-2xl font-bold text-[#626466] mb-2">
            Gaby Barboza
          </h3>
          <span className="text-[0.90rem] text-[#626466] italic leading-4">
            Médica do Einstein e ex-aluna de cursos de Atualização em Gestão.
          </span>
        </div>
      </div>

      <div className="flex flex-row">
        <span className="text-4xl font-bold h-0 mt-10 mr-1">“</span>
        <span className="text-[0.90rem] text-[#626466]  leading-5 mt-11 ">
          Sem deixar a parte técnica como médica, escolhi a liderança como
          carreira. Para isso, é necessário estudar e buscar mais conhecimento
          para atuar com excelência como ensina o Einstein
          <span className="text-4xl font-bold align-bottom absolute ml-2">
            ”
          </span>
        </span>
      </div>
    </div>,
    <div key={2} className="flex flex-col w-full md:w-[407px] lg:w-[407px] xl:w-[407px] mt-8 md:mt-0 p-3 box-border items-center rounded-lg shadow-xl bg-[#F4F4F4]">
      <div className="flex h-24">
        <img src={EnsinoProfile1} alt="" className="w-24 h-24 mr-4" />
        <div className="">
          <h3 className="text-2xl	font-bold text-[#626466] mb-2">
            Juliana Ribeiro
          </h3>
          <span className="text-[0.90rem] text-[#626466] italic leading-4">
            Fisioterapeuta e ex-aluna na Pós-graduação EAD em Gestão.
          </span>
        </div>
      </div>
      <div className="flex flex-row">
        <span className="text-4xl font-bold h-0 mt-10 mr-1">“</span>
        <span className="text-[0.90rem] text-[#626466]  leading-5 mt-11 ">
          Fazer a Pós EAD do Einstein facilitou demais a minha vida e o
          conhecimento agregou muito à minha carreira e já faz a diferença no
          meu currículo
          <span className="text-4xl font-bold align-bottom absolute ml-2">
            ”
          </span>
        </span>
      </div>
    </div>,
  ];

  return (
    <div className="bg-white">
      <Container>
        <div className="flex flex-col justify-center items-center my-10">
          <h2 className="text-[#004F92] text-3xl font-bold normal-case">
            {" "}
            Surpreenda-se com a opinião de quem fez
          </h2>
          <div className="flex flex-col md:flex-row md:gap-16 w-full mt-10 justify-center">
            {/* <div className="banner-swiper">
              <CustomSwiper
                // className="overflow-hidden rounded-2xl min-h-[300px] h-[300px]"
                slidesPerView={1}
                spaceBetween={30}
                previousRef={prevRef}
                nextRef={nextRef}
                navigation={true}
                pagination={{
                  el: ".swiper-pagination",
                  type: "bullets",
                  clickable: true,
                }}
              >
                {array.map((item, index) => (
                  <SwiperSlide key={index}>{item}</SwiperSlide>
                ))}
              </CustomSwiper>
            </div> */}
            {array}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Opinioes;
