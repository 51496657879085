import { Provider } from "react-redux";
import { ApolloProvider } from "@apollo/client";

import Store from "./redux/index";
import Routes from "./routes";
import Client from "./shared/services/graphql.service";
import Modal from "./shared/components/Modal";
import { useBrowserHistory } from "./hooks/useBrowserHistory";
import { BrowserRouter } from "react-router-dom";
import ProviderUserPreparaEinsteinContext from "./context/UserPreparaEinsteinContext";
import ProviderAlertContext from "./context/UseAlert";

const App = () => {
  // utilizamos o hook apenas para inicializar o browser history
  useBrowserHistory([window.location.href]);

  return (
    <>
      <ProviderAlertContext>
        <ApolloProvider client={Client}>
          <BrowserRouter forceRefresh={false}>
            <ProviderUserPreparaEinsteinContext>
              <Provider store={Store}>
                <Routes />
                <Modal />
              </Provider>
            </ProviderUserPreparaEinsteinContext>
          </BrowserRouter>
        </ApolloProvider>
      </ProviderAlertContext>
    </>
  );
};

export default App;
