const adeh = localStorage.getItem("adeh");
const browserHistory: string[] = adeh ? JSON.parse(adeh) : [];

do {
  browserHistory.shift();
} while (browserHistory.length > 10);

let lastHref = browserHistory[browserHistory.length - 1]; // document.location.href;
// ao utilizarmos nosso método back() do hook, não desejamos um novo
// item ao histórico
let pushToHistory = true;

/**
 * Método para observar as mudanças e atualizar o nosso objeto encapsulado
 * de histórico.
 */
const observer = new MutationObserver((mutations) => {
  mutations.forEach((mutation) => {
    if (
      window.location.href.indexOf("novo-usuario") === -1 &&
      !!localStorage.getItem("he_novo_usuario")
    ) {
      localStorage.removeItem("he_novo_usuario");
    }

    // debugger;

    if (!pushToHistory) {
      pushToHistory = true;
      // ao utilizar o método back(), retiramos o ultimo item do histórico
      do {
        browserHistory.shift();
      } while (browserHistory.length > 10);
      localStorage.setItem("adeh", JSON.stringify(browserHistory));
      return;
    }

    if (lastHref !== document.location.href) {
      lastHref = document.location.href;
      browserHistory.push(lastHref);
      localStorage.setItem("adeh", JSON.stringify(browserHistory));
    }
  });
});

/**
 * Como se trata de uma aplicação SPA, praticamente escutamos qualquer
 * mudança na DOM para verificar mudanças no Location da página
 * e então atualizarmos nosso objeto de histórico
 */
observer.observe(document.querySelector("body") as HTMLBodyElement, {
  childList: true,
  subtree: true,
});

export const useBrowserHistory = (initialHistory?: string[]) => {
  initialHistory &&
    localStorage.setItem(
      "adeh",
      JSON.stringify([...browserHistory, ...initialHistory])
    );
  const back = () => {
    pushToHistory = false;
    window.location.href = browserHistory[browserHistory.length - 2];
  };

  return {
    back,
    clear() {
      localStorage.removeItem("adeh");
    },
    get history() {
      return [...browserHistory];
    },
  };
};
