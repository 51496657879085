import { Card, CardContent, CardFooter, CardHeader } from "./ui/card";
import Rotulo from "./rotulos";
import { ObjetoEducacional } from "../../models/ObjetoEducacional";
import { cn } from "../utils/utils";
import {
  getDescricaoTipoObjeto,
  getIconeTipoObjeto,
  getTipoObjeto,
} from "../utils/tipo-objeto";
import CalendarBlue from "../../assets/svgs/icons/calendar.svg";
import DeclaracaoIcon from "../../assets/svgs/new/certificado.svg";
import { SmartLinkNovaHome } from "./SmartLinkNovaHome";
import { Favoritar } from "./Favoritar";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { Badge } from "./ui/badge";

export enum typeObjetoEducacional {
  VIDEO = "VÍDEO",
  QUESTIONARIO = "QUESTIONÁRIO",
  TEXTO = "TEXTO",
  IMAGEM = "IMAGEM",
  PDF = "PDF",
  AUDIO = "ÁUDIO",
}

export const getIcon = (type: string) => {
  switch (type) {
    case typeObjetoEducacional.VIDEO:
      return "/assets/svg/tipo-objetos/video.svg";
    case typeObjetoEducacional.QUESTIONARIO:
      return "/assets/svg/tipo-objetos/questionario.svg";
    case typeObjetoEducacional.TEXTO:
      return "/assets/svg/tipo-objetos/texto.svg";
    case typeObjetoEducacional.IMAGEM:
      return "/assets/svg/tipo-objetos/imagem.svg";
    case typeObjetoEducacional.PDF:
      return "/assets/svg/tipo-objetos/pdf.svg";
    case typeObjetoEducacional.AUDIO:
      return "/assets/svg/tipo-objetos/audio.svg";
    default:
      return "";
  }
};

export default function CardObjetoEducacional({
  objetoEducacional,
  classNameItems,
}: {
  objetoEducacional: ObjetoEducacional;
  classNameItems?: {
    rotulos?: string;
  };
}) {
  function formatarTempoMinutos(minutes: string) {
    const totalMinutes = Number(minutes);
    const hours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;
    const formattedMinutes = remainingMinutes.toString().padStart(2, "");
    const formattedSeconds = "00";

    if (hours > 0) {
      return `${hours}:${formattedMinutes}:${formattedSeconds}`;
    } else {
      return `${formattedMinutes}:${formattedSeconds}`;
    }
  }

  return (
    <Card className="flex h-full flex-col gap-2 shadow-none">
      <SmartLinkNovaHome
        oe={objetoEducacional}
        onShowCertificate={() => {}}
        isListCertificados={false}
      >
        <CardHeader className="relative h-[188.42px] md:h-[227.66px]">
          <img
            alt="imagem capa objeto educacional"
            src={objetoEducacional?.imagemPreviewUrl ?? ""}
            width={300}
            height={150}
            className={cn(
              "absolute left-0 top-0 h-full w-full rounded-xl object-cover object-top ",
              objetoEducacional?.isPatrocinado
                ? "border-4 border-[#626466]"
                : "",
            )}
          />
          <img
            src={getTipoObjeto(objetoEducacional?.tipoObjetoId)}
            alt="ícone tipo objeto educacional"
            width={60} //60 52.03
            height={60} //60 52.03
            className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
          />
          <div
            className={`absolute left-[24px] top-[17.78px] max-w-sm md:left-6 md:top-6 ${classNameItems?.rotulos}`}
          >
            <Rotulo
              rotuloName={objetoEducacional?.descricaoRotulo}
              id={objetoEducacional?.idRotulo}
            />
          </div>
          <div className="absolute right-5 top-5 md:right-6 md:top-6">
            <Favoritar
              idOe={objetoEducacional?.id?.toString() || "0"}
              color="yellow"
              item={{
                id: objetoEducacional?.id?.toString()!,
                titulo: objetoEducacional.titulo!,
              }}
            />
          </div>
          {objetoEducacional && (
            <Badge className="absolute bottom-[19.78px] right-6 border-0 border-none bg-black-900">
              <span className="text-xxs font-bold text-white-200 md:text-xs">
                {formatarTempoMinutos(objetoEducacional?.tempoMedioDuracao)}
              </span>
            </Badge>
          )}

          {objetoEducacional?.finalizado && (
            <span className="absolute bottom-[19.78px] left-[19.78px] text-xxs font-bold text-white-200 md:bottom-6 md:left-6 md:text-xs">
              Visualizado
            </span>
          )}
        </CardHeader>
      </SmartLinkNovaHome>
      <CardContent className="flex flex-col gap-2 p-0">
        {objetoEducacional?.isPatrocinado && (
          <span className=" text-[10px] font-bold text-[#626466]">
            CONTEÚDO PATROCINADO
          </span>
        )}
        <span className="text-sm font-semibold leading-4 text-[#111316] md:text-base tablet:text-xs tablet:leading-3">
          <SmartLinkNovaHome
            oe={objetoEducacional}
            onShowCertificate={() => {}}
            isListCertificados={false}
          >
            {objetoEducacional?.titulo}
          </SmartLinkNovaHome>
        </span>
      </CardContent>
      <CardFooter className="flex flex-col items-start gap-[3px] p-0">
        <div className="flex flex-row gap-2">
          <div className="flex flex-row items-center gap-1">
            {getIconeTipoObjeto(objetoEducacional?.tipoObjetoId, "#004F92", 20)}
            <span className="text-xxs font-semibold text-[#111316] md:text-[11px]">
              {getDescricaoTipoObjeto(objetoEducacional?.tipoObjetoId)}
            </span>
          </div>

          <div className="flex flex-row items-center gap-1">
            <img
              src={CalendarBlue}
              alt="icon"
              width={20} //24
              height={20} //24
              className="size-[17.76px] md:size-6 tablet:size-[15.64px]"
            />
            <span className="text-xxs font-semibold text-[#111316] md:text-[11px]">
              {format(
                new Date(objetoEducacional?.dataProducao),
                "dd MMM yyyy",
                {
                  locale: ptBR,
                },
              )}
            </span>
          </div>
          {objetoEducacional?.possuiCertificado && (
            <div className="flex flex-row items-center gap-[3px]">
              <img
                src={DeclaracaoIcon}
                alt="ícone certificado"
                width={20} //24
                height={20} //24
                className="size-[17.76px] md:size-6 tablet:size-[15.64px]"
              />

              <span className="text-xxs font-semibold leading-18 text-[#111316] md:text-[11px] md:leading-4 tablet:text-xxs">
                Declaração de participação
              </span>
            </div>
          )}
        </div>
      </CardFooter>
    </Card>
  );
}
