import Container from "../../../shared/components/Container";
import LinkCustom from "../../../shared/components/Link";
import {
  Carousel,
  CarouselBullets,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../../shared/components/ui/carousel";
import Autor1 from "../../../assets/images/autores/autor1.png";
import Autor2 from "../../../assets/images/autores/autor2.png";
import Autor3 from "../../../assets/images/autores/autor3.png";
import Autor4 from "../../../assets/images/autores/autor4.png";
import Autor5 from "../../../assets/images/autores/autor5.png";
import React from "react";
import { useUtils } from "../../../shared/utils";

export interface AutoresProps {
  qtdProfissionais?: number;
}

export default function Autores({ qtdProfissionais }: AutoresProps) {
  const { formatNumberWithCommas } = useUtils();

  const options = [
    {
      id: 1,
      title: "Débora Junqueira",
      image: Autor1,
    },
    {
      id: 2,
      title: "Fernanda Petreche",
      image: Autor3,
    },
    {
      id: 3,
      title: "Milena de Paulis",
      image: Autor2,
    },
    {
      id: 4,
      title: "Luciana Henriques",
      image: Autor4,
    },
    {
      id: 5,
      title: "Anuar Saleh Hatoum",
      image: Autor5,
    },
  ];

  return (
    <div className="relative flex flex-col justify-center py-6 text-center md:py-20 tablet:py-10">
      <Container className="flex items-center justify-center text-center">
        <div className="flex flex-col items-center justify-center">
          <h2 className="gradiente-einstein w-[300px] text-balance text-xxl font-semibold normal-case leading-30 md:w-full md:text-3.5xl md:leading-10 tablet:text-xxl tablet:leading-26">
            Conteúdos exclusivos feitos por <br className="hidden md:block" />
            especialistas do Einstein
          </h2>
          <span className="mt-3 text-sm font-normal leading-18 text-[#626466] md:text-lg md:leading-22 tablet:text-sm tablet:leading-22">
            Produzidos por mais de{" "}
            <span className="font-bold ">
              {formatNumberWithCommas(qtdProfissionais || 0)} profissionais e
              estudantes.
            </span>
          </span>
        </div>
      </Container>
      <Container>
        <div className="mt-3 flex w-full max-w-full justify-center  gap-6 overflow-x-hidden pb-2 md:!mt-9 tablet:mt-3">
          <Carousel className="h-[14.75rem] w-full tablet:h-[11.45rem]">
            <CarouselContent className="justify-start md:justify-center tablet:justify-start">
              {options?.map((option, index) => (
                <CarouselItem
                  key={index}
                  className="basis-[13.75rem] md:basis-[13.875rem] tablet:basis-[10.25rem]"
                >
                  <div className="h-full">
                    <div
                      key={option.id}
                      className="relative h-[210px] w-[210px] rounded-[10.06px] bg-[#F9F9F9] md:h-[210px] md:w-[210px] tablet:h-[151.12px]  tablet:w-[151.12px] "
                      style={{
                        boxShadow: "0px 1.68px 3.35px 0px #0B1F4D14",
                      }}
                    >
                      <img
                        src={option.image}
                        width={234}
                        height={239}
                        alt={option.title}
                        className="h-full w-full overflow-hidden rounded-xl rounded-b-2xl bg-center bg-no-repeat object-contain"
                      />
                      <div className="absolute -bottom-[1px]  left-[.2px] z-40 flex h-[33.54px] w-full items-center overflow-hidden rounded-b-xl bg-[#F5F8FF] px-4 py-1.5 text-start md:px-6 md:py-2 tablet:px-3 tablet:py-1">
                        <span className="text-8 font-semibold leading-4 text-black-900 md:text-xs md:leading-22 tablet:text-8 tablet:leading-3">
                          {option.title}
                        </span>
                      </div>
                    </div>
                  </div>
                </CarouselItem>
              ))}
            </CarouselContent>
            <CarouselPrevious
              data-testid="btn-previous-autores"
              height="h-[0px] md:h-[227.66px]"
              className="banner hidden md:block"
            />
            <CarouselNext
              data-testid="btn-next-autores"
              height="h-[0px] md:h-[227.66px]"
              className="banner hidden md:block"
            />
            <CarouselBullets className="absolute left-0 right-0 mx-auto " />
          </Carousel>
        </div>
        <div className="!mt-3 md:!mt-1 tablet:!mt-3">
          <LinkCustom
            to="/central-de-autorias"
            className="!text-sm font-bold  leading-22  text-brand-blue underline md:!text-sm tablet:!text-xs"
          >
            Conheça todos os autores
          </LinkCustom>
        </div>
      </Container>
    </div>
  );
}
