import React from "react";

export interface TituloQuizProps {
  quizAtivo?: any;
}

const TituloQuiz: React.FC<TituloQuizProps> = ({ quizAtivo }) => {
  return (
    <h2 className="normal-case mt-2">
      <span className="font-semibold text-base md:text-xxl leading-normal md:leading-8 text-[#004F92] ">
        {quizAtivo?.attributes?.nome}
      </span>
    </h2>
  );
};

export default TituloQuiz;
