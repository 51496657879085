import React, { useEffect, useState } from "react";
import { ComponentTextoSecaoPdf, ObjetoEducacionalEntity, UsersPermissionsUserEntity } from "../../../generated/graphql";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router";
import OePDFWatch from "./OePDFWatch";
import { OePreviewDesktop, TypeOE } from "../Preview/OePreviewDesktop";
import { OePreviewMobile } from "../Preview/OePreviewMobile";

interface OePDFProps {
  oe: ObjetoEducacionalEntity;
  user?: UsersPermissionsUserEntity;
  oesRelacionadas?: ObjetoEducacionalEntity[];
}

export const OePDFPage: React.FC<OePDFProps> = (props) => {
  const [isPreview, setPreview] = useState<boolean>(true);
  const [pdfAtual, setPdfAtual] = useState<ComponentTextoSecaoPdf | undefined>();
  const params = useParams<{ pdfId: string }>();


  useEffect(() => {
    if (!props.oe) return;

    setPdfAtual(undefined);
    setPreview(true);
    if (!!params && !!params.pdfId) {
      const _pdfAtual = props.oe.attributes?.PDF?.find(
        (item) => item.id === params.pdfId
      );

      if (!_pdfAtual) {
        window.location.href = "/";
        return;
      }

      setPreview(false);
      setPdfAtual(_pdfAtual);
    }
  }, [props.oe, params, window.location.pathname]);

  const _oeLido = props.user?.attributes?.OesConsumidosParcialmente?.data.find(
    (item) =>
      !!item?.attributes?.PDFId &&
      item?.attributes?.PDFId.toString() === pdfAtual?.id
  );


  return (
    <>
      {isPreview ? (!isMobile ? (
        <OePreviewDesktop
          oe={props.oe}
          user={props.user}
          type={TypeOE.PDF}
        />
      ) : (
        <OePreviewMobile
          oe={props.oe}
          user={props.user}
          type={TypeOE.PDF}
        />
      )) : (
        <OePDFWatch
          pdf={pdfAtual}
          idOe={props.oe.id!}
      />
      )}
    </>
  );
};
