import React, { ReactNode, useEffect } from "react";
import { useLocation } from "react-router";

import Header from "../Header";
import { FooterLinks } from "../Footer";

import { ModalFeedback } from "../../shared/components";
import ModalPreRegisterUser from "../../shared/components/ModalPreRegisterUser";

import { useConviteCadastro } from "../../hooks/useConviteCadastro";
import useOeConsume from "../../hooks/useOeConsume";
import NewHeader from "../newHeader";

interface ContainerPageProps {
  children?: ReactNode;
}

export const ContainerPage: React.FC<ContainerPageProps> = (props) => {
  const location = useLocation();
  const { saveOeConsume, resetUserContext } = useOeConsume();

  useEffect(() => {
    salvarStatusVideo();
  }, [location]);

  const salvarStatusVideo = async () => {
    let update = localStorage.getItem("UPDATE_VIDEO");
    let pathVideo = localStorage.getItem("pathVideo");
    if (update && pathVideo != window.location.pathname) {
      const data = JSON.parse(update);
      await saveOeConsume(data);
      resetUserContext(data.user);
      localStorage.removeItem("UPDATE_VIDEO");
    }
    localStorage.setItem("pathVideo", window.location.pathname);
  };

  return (
    <>
      {/* <ModalPreRegisterUser /> */}
      {/* <ModalFeedback /> */}
      <NewHeader />
      <div>{props.children}</div>
      <FooterLinks />
    </>
  );
};
