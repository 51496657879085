import ResidenciaMedicaBanner from "../_HomePage/components/ResidenciaMedica";
import { useScrollTop } from "../../shared/hooks/useScrollTop";

function SobreResidenciaMedicaPage() {
  const scrollTop = useScrollTop();

  scrollTop();

  return (
    <div className="h-auto py-8">
      <ResidenciaMedicaBanner page="residencia" />
    </div>
  );
}

export default SobreResidenciaMedicaPage;
