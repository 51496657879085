import { DocumentNode, OperationVariables, QueryHookOptions, TypedDocumentNode, useQuery } from "@apollo/client";
import client from "../shared/services/graphql.service";
import { QueryOptions } from "@apollo/client/core/watchQueryOptions";

export type ConditionGraphQLType = "contains" | "gt" | "";

export interface WhereVariableGraphQL<TFields> {
  field: TFields;
  condition: ConditionGraphQLType;
  value: any;
}

export interface SortVariableGraphQL {
  field: string,
  direction: "asc" | "desc" | string;
}

export const CreateVariables = (limit?: number|undefined, start?: number|undefined, where?: WhereVariableGraphQL<any>[], sort?: SortVariableGraphQL) => {

  const variables: OperationVariables = {};

  if (limit) {
    variables.limit = limit;
  }

  if (start) {
    variables.start = start;
  }

  if (where) {
    for (const item of where) {
      const _value =
        typeof item.value === 'string' ? `${item.value}` : item.value;

      variables.where = { [`${item?.field}_${item?.condition}`]: _value };
    }
  }

  if (sort) {
    variables.sort = `${sort.field}:${sort.direction}`;
  }

  return variables;
};

export function Query<T>(
  GQL: DocumentNode | TypedDocumentNode<T, OperationVariables>,
  options?: QueryHookOptions
) {
  const { loading, error, data } = useQuery<T>(GQL, options);
  return {
    loading,
    error,
    data,
  };
}

export async function QueryClient<T>(GQL: any, variables?: OperationVariables | undefined) {
  return client.query<T>({ query: GQL, variables,  fetchPolicy: 'network-only' });
}

export async function QueryClientWithOptions<TResult>(GQL: any, variables?: OperationVariables, options?: QueryOptions) {
  try {
    const {data, loading, error, networkStatus} = await client.query<TResult>({ query: GQL, variables, ...options });
    return { data, loading, error, networkStatus};
  } catch (error) {
    console.error(error);
    return null;
  }
}

export function MutateClient<T>(GQL: any, variables?: OperationVariables | undefined) {
  return client.mutate<T>({ mutation: GQL, variables });
}
