import React from 'react';
import CustomSwiper from '../../../../shared/components/CustomSwiper';
import { SwiperSlide } from 'swiper/react';
import { twMerge } from 'tailwind-merge';
import { QuizModel } from '../../../../models/QuizModel';

export interface MediaImagemVideoFeedBackQuizProps {
  className: string;
  quizAtivo?: QuizModel;
  isMobile: boolean;
  onClickMobile: () => void;
  nextRef: any;
  previousRef: any;
}

export const MediaImagemVideoFeedBackQuiz: React.FC<MediaImagemVideoFeedBackQuizProps> = ({ className, quizAtivo, isMobile, onClickMobile, nextRef, previousRef }) => {
  return (
    <>
      {quizAtivo?.feedbackImagemUrl && quizAtivo?.feedbackImagemUrl?.length >
        0 && (
          <div
            className={className}
            onClick={() => {
              isMobile && onClickMobile();
            }}
          >
            {true && (
              <CustomSwiper
                spaceBetween={1}
                slidesPerView={1}
                navigation={true}
                pagination={true}
                nextRef={nextRef}
                previousRef={previousRef}
                className="h-auto overflow-hidden"
                autoHeight={true}
                quantidade={
                  quizAtivo?.feedbackImagemUrl
                    .length
                }
              >
                {quizAtivo?.feedbackImagemUrl?.map(
                  (img, index: number) => (
                    <SwiperSlide key={index}>
                      <img
                        src={img}
                        width="100%"
                        className={twMerge(
                          "object-fill object-center max-h-[300px] md:max-h-[500px] md:min-h-[500px] bg-no-repeat",
                          className
                        )}
                        alt=""
                      />
                    </SwiperSlide>
                  )
                )}
              </CustomSwiper>
            )}
          </div>
        )}
    </>
  );
}
