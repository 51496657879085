import React from "react";

import TimerReaderBlue from "../../../assets/svgs/timer-reader-blue.svg";
import TimerReaderWhite from "../../../assets/svgs/timer-reader.svg";
import TimerReaderGreen from "../../../assets/svgs/timer-reader-green.svg";

import { ColorStyleType } from "../OeMedia/FooterOeMedia";

import "./styles.scss";

interface IDateViewProps {
  time: any;
  color?: ColorStyleType;
  textColor?: ColorStyleType;
  animate?: boolean;
}

export const TimeView: React.FC<IDateViewProps> = ({
  time,
  animate,
  color,
  textColor,
}) => {
  const getIcon = (_color: ColorStyleType) => {
    switch (_color) {
      case "blue":
        return TimerReaderBlue;
      case "green":
        return TimerReaderGreen;
      default:
        return TimerReaderWhite;
    }
  };

  return (
    // <div data-time-view={color} data-animate={animate}>
    <div className="gap-2 flex text-[11px] font-normal ">
      <img className="w-4 h-4" src={getIcon(color!)} alt="" />
      {/* <div data-color={textColor || color}>{parseInt(time)} min</div> */}
      <span
        className={`text-[11px] font-normal !leading-4 text-center !ml-0
      ${color === "blue" ? " text-[#0A589B]" : "text-white"}`}
      >
        {parseInt(time)} min
      </span>
    </div>
  );
};
