import React, { useState } from "react";
import Card from "@material-ui/core/Card";

import Apredizado from "../../../../assets/svgs/aprendizado.svg";
import Biblioteca from "../../../../assets/svgs/biblioteca.svg";
import Recomendacoes from "../../../../assets/svgs/recomendacoes.svg";
import Declaracao from "../../../../assets/svgs/declaracao.svg";
import DiversosFormatos from "../../../../assets/svgs/diversos-formatos.svg";
import AcessoGratuito from "../../../../assets/svgs/acesso-gratuito.svg";

import { If } from "../../../../shared/components";

import "./styles.scss";

interface TemasOferecidosProps {}

interface TemasOferecidosCard {
  titulo: string;
  icone: {
    url: string;
    desktop: {
      altura: string;
    };
    mobile: {
      altura: string;
    };
  };
  descricao: string | null;
  rodape: string | null;
}

const TemasOferecidos: React.FC<TemasOferecidosProps> = (props) => {
  const [dados] = useState<TemasOferecidosCard[]>([
    {
      titulo: "Aprendizado a qualquer hora e em qualquer lugar",
      icone: {
        url: Apredizado,
        desktop: {
          altura: "77px",
        },
        mobile: {
          altura: "63px",
        },
      },
      descricao: "no seu celular e/ou computador",
      rodape: null,
    },

    {
      titulo: "CONTEÚDO CONSTANTEMENTE ATUALIZADO",
      icone: {
        url: Biblioteca,
        desktop: {
          altura: "90px",
        },
        mobile: {
          altura: "78px",
        },
      },
      descricao: "para diversas áreas de conhecimento",
      rodape: null,
    },
    {
      titulo: "CONTEÚDO PRODUZIDO POR PROFISSIONAIS EINSTEIN",
      icone: {
        url: Recomendacoes,
        desktop: {
          altura: "101px",
        },
        mobile: {
          altura: "93px",
        },
      },
      descricao:
        "possibilidade de visualizar conteúdo de seus autores favoritos",
      rodape: null,
    },
    {
      titulo: "DECLARAÇÃO",
      icone: {
        url: Declaracao,
        desktop: {
          altura: "80px",
        },
        mobile: {
          altura: "74px",
        },
      },
      descricao: "para comprovar o aprendizado",
      rodape: "* disponível somente para usuários cadastrados",
    },
    {
      titulo:
        "DIVERSAS CATEGORIAS E FORMATOS DE CONTEÚDOPOR PROFISSIONAIS EINSTEIN",
      icone: {
        url: DiversosFormatos,
        desktop: {
          altura: "94px",
        },
        mobile: {
          altura: "76px",
        },
      },
      descricao: "para consumo rápido ou aprofundamento",
      rodape: null,
    },
    {
      titulo: "ACESSO GRATUITO*",
      icone: {
        url: AcessoGratuito,
        desktop: {
          altura: "60px",
        },
        mobile: {
          altura: "55px",
        },
      },
      descricao: null,
      rodape: "*nesse primeiro momento.",
    },
  ]);

  return (
    <>
      <div data-temas-oferecidos>
        {dados.map((item: TemasOferecidosCard, i: number) => (
          <Card key={i} className="flex card">
            <div className="flex-item card-icon">
              <img
                src={item?.icone.url}
                alt=""
                className="hide-for-mobile"
                style={{ height: item.icone.desktop.altura }}
              />
              <img
                src={item?.icone.url}
                alt=""
                className="hide-for-desktop"
                style={{ height: item.icone.mobile.altura }}
              />
            </div>
            <div className="flex-item card-content">
              <h2>{item?.titulo}</h2>
              <If test={!!item?.descricao}>
                <p>{item.descricao}</p>
              </If>
              <If test={!!item?.rodape}>
                <span>{item?.rodape}</span>
              </If>
            </div>
          </Card>
        ))}
      </div>
    </>
  );
};

export default TemasOferecidos;
