import React from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

import "./styles.scss";

import Logo from "../../../../assets/images/logo.png";

import {
  ComponentMenusRodapeMenusRodape,
  GetRodapePortalQuery,
  MenusRodapeFragment,
} from "../../../../generated/graphql";
import { getIconUrl, sortMenus } from "../..";
import { RodapeModel } from "../../../../models/RodapeModel";

interface FooterMobileProps {
  data: RodapeModel | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      borderRadius: "0px",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular as any,
    },
  })
);

const FooterMobileComponent: React.FC<FooterMobileProps> = (props) => {
  const classes = useStyles();

  return (
    <div data-footer-mobile>
      <div data-atendimento>
        {props.data &&
          props.data?.itensRodape?.filter(a => a.nome === 'Dúvidas, sugestões, críticas ou elogios?').map(
            (group, idx) => {
              return (
                <div key={idx}>
                  <h4>{group.nome}</h4>
                  <div data-links data-flex>
                    {group?.itensRodape
                      .map((item, idx) => (
                        <div key={idx}>
                          <a target="blank" href={item?.path}>
                            {item.nome}
                          </a>

                          {!!item?.contato && (
                            <span>{item.contato}</span>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              );
            }
          )}
      </div>

      {/*<div data-social-media>
        {props.data &&
          props.data?.itensRodape?.filter(a => a.nome !== 'Dúvidas, sugestões, críticas ou elogios?').map(
            (group, idx) => {
              return (
                <div key={idx}>
                  <h4>{group.nome}</h4>
                  <div data-links data-flex>
                    {group?.itensRodape.map((item, idxLink) => (
                      <a key={idxLink} target="blank" href={item?.path}>
                        <img alt={item.nome} src={getIconUrl(item)} />
                      </a>
                    ))}
                  </div>
                </div>
              );
            }
          )}
          </div>*/}

      <div data-logo>
        <img src={Logo} width="203px" alt="" />
      </div>
      {props.data &&
        props.data?.itensRodape?.filter(a => a.nome !== 'Dúvidas, sugestões, críticas ou elogios?' && a.nome !== 'Junte-se a nós').map(
          (group, idx) => (
            <Accordion key={idx}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>{group?.nome}</Typography>
              </AccordionSummary>
              <AccordionDetails data-footer-group-items>
                <ul>
                  {group?.itensRodape?.map(
                    (item, idxLi) => (
                      <li key={idxLi}>
                        <a
                          data-footer-link
                          target="blank"
                          href={item?.path}
                        >
                          {item.nome}
                        </a>
                      </li>
                    )
                  )}
                </ul>
              </AccordionDetails>
            </Accordion>
          ))}
      <div data-copyrigth data-flex data-flex-direction="column">
        <p>
          ©2021 Sociedade Beneficente Israelita Brasileira Hospital Albert
          Einstein - TODOS OS DIREITOS RESERVADOS
        </p>

        <p>
          CNPJ: 60.765.823/0001-30 - Endereço: Av. Albert Einstein, 627 Morumbi
          - São Paulo - SP - 05652-000
        </p>
      </div>
    </div>
  );
};

export default FooterMobileComponent;
