import React, { useState, useEffect } from "react";

import {
  EnsinoEinsteinCursoEntity,
  useGetEnsinoEinsteinCursosQuery,
} from "../../generated/graphql";

import {
  Loader,
  HeaderSection,
  Breadcrumb,
  BreadcrumbItem,
} from "../../shared/components";

import { useScrollTop } from "../../shared/hooks/useScrollTop";

import "./styles.scss";
import Vimeo from "@u-wave/react-vimeo";
import EnsinoBackground from "../../assets/svgs/ensino-background.svg";

import HiaeLogo from "../../assets/svgs/HIAE-logo.svg";
import Container from "../../shared/components/Container";
import EnsinoEinsteinCards from "./EnsinoEinsteinCards";
import Info from "./Info";
import Opinioes from "./Opinioes";
import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../hooks/useGtm";
import { Button } from "../../shared/components/ui/button";

export const EnsinoEinsteinPage: React.FC = () => {
  const [urlSobreEnsinoEinstein] = useState<string>(
    "https://vimeo.com/824170762/d52042ef79",
  );
  const gtm = useGtm();
  const scrollTop = useScrollTop();

  const { data: dataEnsino, loading: loadingEnsino } =
    useGetEnsinoEinsteinCursosQuery();

  useEffect(() => {
    scrollTop();

    // GTM doc linha 82
    gtm.push(
      EDataLayerEventCategory.PAGE_VIEW,
      EDataLayerEventAction.NAVEGACAO,
      {
        label: "pagina_ensino_einstein",
      },
    );
  }, []);

  return (
    <>
      <HeaderSection>
        <Breadcrumb>
          <BreadcrumbItem to="/">Home</BreadcrumbItem>
          <BreadcrumbItem active>Ensino Einstein</BreadcrumbItem>
        </Breadcrumb>
        <h3 className="text-white text-base font-semibold leading-none md:text-xxl">
          Ensino Einstein
        </h3>
      </HeaderSection>

      <Loader
        show={loadingEnsino}
        message="Aguarde enquanto o conteúdo é carregado..."
      />
      <section>
        <div className="bg-[#F4F4F4] ">
          <Container className=" !py-5 md:!py-20">
            <div className="flex h-auto flex-col-reverse items-center justify-center md:flex-row md:gap-5">
              <div className="mr-2 flex w-full flex-col items-end md:w-96">
                <div className="">
                  <h2 className="leading-0 flex-nowrap text-2xl font-bold normal-case text-[#004F92] md:mt-12 md:text-3xl">
                    {" "}
                    Sobre o Ensino Einstein
                  </h2>
                  <p className="mt-6 text-base font-normal">
                    O Ensino Einstein é a unidade de educação da Sociedade
                    Beneficente Israelita Brasileira Albert Einstein, criado
                    para difundir conhecimento sobre assistência e gestão em
                    saúde.
                  </p>
                  <p className=" !mt-6 text-base font-normal">
                    {" "}
                    Com portfólio que vai desde o Ensino Médio até o MBA,
                    acompanha toda a jornada dos profissionais com métodos
                    exclusivos, corpo docente de referência e a mais completa
                    infraestrutura de ensino em saúde.
                  </p>
                  <div className="my-8 flex w-full justify-start">
                    <Button
                      data-testid="btn-conheca-nossos-cursos"
                      size="default"
                      variant="primary"
                      onClick={() => {
                        window.open("https://ensino.einstein.br/", "_blank");
                      }}
                      // className="relative mt-4 w-full rounded-[26px] bg-[#004F92] px-4 py-2 text-base font-semibold text-[#FFFFFF] hover:bg-[#3777af] md:mt-12	md:w-52 "
                    >
                      Conheça nossos cursos
                    </Button>
                  </div>
                </div>
              </div>
              <div className="z-10 my-8 h-full w-full md:w-[640px] md:p-4">
                <div className="flex items-start rounded-2xl ">
                  <Vimeo
                    pip={true}
                    video={urlSobreEnsinoEinstein}
                    responsive
                    className="vp-center h-full w-full"
                  />
                </div>
              </div>
            </div>
          </Container>
        </div>

        <Info />

        <div className="bg-white">
          <Container>
            <div className="my-10 flex flex-row items-center justify-center md:my-0">
              <div
                className="relative h-[302px] w-full items-center bg-slate-700 md:rounded-lg"
                style={{
                  backgroundImage: `url(${EnsinoBackground})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              >
                <div
                  className="absolute left-1/2 top-1/2 h-auto w-72 -translate-x-1/2
                -translate-y-1/2 transform rounded-3xl bg-[#ffffff] px-9 py-7 text-center opacity-95  backdrop-blur-md md:left-48"
                >
                  <span className="text-center text-base font-normal leading-5">
                    Faça uma
                    <span className="font-bold"> visita virtual </span>
                    por algumas unidades do
                    <span className="font-bold"> Ensino Einstein</span>
                  </span>
                  <Button
                    data-testid="btn-clique-aqui"
                    onClick={() => {
                      window.open(
                        "https://tourvirtual.ensinoeinstein.com/",
                        "_blank",
                      );
                    }}
                    variant="primary"
                    className="text-white relative mt-7 w-[151px] rounded-3xl bg-[#004F92] text-base font-semibold	hover:bg-[#3777af]"
                  >
                    Clique aqui
                  </Button>
                </div>
              </div>
            </div>
          </Container>
        </div>

        <Opinioes />

        <EnsinoEinsteinCards data={dataEnsino as EnsinoEinsteinCursoEntity} />

        <div className="bg-white">
          <Container>
            <div className="flex h-auto flex-col items-center justify-center p-14">
              <img
                src={HiaeLogo}
                className="w-[281px] cursor-pointer"
                onClick={() => {
                  window.open("https://ensino.einstein.br/", "_blank");
                }}
              />
            </div>
          </Container>
        </div>
      </section>
    </>
  );
};
