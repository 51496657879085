import React, { useState, useContext, useRef } from "react";
import html2canvas from "html2canvas";
import * as jspdf from "jspdf";
import { UserContext } from "../../context/UserContext";
import Assinatura from "../../assets/svgs/assinatura.svg";
import EinsteinCertificate from "../../assets/images/einsteincertificate.png";
import * as Icon from "../../assets/svgs";

import "./styles.scss";

import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useUtils } from "../../shared/utils";
import { Button } from "../../shared/components/ui/button";
import { cn } from "../../lib/utils";
import { fetchApi } from "../../shared/services/rest-dotnet.service";

interface ICertificatePageProps {
  tempoMedioDuracao: string;
  titulo: string;
}

const documentDate = () => {
  const date = new Date();
  const dia = date.getDate();
  const mes = date.getMonth();
  const ano = date.getFullYear();
  const bMes = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  return `${dia} de ${bMes[mes]} de ${ano}`;
};

const secretKey = "1A2B3C4D5E6F708192A1B2C3D4E5F607";

const CertificadoPageV2: React.FC<ICertificatePageProps> = ({
  tempoMedioDuracao,
  titulo,
}) => {
  const { user } = useContext(UserContext);

  const [htmlClonado, setHtmlClonado] = useState<string>("");
  const [duracao, setDuracao] = useState("");
  const certificadoRef = useRef(null);
  const [web, setWeb] = useState(true);
  const [loading, setLoading] = useState(false);
  const { formatTitle } = useUtils();

  useEffect(() => {
    if (!!user) {
      setTimeout(() => {
        const data = certificadoRef?.current as unknown as HTMLDivElement;

        if (!htmlClonado) {
          setHtmlClonado(data.innerHTML);
        }
      }, 5000);
    }
  }, [user]);

  useEffect(() => {
    const tempoMeioDuracao: string | number = parseInt(
      tempoMedioDuracao.replace(/[^\d]/g, "") || "0",
    );

    const tempoFormatado =
      tempoMeioDuracao > 60
        ? (tempoMeioDuracao / 60).toFixed(2).replaceAll(".", ",") + " horas"
        : tempoMeioDuracao + " minutos";

    setDuracao(tempoFormatado);
  }, [tempoMedioDuracao]);

  const curso = formatTitle(titulo);

  const downloadDocument2 = () => {
    setLoading(true);
    const webb = web;
    setWeb(false);
    setTimeout(() => {
      const data = certificadoRef?.current as unknown as HTMLDivElement;

      const dataBtoa = btoa(unescape(encodeURIComponent(data.innerHTML)));
      const htmlClonadoBtoa = btoa(unescape(encodeURIComponent(htmlClonado)));

      dataBtoa === htmlClonadoBtoa &&
        html2canvas(data, { scrollY: 0, scale: 2 })
          .then((canvas) => {
            const pdf = new jspdf.jsPDF({
              orientation: "landscape",
              unit: "mm",
              format: [canvas.width, canvas.height],
            });
            pdf.addImage(canvas, "jpg", 0, 0, canvas.width, canvas.height);
            pdf.save(`${curso}.pdf`);
          })
          .finally(() => {
            setWeb(webb);
          });
    }, 500);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };


  const donwloadDocument = () => {
    fetchApi(
      "declaracao/download",
      "POST",
      JSON.stringify({
        nome: user?.attributes?.nome_completo,
        curso: curso,
        duracao: duracao,
      }),
    ).then((response) => {
      response.blob().then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${curso}.pdf`;
        a.click();
      });
    });
  };

  const renderCertificateWeb = (print?: boolean) => {
    return (
      <>
        <div
          ref={certificadoRef}
          className={cn(
            `relative  h-full w-full self-center overflow-hidden bg-[#102f49] md:h-[413.23px]  `,
            print ? "h-[210mm] w-[297mm] !p-7" : "h-[504.17px] !p-5",
          )}
        >
          <div className="relative flex h-full w-full flex-col items-center justify-center rounded-[30px] bg-white-200 md:h-full">
            <Icon.CertificadoQuinaLT
              className={cn(
                "absolute left-0 top-0 size-20 ",
                print ? "size-32" : "",
              )}
            />
            <Icon.CertificadoQuinaRT
              className={cn(
                "absolute right-0 top-0 size-20 rotate-90 transform",
                print ? "size-32" : "",
              )}
            />
            <Icon.CertificadoQuinaBL
              className={cn(
                "absolute bottom-0 right-0 size-20 rotate-180 transform",
                print ? "size-32" : "",
              )}
            />
            <Icon.CertificadoQuinaBR
              className={cn(
                "absolute bottom-0 left-0 size-20 -rotate-90 transform",
                print ? "size-32" : "",
              )}
            />
            <div className="flex flex-col items-center justify-center py-3 md:px-[54px]">
              <header>
                <img
                  src={EinsteinCertificate}
                  alt="Academia Digital Einstein"
                  style={{ height: "40px" }}
                />
                <h1 className="mt-3 text-center text-base font-bold normal-case leading-none text-[#13304E] md:text-[24px]">
                  Declaração
                </h1>
              </header>
              <strong className="!mt-5 border-b-[1px] border-b-black-900 text-center text-base font-semibold leading-8 text-black-900  md:text-lg">
                {user?.attributes?.nome_completo}
              </strong>

              <span className="mt-4 text-balance px-3 text-center text-xs font-normal leading-5 text-[#111316]  ">
                Concluiu o acesso ao conteúdo{" "}
                <span className="font-bold">{curso}</span>
                {!!curso && <>, </>}
                promovido pela Academia Digital Einstein, do Instituto Israelita
                de Ensino e Pesquisa Albert Einstein,
                <br /> com carga horária de {duracao}.
              </span>

              <footer className="flex flex-col items-center justify-center">
                <div className="mt-7 text-[10px] font-normal leading-normal text-[#111316]">
                  São Paulo, {documentDate()}.
                </div>
                <div className="flex flex-col items-center justify-center gap-2 text-center">
                  <img
                    src={Assinatura}
                    // height={100}
                    style={{ height: "70px" }}
                    alt="signed"
                    className="mt-2"
                  />
                </div>
              </footer>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {!!user && (
        <div
          className={`box-border flex h-screen flex-col items-center justify-center md:h-[600px] md:w-[900px] md:px-6  ${
            isMobile ? "landscape:h-screen landscape:overflow-auto" : ""
          }`}
        >
          <div className="flex h-auto w-full items-center justify-center border bg-[#E8E8E8] px-4 py-6 md:hidden ">
            {renderCertificateWeb()}
          </div>
          <div className="hidden justify-center px-16 md:flex">
            {renderCertificateWeb()}
          </div>
          <div className="mt-3 flex w-full items-center justify-center md:!mt-5">
            <Button
              type="submit"
              data-testid="btn-baixar-declaracao"
              className="h-[39px] w-[215px] rounded-[30px] bg-[#0A589B] px-[28px] py-[12px] text-base font-semibold leading-3 text-[#fff] md:h-[58px]"
              onClick={donwloadDocument}
              disabled={loading}
            >
              {loading ? "Gerando..." : "Baixar declaração"}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default CertificadoPageV2;
