import React, { Component, HTMLAttributes } from "react";
import { Tooltip } from "@material-ui/core";

import { ObjetoEducacionalEntity } from "../../../../generated/graphql";

import {
  CardContainer,
  RotuloCategoria,
  DateView,
} from "../../../../shared/components";

import FooterOeMedia from "../../../../shared/components/OeMedia/FooterOeMedia";

import { splitOeTitle } from "../../../../helpers/oe-card.helper";
import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../../../hooks/useGtm";

import "./styles.scss";
import { useSanetizeString } from "../../../../hooks/useSanetizeString";
import { useUtils } from "../../../../shared/utils";

interface IWidgetQuizProps extends HTMLAttributes<HTMLDivElement> {
  oe: ObjetoEducacionalEntity;
  gtm?: any;
  sanetizeString?: any;
}

export const WidgetQuizComponentHOC: React.FC<IWidgetQuizProps> = (props) => {
  const gtm = useGtm();
  const { sanetizeString } = useSanetizeString();
  return <WidgetQuizComponent {...{ ...props, gtm, sanetizeString }} />;
};

class WidgetQuizComponent extends Component<IWidgetQuizProps> {
  isOeIdValid = (oeId: string): boolean => /^\d+$/.test(oeId);

  handleQuizButtonClick = () => {
    const { oe, gtm } = this.props;

    // GTM doc linha 53
    gtm.push(
      EDataLayerEventCategory.ADE_DESTAQUE_QUIZ,
      EDataLayerEventAction.CLIQUE,
      {
        label: oe?.attributes?.Titulo || "",
      }
    );

    window.location.href = `/oe/${oe?.id}/quiz`;
  };

  renderHeader = () => {
    const { oe } = this.props;
    const { formatTitle } = useUtils();

    return (
      <header className="flex flex-col gap-3">
        {oe?.attributes?.Titulo && (
          <Tooltip title={formatTitle(oe.attributes?.Titulo!)}>
            <h4 className="text-base text-[#0A589B] font-semibold leading-none line-clamp-2 ">
              {splitOeTitle(formatTitle(oe.attributes?.Titulo!))}
            </h4>
          </Tooltip>
        )}

        <div className="flex items-center">
          {oe?.attributes?.Rotulos?.data?.map((rotulo, idx) => (
            <div className="mr-3">
              <RotuloCategoria
                key={idx}
                data={rotulo?.attributes?.titulo ?? ""}
              />
            </div>
          ))}
          <DateView
            color="blue"
            date={oe.attributes?.DataAtualizacaoProducao}
          />
        </div>
      </header>
    );
  };

  renderContent = () => {
    const { oe } = this.props;

    return (
      <div className="font-normal text-[#111316] leading-4">
        <div className="flex flex-col gap-3">
          <p className="text-xs font-normal text-[#707070] leading-[17.07px] line-clamp-3">
            {oe.attributes?.Resumo}
          </p>
          <div>
            {this.isOeIdValid(oe?.id!) && (
              <div
                className="cursor-pointer w-full h-[44px] rounded-3xl  bg-[#0A589B] flex items-center justify-center text-white font-semibold text-xs"
                onClick={this.handleQuizButtonClick}
              >
                Responda
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { oe } = this.props;
    const hasSponsor = !!oe?.attributes?.patrocinador?.data;
    const hasPartner = !!oe?.attributes?.parceria?.data;

    return (
      <div className="flex flex-col w-full h-[292px] md:h-[282px] rounded-2xl bg-white justify-between">
        <section className=" p-[22px]">
          <div
          // className={classNames({
          //   "home-videos-higher-item oe-patrocinada-destaques oe-patrocinada":
          //     hasSponsor,
          //   "home-videos-higher-item oe-parceria-destaques oe-parceria":
          //     hasPartner,
          //   "home-videos-higher-item oe-nao-patrocinada":
          //     !hasSponsor && !hasPartner,
          // })}
          >
            <div className="flex flex-col gap-3">
              {this.renderHeader()}
              {this.renderContent()}
            </div>

            {/* <footer
            data-footer-oe-quizz-patrocinada={hasSponsor}
            data-footer-oe-quizz-parceria={hasPartner}
          >
            <span>
              {hasSponsor ? "CONTEÚDO PATROCINADO" : "CONTEÚDO DE PARCEIROS"}
            </span>
          </footer> */}
          </div>
        </section>
        <div className="flex h-6 w-full relative">
          <FooterOeMedia
            item={{
              Descricao: ""!,
              id: oe?.id!,
              imagemPreview:
                oe?.attributes?.ImagemPreview?.data?.attributes?.url!,
              Infografico: [],
              PossuiCertificado: oe?.attributes?.PossuiCertificado!,
              SecoesTexto: [],
              TempoMedioDuracao: oe?.attributes?.TempoMedioDuracao!,
              TipoObjeto:
                oe?.attributes?.TipoObjeto?.data?.map((a) => ({
                  titulo: a?.attributes?.titulo!,
                })) || [],
              titulo: oe?.attributes?.Titulo!,
              VideoAudio:
                oe?.attributes?.VideoAudio?.map((a) => ({
                  id: Number.parseInt(a?.id!),
                })) || [],
              VideoId:
                oe?.attributes?.VideoAudio &&
                oe?.attributes?.VideoAudio?.length > 0
                  ? Number.parseInt(oe?.attributes?.VideoAudio[0].id!)
                  : 0,
            }}
            color="blue"
            padding={[0, 0]}
            quiz={true}
            nonAbsolute={true}
          />
        </div>
      </div>
    );
  }
}

export default WidgetQuizComponentHOC;
