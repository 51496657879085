import React from "react";

import {Puff} from "../../../assets/svgs";

import "./styles.scss";

interface LoaderContentProps {
  show: boolean;
}

export const LoaderContent: React.FC<LoaderContentProps> = (props) => {
  return (
    <div data-loader-content={props.show}>
      <Puff stroke={"#ccc"} />
      <h4>Carregando...</h4>
    </div>
  );
};
