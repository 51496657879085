import { useEffect, useState } from "react";
import { AreaInteresseCard } from "../../../models/AreaInteresseCard";
import Container from "../../../shared/components/Container";
import { fetchApi } from "../../../shared/services/rest-dotnet.service";
import { useHistory } from "react-router";

async function getSuasAreasInteresse(): Promise<AreaInteresseCard[]> {
  const response = await fetchApi(
    "area-conhecimento/get-area-conhecimento-card-by-user",
    "GET",
  ).then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      return [];
    }
  });

  return response;
}

export default function SuasAreasInteresse() {
  const [areasInteresse, setAreasInteresse] = useState<AreaInteresseCard[]>();
  const history = useHistory();

  const loadSuasAreasInteresse = async () => {
    setAreasInteresse(await getSuasAreasInteresse());
  };

  useEffect(() => {
    loadSuasAreasInteresse();
  }, []);

  const handleNavigate = (item: any) => {
    history.push(
      `/area-de-interesse/${item.titulo
        .toString()
        .toLowerCase()
        .trim()
        .replace(/ /g, "-")}/${item.id}`,
      {
        id: item.id,
        titulo: item.titulo,
      },
    );
  };

  return (
    <Container className="flex flex-col gap-6 py-9 md:py-10">
      <h2 className="text-center text-base font-semibold normal-case leading-5 text-brand-blue md:text-xxl md:leading-22 tablet:text-sm tablet:leading-4">
        Acesse as suas áreas de interesse
      </h2>

      <div className="flex flex-row flex-wrap justify-center gap-3">
        {areasInteresse?.map((area: any, index: number) => (
          <div
            key={area.id}
            onClick={() => handleNavigate(area)}
            className="bg-white flex h-28 w-32 cursor-pointer flex-col  items-center justify-center gap-1 text-center hover:!bg-gray-50  md:size-[160px]  md:h-[159.91px] md:!gap-4"
            style={{
              boxShadow: " 0px 1.48px 2.97px 0px #00000029",
            }}
          >
            <img
              src={area.imagemUrl}
              alt={area.titulo}
              width={77} //77.23px
              height={77} //77.23px
              className="size-[42px] self-center md:size-16 tablet:size-[42px]"
            />
            <span className=" text-center text-8 font-medium leading-4 text-[#111316] md:text-[0.806rem] tablet:text-xxs">
              {area.titulo}
            </span>
          </div>
        ))}
      </div>
    </Container>
  );
}
