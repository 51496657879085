import React from "react";
import { ModalService } from "../../../../shared/components/Modal";
import "./styles.scss";
import { Button } from "../../../../shared/components/ui/button";
import ExclusivoMedicina from "../../../../assets/images/exclusivo-medicina.png";

export const ModalBloqueiaPerfilNaoMedico: React.FC = () => {
  const handleVoltar = () => {
    ModalService.hide();
    window.history.back();
  };

  return (
    <div data-modal-bloqueia-perfil-nao-medico className="justify-center items-center">
      <img src={ExclusivoMedicina} alt="" className="h-[103.42px] w-[120px] md:h-[137.89px] md:w-[160px] mt-10 mb-6" />
      <h3 className="text-[#004F92] text-lg md:text-base font-bold leading-22 mb-3">Conteúdo exclusivo para médicos</h3>
      <p className="text-[#313335] text-base md:text-sm leading-22 md:leading-5 w-[250px] md:w-[350px] !mx-8 mb-6">
        Apenas profissionais de medicina  podem ver esse conteúdo. Continue explorando os conteúdos gratuitos da Academia Digital.
      </p>
      <div data-modal-bloqueia-perfil-nao-medico-footer className="mb-16 md:mb-14">
        <Button
          data-testid="btn-voltar-modal-bloqueio"
          type="button"
          variant="primary"
          onClick={handleVoltar}
        >
          Voltar
        </Button>
      </div>
    </div>
  );
};
