import { useState } from "react";
import {
  AreaDeConhecimento,
  AreaDeConhecimentoEntity,
  ObjetoEducacionalEntity,
  useGetResumoVisualizadosByUserLazyQuery,
} from "../generated/graphql";

export interface ResumeTipoOes {
  audio: number;
  videos: number;
  quizes: number;
  textos: number;
  imagens: number;
}

const useResumeOe = () => {
  const [resume, setResume] = useState<ResumeTipoOes | null>(null);
  const [knowlegdesArea, setKnowlegdesArea] = useState<AreaDeConhecimentoEntity[]>(
    []
  );

  const [GetTotalConsumidosTotalmentePorUser] =
    useGetResumoVisualizadosByUserLazyQuery({
      onCompleted: (response) => {
        const consumidos = response.usersPermissionsUser?.data?.attributes?.OesVisualizados?.data!;
        computeResume(consumidos as ObjetoEducacionalEntity[]);
        computeKnowlegdesArea(consumidos as ObjetoEducacionalEntity[]);
      },
    });

  const computeResume = (oes: ObjetoEducacionalEntity[]) => {
    const _resume = oes.reduce<ResumeTipoOes>(
      (acc, item) => {
        item.attributes?.TipoObjeto?.data?.forEach((tipo) => {
          switch (tipo.id!) {
            case "3":
              acc.audio++;
              break;
            case "2":
              acc.imagens++;
              break;
            case "1":
            case "5":
              acc.quizes++;
              break;
            case "4":
              acc.textos++;
              break;
            case "6":
              acc.videos++;
              break;
          }
        });

        return acc;
      },
      {
        audio: 0,
        videos: 0,
        textos: 0,
        quizes: 0,
      } as ResumeTipoOes
    );

    setResume(_resume!);
  };

  const computeKnowlegdesArea = (oes: ObjetoEducacionalEntity[]) => {
    const _: any = {};

    const returnOes: any = oes.reduce((prev, curr) => {
      let itens: any = prev || {};

      if (curr.attributes?.SubAreaConhecimento?.data) {
        for (const item of curr.attributes?.SubAreaConhecimento?.data!) {
          itens[item?.attributes?.area_de_conhecimento?.data?.id!] = {
            ...item?.attributes?.area_de_conhecimento?.data,
            total: (itens[item?.attributes?.area_de_conhecimento?.data?.id!]?.total || 1) + 1
          }
        }
      }

      return itens;
    }, {});


    const list = Object.keys(returnOes)
      .map((k) => returnOes[k])
      .sort((a, b) => {
        if (a.total < b.total) return -1;
        if (a.total > b.total) return 1;
        return 0;
      });

    const _knowlegdesArea = [];
    for (let i = list.length; i > 0; i--) {
      if (_knowlegdesArea.length < 2 && !!list[i - 1].attributes.icone) {
        _knowlegdesArea.push({ ...list[i - 1] });
      }
    }
    setKnowlegdesArea([..._knowlegdesArea]);
  };

  const gerarHistorico = (id: string) => {
    GetTotalConsumidosTotalmentePorUser({
      variables: {
        id,
      },
    });
  };

  return { data: resume, knowlegdesArea, gerarHistorico };
};

export default useResumeOe;
