import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  ObjetoEducacional,
  ObjetoEducacionalEntity,
  UsersPermissionsUserEntity,
} from "../../../../generated/graphql";

import { ModalService } from "../../../../shared/components/Modal";

import CertificadoPageV2 from "../../../CertificadoPageV2";

import "./style.scss";
import { HEADER_ACTION_TYPE } from "../../../../shared/constants/action-types";
import { useUtils } from "../../../../shared/utils";
import * as Icon from "../../../../assets/svgs";
import { toast } from "react-toastify";
import { TriangleAlert } from "lucide-react";
import { useModalNaoConcluido } from "../ModalNaoConcluido";

interface AcessoDeclaracaoProps {
  oe: ObjetoEducacionalEntity;
  user: UsersPermissionsUserEntity | undefined;
}

interface OE {
  id: string;
}

export const AcessoDeclaracao: React.FC<AcessoDeclaracaoProps> = ({
  oe,
  user,
}) => {
  const dispatch = useDispatch();

  const [concluido, setConcluido] = useState(false);
  const [estahLogado, setEstahLogado] = useState(false);
  const { formatTitle } = useUtils();

  const { mostrar } = useModalNaoConcluido();

  function hasUser() {
    return !!user && oe && oe.id;
  }

  useEffect(() => {
    if (hasUser()) {
      if (!!oe.attributes?.VideoAudio && oe.attributes.VideoAudio.length > 1) {
        let totalWatched = 0;
        let watchedAllVideo = true;
        let finishAllVideo = true;

        let oesConsumidas = user?.attributes?.OesConsumidosParcialmente;

        for (let o of oe.attributes?.VideoAudio) {
          watchedAllVideo =
            watchedAllVideo &&
            !!oesConsumidas &&
            oesConsumidas.data.findIndex(
              (e) => e.attributes?.VideoAudioId?.toString() == o.id
            ) > -1;
          finishAllVideo =
            finishAllVideo &&
            !!oesConsumidas &&
            oesConsumidas.data
              .filter((a) => a.attributes?.VideoAudioId?.toString() == o.id)
              .reduce((ant, prox) => {
                let a = ant || 0;
                a = a + prox.attributes?.segundos!;
                return a;
              }, 0) >=
            oesConsumidas.data.find(
              (a) => a.attributes?.VideoAudioId?.toString() == o.id
            )?.attributes?.duracao!;
        }

        oesConsumidas &&
          oesConsumidas.data?.map((p) => {
            const hasVideoIdOe =
              oe.attributes?.VideoAudio &&
              oe.attributes?.VideoAudio.filter(
                ({ id: idOE }: OE) =>
                  parseInt(idOE) === p.attributes?.VideoAudioId
              );

            if (
              hasVideoIdOe &&
              hasVideoIdOe.length > 0 &&
              !!p.attributes?.segundos
            ) {
              totalWatched += p.attributes?.segundos / 60;
            }

            return totalWatched;
          });

        setEstahLogado(true);

        if (finishAllVideo && watchedAllVideo) {
          setConcluido(true);
          return;
        }
      } else {
        const isOeConcluidaPorUsuario =
          user?.attributes?.OesConsumidosParcialmente?.data.filter(
            (item) =>
              item.attributes?.Oes_Assistidos &&
              item.attributes.Oes_Assistidos.data[0]?.id === oe.id &&
              item.attributes.duracao &&
              item.attributes.segundos &&
              item.attributes.duracao === item.attributes.segundos
          ).length;

        setEstahLogado(true);

        if (!!isOeConcluidaPorUsuario) {
          setConcluido(true);
          return;
        }
      }
    }
  }, [oe, user]);

  return (
    <>
      {oe.attributes?.PossuiCertificado && (
        <>
          {concluido && (
            <div>
              <div
                className="flex justify-between flex-col md:flex-row items-center cursor-pointer"
                data-concluido
                onClick={(ev) => {
                  ev.stopPropagation();
                  mostrarModalCertificado(
                    formatTitle(oe.attributes?.Titulo!),
                    oe.attributes?.TempoMedioDuracao!
                  );
                }}
              >
                <Icon.Certificate />
                <span className="font-normal text-[10px] leading-normal text-[#111316]">
                  Declaração
                </span>
              </div>
            </div>
          )}

          {!concluido && (
            <div>
              <div
                className="flex justify-between flex-col md:flex-row  items-center cursor-pointer"
                onClick={() => {
                  ModalService.hide();
                  if (estahLogado) {
                    mostrar()
                  } else {
                    dispatch({
                      type: HEADER_ACTION_TYPE.PRE_REGISTER_USER,
                      payload: true,
                    });
                  }
                }}
              >
                <Icon.CertificateInactive />
                <span className="font-normal text-[10px] leading-normal text-gray-400">
                  Declaração
                </span>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export const mostrarModalCertificado = (
  titulo: string,
  tempoMedioDuracao: string
) => {
  ModalService.hide();
  ModalService.show({
    content: (
      <CertificadoPageV2
        titulo={titulo}
        tempoMedioDuracao={tempoMedioDuracao}
      />
    ),
    showComponentInFullScreen: true,
    showCloseButton: true,
    closeOutsideModal: true,
  });
};
