import React, { useState, useContext, useEffect } from "react";

import {
  ObjetoEducacionalEntity,
  UsersPermissionsUserEntity,
} from "../../../generated/graphql";
import OeInfograficoTexto from "./components/OeInfograficoTexto";
import { isMobile } from "react-device-detect";
import { BannerSection } from "../../../shared/components";
import { UserContext } from "../../../context/UserContext";

import { TIPO_AREA_PAGINA, TIPO_PAGINA } from "../../../hooks/useBannerApi";
import { OePreviewDesktop, TypeOE } from "../Preview/OePreviewDesktop";
import { OePreviewMobile } from "../Preview/OePreviewMobile";
import OePDFWatch from "../OePDFPage/OePDFWatch";
import {
  Banner,
  loadBanner,
} from "../../../graphql/collections/queries/banner.query";

interface OeInfograficoProps {
  oe: ObjetoEducacionalEntity;
  isPreview: boolean;
  isImagem?: boolean;
  user?: UsersPermissionsUserEntity;
}

export const OeInfograficoPage: React.FC<OeInfograficoProps> = (props) => {
  const [showBanner, setShowBanner] = useState(true);
  const { user } = useContext(UserContext);

  const [banners, setBanners] = useState<Banner[]>([]);

  const carregarBanner = async () => {
    const resultBanner = await loadBanner({
      homePage: false,
      objetoEducacionalId: props.oe.id! ? Number(props.oe.id!) : undefined,
      areaDeConhecimentoId:
        props.oe.attributes?.SubAreaConhecimento?.data
          ?.filter((sub) => {
            return !!sub.attributes?.area_de_conhecimento?.data?.id;
          })
          ?.map((a) => Number(a.attributes?.area_de_conhecimento?.data?.id!)) ||
        [],
      subAreaDeConhecimentoId:
        props.oe.attributes?.SubAreaConhecimento?.data?.map((a) =>
          Number(a.id!),
        ) || [],
      publicoAlvoId:
        props.oe.attributes?.PublicoAlvo?.data?.map((a) => Number(a.id!)) || [],
    });

    setBanners(resultBanner);
  };

  useEffect(() => {
    carregarBanner();
  }, [props.oe]);

  const closeBanner = () => {
    setShowBanner(!showBanner);
  };

  const ext =
    props.oe?.attributes?.Infografico &&
    props.oe?.attributes?.Infografico.length > 0
      ? props.oe?.attributes?.Infografico[0].Imagem?.data?.attributes?.url?.split(
          ".",
        )
      : [""];

  return (
    <>
      {props.isPreview ? (
        !isMobile ? (
          <OePreviewDesktop
            oe={props.oe}
            user={props.user}
            type={TypeOE.Imagem}
          />
        ) : (
          <OePreviewMobile
            oe={props.oe}
            user={props.user}
            type={TypeOE.Imagem}
          />
        )
      ) : props.oe?.attributes?.Infografico &&
        ext &&
        !ext[ext.length - 1].toLowerCase().includes("pdf") ? (
        <OeInfograficoTexto oe={props.oe} user={props.user} />
      ) : (
        <OePDFWatch
          pdf={
            props.oe.attributes?.Infografico
              ? props.oe.attributes.Infografico[0]!.Imagem
              : undefined
          }
          idOe={props.oe.id!}
        />
      )}

      {(() => {
        let bannersFixo = banners?.filter(
          (a) =>
            a.tipoPagina === TIPO_PAGINA.OE &&
            a.areaPagina === TIPO_AREA_PAGINA.FIXO &&
            ((!!user?.id && a.ocultarLogado === false) || !user?.id),
        );

        return (
          <>
            {showBanner && !!bannersFixo && !!bannersFixo.length && (
              <div
                data-banner
                style={{
                  zIndex: 99999,
                }}
                className={
                  !user
                    ? "deslogado"
                    : "fixed bottom-0 left-0 right-0 z-50 mx-auto flex w-full flex-col items-center justify-center md:w-[728px]"
                }
              >
                <BannerSection
                  items={bannersFixo}
                  fixo={true}
                  onClose={closeBanner}
                  showBanner={showBanner}
                />
              </div>
            )}
          </>
        );
      })()}
    </>
  );
};
