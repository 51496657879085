import React from "react";
import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../../../hooks/useGtm";
import "./styles.scss";
import { useHistory } from "react-router";
import { PublicoAlvoModel } from "../../../../models/PublicoAlvoModel";

interface ProfissoesCardsProps {
  publicosAlvos: PublicoAlvoModel;
  profissaoSelect: number;
  setProfissaoSelect: any;
}

export const ProfissaoCard: React.FC<ProfissoesCardsProps> = (props) => {
  const history = useHistory();
  const gtm = useGtm();

  function handlePublicoAlvo(publicoAlvo: PublicoAlvoModel) {
    gtm.push(
      EDataLayerEventCategory.ADE_PROFISSOES,
      EDataLayerEventAction.CLIQUE,
      {
        label: publicoAlvo.titulo!,
      }
    );
    history.push({
      pathname: `/profissoes/${publicoAlvo.id}`,
      state: {
        publicoAlvo: publicoAlvo,
      },
    });
  }

  return (
    <>
      <li
        className="grid__item"
        onClick={() => handlePublicoAlvo(props.publicosAlvos)}
        tabIndex={Number(props.publicosAlvos.id)}
      >
        <img
          height="75px"
          style={{ height: "75px" }}
          src={props?.publicosAlvos?.iconeUrl}
          alt={props.publicosAlvos?.titulo}
          loading="lazy"
        />
        <span className="font-medium text-ssm leading-normal text-[#000000] mt-2 text-center">
          {props.publicosAlvos?.titulo}
        </span>
      </li>
    </>
  );
};
