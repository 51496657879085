import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";
import ResidenciaMedicaHome from "../ResidenciaMedicaHome";
import { useScrollTop } from "../../shared/hooks/useScrollTop";
import { UserDetalheContext } from "../../context/UserPreparaEinsteinContext";
import CursosPagosResidencia from "../ResidenciaMedicaHome/components/cursospagos-residencia";

function ResidenciaMedicaPage() {
  const { userDetalhe } = useContext(UserDetalheContext);
  const { user } = useContext(UserContext);
  const scrollTop = useScrollTop();

  useEffect(() => {
    if (
      userDetalhe !== undefined &&
      !userDetalhe &&
      window.location.pathname !== "/sobre-prepara-einstein"
    ) {
      window.location.href = "/sobre-prepara-einstein";
    }
  }, [user, userDetalhe?.id]);

  scrollTop();

  return (
    <>
      <div className="h-auto bg-[#F5F8FF] py-8">
        <ResidenciaMedicaHome />
      </div>
      <CursosPagosResidencia />
    </>
  );
}

export default ResidenciaMedicaPage;
