import { twMerge } from "tailwind-merge";
import * as Icon from "../../../assets/svgs";
import {
  ResidenciaMedicaModuloEntity,
  ResidenciaMedicaQtdModulo,
} from "../../../generated/graphql";
import { ArrowBigDown, ChevronUp } from "lucide-react";

export default function ModulosResidencia({
  modulo,
  index,
  handleModuleToggle,
  expandedModules,
  residenciaMedicaQtdModulos,
}: {
  modulo: ResidenciaMedicaModuloEntity;
  index: number;
  handleModuleToggle: (index: number) => void;
  expandedModules: number[];
  residenciaMedicaQtdModulos: ResidenciaMedicaQtdModulo;
}) {
  const percentual = Math.min(
    Number(
      (residenciaMedicaQtdModulos?.quantidadeObjetos === 0
        ? 0
        : (residenciaMedicaQtdModulos?.progresso! /
            residenciaMedicaQtdModulos?.quantidadeObjetos!) *
          100
      ).toFixed(0),
    ),
    100,
  );

  return (
    <>
      <div
        className={twMerge(
          "bg-white group mt-2 flex h-[92px]  w-full cursor-pointer flex-col rounded-xl !p-6 hover:border-[1px] hover:border-[#01B092] ",
          expandedModules.includes(index)
            ? residenciaMedicaQtdModulos?.progresso! === 0
              ? "border-[1px] border-[#01B092]"
              : "border-[1px] border-[#01B092]"
            : "",
        )}
        style={{
          boxShadow: "1px 2px 4px 0px #0B1F4D14",
        }}
      >
        <div className="flex flex-row gap-2">
          <div className="flex w-full flex-col gap-2">
            <div className="flex w-full justify-between  ">
              <span className="inline-block text-xs font-bold leading-4 text-[#111316]">
                {modulo?.attributes?.titulo}
              </span>
              <span className="inline-block text-xs font-medium leading-4 text-[#111316]">
                {residenciaMedicaQtdModulos?.progresso}/
                {residenciaMedicaQtdModulos?.quantidadeObjetos}
              </span>
            </div>

            <div className=" flex w-full items-center justify-between gap-3">
              <span className="inline-block text-xs font-bold leading-none text-[#626466]">
                {percentual}%
              </span>
              <div
                className={twMerge(
                  "bg-white h-[6px] w-full rounded-[20px] text-[#111316] group-hover:border-[#E1E3E6]",
                  expandedModules.includes(index)
                    ? residenciaMedicaQtdModulos?.progresso! === 0
                      ? "bg-[#E8E9EB]"
                      : "bg-[#E8E9EB]"
                    : "bg-[#E8E9EB]",
                )}
              >
                <div
                  className="h-[6px] rounded-[20px] bg-[#00B193]"
                  style={{
                    width: `${percentual}%`,
                  }}
                ></div>
              </div>
            </div>
          </div>
          <button className="" onClick={() => handleModuleToggle(index)}>
            <ChevronUp
              className={`size-5 text-[#00b194] ${
                expandedModules.includes(index)
                  ? "rotate-180 transform transition-all duration-500"
                  : ""
              }`}
            />
          </button>
        </div>
      </div>
    </>
  );
}
