import React from "react";

import {
  Maybe,
  ObjetoEducacionalEntity,
  PerfilDeAutor,
  PerfilDeAutorEntity,
} from "../../../../generated/graphql";

import { AuthorBox } from "../../../../shared/components/AuthorBox";

import "./styles.scss";
import Container from "../../../../shared/components/Container";

interface AboutAuthorsProps {
  Autores: Maybe<PerfilDeAutorEntity>[];
  oe?: ObjetoEducacionalEntity;
}

export const AboutAuthors: React.FC<AboutAuthorsProps> = (props) => {
  const { Autores, oe } = props;

  return (
    <div className="bg-[#E2E2E2]  md:rounded-none">
      <Container className="py-8">
        <div>
          <span className="text-base font-semibold leading-normal text-[#107B95] md:text-xl ">
            {Autores && Autores?.length > 1
              ? "Sobre os Autores"
              : "Sobre o Autor"}
          </span>
        </div>
        <div className="grid-col-1 mt-7 grid flex-wrap md:grid-cols-2 md:gap-6 ">
          {!!Autores &&
            Autores.map((author) => (
              <AuthorBox key={author?.id} author={author} oe={oe} />
            ))}
        </div>
      </Container>
    </div>
  );
};
