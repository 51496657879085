import React, { useState } from "react";
import { ProfissaoCard } from "../ProfissaoCard";

import "./styles.scss";
import { PublicoAlvoModel } from "../../../../models/PublicoAlvoModel";

interface ProfissoesCardsProps {
  publicosAlvos: PublicoAlvoModel[];
  columns?: number;
}

export const ProfissoesCards: React.FC<ProfissoesCardsProps> = (props) => {
  const [profissaoSelect, setProfissaoSelect] = useState<number>(-1);

  return (
    <ul data-profession-areas-inner>
      {props.publicosAlvos.map(
        (item, index) =>
          item?.iconeUrl && (
            <ProfissaoCard
              key={index}
              publicosAlvos={item}
              profissaoSelect={profissaoSelect}
              setProfissaoSelect={setProfissaoSelect}
            />
          )
      )}
    </ul>
  );
};
