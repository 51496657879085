/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import HtmlParse from "html-react-parser";
import { useHistory } from "react-router-dom";
import {
  ComponentSecaoVideoAudioVideoAudio,
  ObjetoEducacionalEntity,
  PublicoAlvo,
  UsersPermissionsUserEntity,
} from "../../../../../../generated/graphql";

import { BannerSection } from "../../../../../../shared/components";
import BtnVideoPlayIcon from "../../../../../../assets/svgs/button-video-play-blue.svg";

import { UserContext } from "../../../../../../context/UserContext";
import { format, isFuture } from "date-fns";
import ptBr from "date-fns/locale/pt-BR";
import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../../../../../hooks/useGtm";

import { ArrowBackOutlined } from "@material-ui/icons";
import {
  TIPO_AREA_PAGINA,
  TIPO_PAGINA,
} from "../../../../../../hooks/useBannerApi";
import { useUtils } from "../../../../../../shared/utils";
import {
  Banner,
  loadBanner,
} from "../../../../../../graphql/collections/queries/banner.query";
import { Badge } from "../../../../../../shared/components/ui/badge";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../../../shared/components/ui/dialog";
import Container from "../../../../../../shared/components/Container";
import { Calendar, Play } from "lucide-react";

interface OeVideoPreviewProps {
  oe: ObjetoEducacionalEntity;
  history?: any;
  location?: any;
  match?: any;
  user?: UsersPermissionsUserEntity;
}

export const OeLiveDesktop: React.FC<OeVideoPreviewProps> = (props) => {
  const gtm = useGtm();

  const { user } = useContext(UserContext);
  const history = useHistory();

  const [showBanner, setShowBanner] = useState(true);
  const [estaAovivo, setEstaAovivo] = useState(false);
  const [videoAtivo, setVideoAtivo] = useState<
    ComponentSecaoVideoAudioVideoAudio | undefined
  >(undefined);
  const { formatTitle } = useUtils();

  const [banners, setBanners] = useState<Banner[]>([]);

  const carregarBanner = async () => {
    const resultBanner = await loadBanner({
      homePage: false,
      objetoEducacionalId: props.oe.id! ? Number(props.oe.id!) : undefined,
      areaDeConhecimentoId:
        props.oe.attributes?.SubAreaConhecimento?.data
          ?.filter((sub) => {
            return !!sub.attributes?.area_de_conhecimento?.data?.id;
          })
          ?.map((a) => Number(a.attributes?.area_de_conhecimento?.data?.id!)) ||
        [],
      subAreaDeConhecimentoId:
        props.oe.attributes?.SubAreaConhecimento?.data?.map((a) =>
          Number(a.id!),
        ) || [],
      publicoAlvoId:
        props.oe.attributes?.PublicoAlvo?.data?.map((a) => Number(a.id!)) || [],
    });

    setBanners(resultBanner);
  };

  useEffect(() => {
    carregarBanner();
  }, [props.oe]);

  useEffect(() => {
    atualizarAoVivo();

    if (props.oe.attributes?.VideoAudio && props.oe.attributes?.VideoAudio[0]) {
      setVideoAtivo(props.oe.attributes?.VideoAudio[0]);
    }
  }, [props]);

  const joinPublicoAlvoName = (list: PublicoAlvo[] = []) => {
    const strArr = list.reduce((acc: Array<String>, item) => {
      if (item && item.titulo) {
        acc.push(item.titulo);
      }
      return acc;
    }, []);

    return strArr.join(", ");
  };

  const atualizarAoVivo = () => {
    setEstaAovivo(!isFuture(new Date(props.oe.attributes?.DataHoraLive)));
    setTimeout(() => atualizarAoVivo(), 1000);
  };

  const closeBanner = () => {
    setShowBanner(!showBanner);
  };

  const getUrlAssistirAgora = (): string => {
    if (props.oe.id && videoAtivo?.id) {
      return `/oe/${props.oe.id}/live/${videoAtivo?.id}`;
    }
    return `/oe/${props.oe.id}/live`;
  };

  return (
    <>
      <section
        className="flex h-[592px] w-full bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${props.oe.attributes?.ImagemCapa?.data?.attributes?.url})`,
        }}
      >
        <Container className="relative">
          {estaAovivo && (
            <button
              onClick={() => {
                // GTM doc linha 86

                gtm.push(
                  EDataLayerEventCategory.ADE_OE_INTERACAO_VIDEO,
                  EDataLayerEventAction.CLIQUE,
                  {
                    label: `iniciar-live:${formatTitle(
                      props.oe.attributes?.Titulo!,
                    )}`,
                  },
                );
                history.push(getUrlAssistirAgora());
              }}
              className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 cursor-pointer"
            >
              <Play size={60} color="white" />
            </button>
          )}
          <div className="flex h-full w-[364px] flex-col items-start justify-center gap-6">
            <div className="flex flex-col gap-3">
              {estaAovivo ? (
                <div className="flex">
                  <Badge className="flex h-6 bg-[#FDFEFF]">
                    <div className="flex items-center gap-1">
                      <div className="flex size-[13px] animate-pulse items-center justify-center rounded-full bg-red-200">
                        <div className="size-[9px] rounded-full bg-red-500"></div>
                      </div>
                      <span className="text-xs font-extrabold uppercase text-[#000000]">
                        AO VIVO
                      </span>
                    </div>
                  </Badge>
                </div>
              ) : (
                <div className="flex">
                  <Badge className="h-6 border-0 bg-[#004F92]">
                    <span className="text-xxs font-extrabold uppercase text-[#FDFEFF]">
                      live em breve
                    </span>
                  </Badge>
                </div>
              )}

              <h1 className="text-xxl font-medium leading-[26.82px] text-[#FDFEFF]">
                <span className="text-xxl font-medium leading-[26.82px] text-[#FDFEFF]">
                  {formatTitle(props.oe.attributes?.Titulo!)}
                </span>
              </h1>

              <Dialog>
                <DialogTrigger asChild>
                  <div className="text-sm font-medium leading-[17.07px]">
                    <p
                      className={`!line-clamp-2 inline-block  ${
                        true ? "text-[#FDFEFF] " : "text-[#111316]"
                      }`}
                    >
                      {props.oe.attributes?.Descricao &&
                        HtmlParse(
                          props.oe.attributes?.Descricao.replace(
                            "<p>",
                            "<p className={`truncate line-clamp-2 text-sm font-medium leading-[17.07px]`}>",
                          ),
                        )}
                    </p>
                    <span
                      className={`font-bold  underline ${
                        true ? "text-[#FDFEFF] " : "text-[#111316]"
                      }`}
                    >
                      Mostrar mais
                    </span>
                  </div>
                </DialogTrigger>
                <DialogContent className="max-w-sm rounded-lg py-8">
                  <DialogHeader className="flex w-full items-start justify-start">
                    <DialogTitle className="text-base  font-bold normal-case leading-22 text-[#004F92]">
                      Descrição
                    </DialogTitle>
                  </DialogHeader>
                  <div className="flex items-center space-x-2">
                    <div className="grid flex-1 gap-2">
                      <p
                        className={`inline-block text-sm font-normal leading-[20px] text-[#313335]`}
                      >
                        {props.oe.attributes?.Descricao &&
                          HtmlParse(
                            props.oe.attributes?.Descricao.replace(
                              "<p>",
                              "<p className={` text-sm font-normal leading-[20px] text-[#313335]`}>",
                            ),
                          )}
                      </p>
                    </div>
                  </div>
                </DialogContent>
              </Dialog>

              {!estaAovivo ? (
                <div className="flex items-center gap-1 text-xs font-bold uppercase leading-[30px] text-white-200">
                  <Calendar size={16.94} />
                  {format(
                    new Date(props.oe.attributes?.DataHoraLive),
                    "dd 'de' MMMM 'às' HH:mm'h'  ",
                    {
                      locale: ptBr,
                    },
                  )}
                </div>
              ) : (
                <div className="flex items-center gap-1 text-xs font-bold uppercase leading-[30px] text-white-200">
                  <Play size={16.94} />
                  acontecendo agora
                </div>
              )}

              <div className="flex flex-col gap-3">
                <div className="flex flex-col flex-nowrap">
                  <div className="flex flex-col gap-3">
                    <p
                      className={`text-sm font-bold leading-[17.07px] ${
                        true ? "text-[#FDFEFF] " : "text-[#111316]"
                      }`}
                    >
                      Recomendado:
                    </p>

                    <Dialog>
                      <DialogTrigger asChild>
                        <div className="text-sm font-medium leading-[17.07px]">
                          <span
                            className={`!line-clamp-2 inline-block text-sm font-medium leading-[17.07px] ${
                              true ? "text-[#FDFEFF] " : "text-[#111316]"
                            }`}
                          >
                            {props.oe.attributes?.PublicoAlvo?.data.map(
                              (p, index, arr) =>
                                index === arr.length - 1
                                  ? `${p.attributes?.titulo}`
                                  : `${p.attributes?.titulo}, `,
                            )}
                          </span>
                          {props?.oe?.attributes?.PublicoAlvo?.data?.length! >
                            6 && (
                            <span
                              className={`font-bold  underline ${
                                true ? "text-[#FDFEFF] " : "text-[#111316]"
                              }`}
                            >
                              Mostrar mais
                            </span>
                          )}
                        </div>
                      </DialogTrigger>
                      <DialogContent className="py-8">
                        <DialogHeader className="flex w-full items-start justify-start">
                          <DialogTitle className="text-base  font-bold normal-case leading-22 text-[#004F92]">
                            Recomendado
                          </DialogTitle>
                        </DialogHeader>
                        <div className="flex items-center space-x-2">
                          <div className="grid flex-1 gap-2">
                            <p
                              className={`inline-block text-sm font-normal leading-[20px] text-[#313335]`}
                            >
                              {props.oe.attributes?.PublicoAlvo?.data.map(
                                (p, index, arr) =>
                                  index === arr.length - 1
                                    ? `${p.attributes?.titulo}`
                                    : `${p.attributes?.titulo}, `,
                              )}
                            </p>
                          </div>
                        </div>
                      </DialogContent>
                    </Dialog>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {estaAovivo && (
            <div data-preview-play-desktop className="hide-for-mobile">
              <a
                className="play-button"
                onClick={() => {
                  // GTM doc linha 86
                  history.push(getUrlAssistirAgora());
                  gtm.push(
                    EDataLayerEventCategory.ADE_OE_INTERACAO_VIDEO,
                    EDataLayerEventAction.CLIQUE,
                    {
                      label: `iniciar-video:${formatTitle(
                        props.oe.attributes?.Titulo!,
                      )}`,
                    },
                  );
                }}
              >
                <img src={BtnVideoPlayIcon} alt="Video Play" />
              </a>
            </div>
          )}
        </Container>
      </section>
      {(() => {
        let bannersFixo = banners?.filter(
          (a) =>
            a.tipoPagina === TIPO_PAGINA.OE &&
            a.areaPagina === TIPO_AREA_PAGINA.FIXO &&
            ((!!user?.id && a.ocultarLogado === false) || !user?.id),
        );

        return (
          <>
            {showBanner && !!bannersFixo && !!bannersFixo.length && (
              <div
                data-banner
                style={{
                  zIndex: 99999,
                }}
                className={
                  !user
                    ? "deslogado"
                    : "fixed bottom-0 left-0 right-0 z-50 mx-auto flex w-[728px] flex-col items-center justify-center"
                }
              >
                <BannerSection
                  items={bannersFixo}
                  fixo={true}
                  onClose={closeBanner}
                  showBanner={showBanner}
                />
              </div>
            )}
          </>
        );
      })()}
    </>
  );
};
