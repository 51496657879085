import React from "react";

import { ObjetoEducacional, ObjetoEducacionalEntity, UserFragment, UsersPermissionsUserEntity } from "../../../generated/graphql";
import { OePreviewDesktop, TypeOE } from "../Preview/OePreviewDesktop";
import { OePreviewMobile } from "../Preview/OePreviewMobile";

import { isMobile } from "react-device-detect";
import { TextOEContent } from "./components/TextOEContent";

interface OeTextoProps {
  oe: ObjetoEducacionalEntity;
  isPreview: boolean;
  user?: UsersPermissionsUserEntity;
}

export const OeTextoPage: React.FC<OeTextoProps> = (props) => {
  return (
    <>
      {props.isPreview ? (
        !isMobile ? (
          <OePreviewDesktop
            oe={props.oe}
            user={props.user}
            type={TypeOE.Texto}
          />
        ) : (
          <OePreviewMobile
            oe={props.oe}
            user={props.user}
            type={TypeOE.Texto}
          />
        )
      ) : (
        <TextOEContent oe={props.oe} user={props.user} />
      )}
    </>
  );
};
