import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import "./styles.scss";

import { ModalService } from "../../../../shared/components/Modal";
import { HEADER_ACTION_TYPE } from "../../../../shared/constants/action-types";
import { Button } from "../../../../shared/components/ui/button";

export const useModalQuaseLa = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleFechar = () => ModalService.hide();

  const _renderizar = () => {
    return (
      <div data-modal-quase-la>
        <h3>Você está quase lá...</h3>

        <div data-row>
          <p>
            Para ter acesso a declaração de participação ao concluir o conteúdo
            da Academia Digital Einstein, é necessário realizar cadastro.
          </p>
        </div>

        <div data-row data-flex-row-arround>
          <Button
            data-testid="btn-quero-me-cadastrar-quase-la"
            type="button"
            variant="primary"
            onClick={() => {
              ModalService.hide();
              history.push("/novo-usuario");
            }}
          >
            Quero me cadastrar
          </Button>

          <Button
            data-testid="btn-agora-nao-quase-la"
            type="button"
            variant="outline"
            className="button-secondary-blue"
            onClick={handleFechar}
          >
            Agora não
          </Button>
        </div>

        <div data-width="100" className="flex-jc-c flex">
          <span
            data-testid="btn-ja-e-cadastrado-quase-la"
            className="link-primary link-redirect-login"
            onClick={() => {
              ModalService.hide();
              dispatch({
                type: HEADER_ACTION_TYPE.TOGGLE_LOGIN,
                payload: true,
              });
            }}
          >
            Já é cadastrado? Faça o seu login
          </span>
        </div>
      </div>
    );
  };

  const mostrar = () => {
    ModalService.show({
      content: _renderizar(),
    });
  };

  return { mostrar };
};
