import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  AreaConhecimentoItem,
  ObjetoEducacionalEntity,
  SubAreaConhecimento,
  SubAreaConhecimentoEntity,
  useGetAreasConhecimentoByPublicoAlvoLazyQuery,
  useGetSubAreasInteresseLazyQuery,
} from "../../../../generated/graphql";
import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../../../hooks/useGtm";

import "./styles.scss";
import {
  Breadcrumb,
  BreadcrumbItem,
  CardSection,
  HeaderSection,
  Loader,
  Pagination,
} from "../../../../shared/components";
import Container from "../../../../shared/components/Container";
import ArrowDown from "../../../../assets/svgs/arrow-down.svg";
import { Origem } from "../../../OeAreaConhecimentoPage";
import { PublicoAlvoModel } from "../../../../models/PublicoAlvoModel";
import { fetchApi } from "../../../../shared/services/rest-dotnet.service";

interface ProfissoesCardsProps {
  areaConhecimento: AreaConhecimentoItem;
  areaConhecimentoSelect: number;
  setAreaConhecimentoSelect: any;
  publicoAlvo?: string;
}

async function getPublicoAlvo(id: string): Promise<PublicoAlvoModel> {
  const response = await fetchApi(`publico-alvo/get-publico-alvo-by-id?id=${id}`, "GET").then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      return null;
    }
  });
  return response;
}

export const ListSubAreaInteresse: React.FC<ProfissoesCardsProps> = (props) => {
  const gtm = useGtm();
  const [areaConhecimentoSelect, setAreaConhecimentoSelect] =
    useState<number>(-1);

  const [page, setPage] = useState(1);
  const [start, setStart] = useState(0);
  const [offset, setOffset] = useState(5);
  const [publicoALvo, setPublicoALvo] = useState<PublicoAlvoModel>();
  const [totalBullets, setTotalBullets] = useState(0);
  const inputRef = useRef<any>(null);
  const suggestionsRef = useRef<any>(null);

  let { id } = useParams<{ id?: string }>();
  const history = useHistory();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [
    getAreaConhecimento,
    { loading: loadingAreasConhecimento, data: areaInteresse },
  ] = useGetAreasConhecimentoByPublicoAlvoLazyQuery({
    variables: {
      publicoAlvoId: id!,
    },
    fetchPolicy: "network-only",
    onCompleted: (response: any) => {},
    onError: (error) => {
      console.log(error);
    },
  });

  const loadPublicoAlvo = async () => {
    const publico = await getPublicoAlvo(id!);
    setPublicoALvo(publico);
  }

  useEffect(() => {
    loadPublicoAlvo();
  }, [id])


  useEffect(() => {
    if (id) {
      getObjetoBySubAreaConhecimento();
      getAreaConhecimento({
        variables: {
          publicoAlvoId: id!,
        },
      });
    }
  }, [id]);

  const [getObjetoBySubAreaConhecimento, { loading: loadingObjetos, data }] =
    useGetSubAreasInteresseLazyQuery({
      fetchPolicy: "network-only",
      variables: {
        start: start,
        limit: offset,
        where: {
          ObjetosEducacionais: {
            PublicoAlvo: {
              id: {
                eq: id,
              },
            },
          },
        },
        whereObjetoEducacional: {
          PublicoAlvo: {
            id: {
              eq: id,
            },
          },
        },
        sort: "Nome",
      },
      onCompleted: (response) => {
        if (
          response &&
          response.subAreaConhecimentos?.meta?.pagination?.total
        ) {
          const total = response.subAreaConhecimentos?.meta?.pagination?.total;

          const bulletCount = total ? Math.abs(Math.ceil(total / offset)) : 0;

          setTotalBullets(bulletCount);
        }
      },
    });

  useEffect(() => {
    if (areaConhecimentoSelect !== -1) {
      setStart(0);
      getObjetoBySubAreaConhecimento({
        variables: {
          start: 0,
          limit: offset,
          where: {
            area_de_conhecimento: {
              id: {
                eq: areaConhecimentoSelect.toString(),
              },
            },
            ObjetosEducacionais: {
              PublicoAlvo: {
                id: {
                  eq: id,
                },
              },
            },
          },
          sort: "Nome",
        },
      });
    }
  }, [areaConhecimentoSelect]);

  function handleAreaInteresse(areaConhecimento: AreaConhecimentoItem) {
    setAreaConhecimentoSelect(Number(areaConhecimento.id!));
    setIsOpen(false);
    gtm.push(
      EDataLayerEventCategory.ADE_PROFISSOES_INTERESSE,
      EDataLayerEventAction.CLIQUE,
      {
        label: areaConhecimento.titulo!,
      },
    );
  }

  const criarLink = (subAreaConhecimento: SubAreaConhecimentoEntity) => {
    return `/area-de-interesse/${subAreaConhecimento?.attributes?.area_de_conhecimento?.data?.attributes
      ?.titulo!.toLowerCase()
      .replace(/[" "]/g, "-")}/${
      subAreaConhecimento.attributes?.area_de_conhecimento?.data?.id
    }/${getNome(subAreaConhecimento.attributes!)
      .toLowerCase()
      .replace(/[" "]/g, "-")}/${subAreaConhecimento?.id}`;
  };

  const getNome = (subAreaInteresse: SubAreaConhecimento) => {
    if (subAreaInteresse.Nome.indexOf("/") > -1) {
      return subAreaInteresse.Nome.substring(
        subAreaInteresse.Nome.indexOf("/") + 1,
      );
    }
    return subAreaInteresse.Nome;
  };

  const handlePageChange = (event: any, page: number) => {
    event.preventDefault();

    setPage(page);
    const start = page - 1 === 0 ? 0 : (page - 1) * offset;
    setStart(start);
    let where: any = {
      ObjetosEducacionais: {
        PublicoAlvo: {
          id: {
            eq: id,
          },
        },
      },
    };

    if (areaConhecimentoSelect != -1) {
      where["area_de_conhecimento"] = {
        id: {
          eq: areaConhecimentoSelect.toString(),
        },
      };
    }

    getObjetoBySubAreaConhecimento({
      variables: {
        start: start,
        limit: offset,
        where,
        sort: "Nome",
      },
    });
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      suggestionsRef.current &&
      !suggestionsRef.current.contains(event.target as Node) &&
      inputRef.current &&
      !inputRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    // Remova o evento ao desmontar o componente para evitar vazamento de memória.
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      <Loader
        show={loadingObjetos || loadingAreasConhecimento}
        message="Aguarde enquanto o conteúdo é carregado..."
      />
      <div data-professions-page>
        <HeaderSection>
          <Breadcrumb>
            <BreadcrumbItem to="/">Home</BreadcrumbItem>
            <BreadcrumbItem active>Profissões</BreadcrumbItem>
          </Breadcrumb>
          <h3 className="text-white text-base font-semibold leading-none md:text-xxl">
            {publicoALvo?.titulo}
          </h3>
        </HeaderSection>
        <section className="pb-8">
          <Container className="pt-9 md:pt-14">
            <div className="flex w-full flex-col items-end md:justify-between">
              <div className="relative flex h-[100px] w-full flex-col">
                <label className="text-base font-semibold leading-normal text-[#107B95]">
                  Selecione a área de interesse
                </label>
                {/* Select AreaInteresse */}
                <div className="bg-white z-[50] !mt-5 w-full cursor-pointer rounded-xl border md:w-[300px] ">
                  <div
                    className="flex h-[50px] items-center  justify-between rounded-xl px-[23px] py-[12px]"
                    onClick={() => {
                      setIsOpen(!isOpen);
                    }}
                    ref={inputRef}
                  >
                    {areaConhecimentoSelect === -1 ? (
                      <>
                        <div className="text-sm font-semibold leading-4 text-[#626466]">
                          Selecione a área
                        </div>
                        <img
                          src={ArrowDown}
                          alt=""
                          className={isOpen ? "rotate-180 transform" : ""}
                        />
                      </>
                    ) : (
                      <>
                        <div className="flex items-center gap-3">
                          <img
                            src={
                              areaInteresse?.areasDeConhecimentoByPublicoAlvo.filter(
                                (item) =>
                                  Number(item.id) === areaConhecimentoSelect,
                              )[0]?.imagem_url
                            }
                            className="h-9 w-9"
                            alt={
                              areaInteresse?.areasDeConhecimentoByPublicoAlvo.filter(
                                (item) =>
                                  Number(item.id) === areaConhecimentoSelect,
                              )[0]?.titulo
                            }
                          />
                          <div className="text-sm font-semibold leading-4 text-[#626466]">
                            {areaInteresse?.areasDeConhecimentoByPublicoAlvo
                              ?.filter(
                                (item) =>
                                  Number(item.id) === areaConhecimentoSelect,
                              )
                              .map((item) => (
                                <span key={item.id}>{item.titulo}</span>
                              ))}
                          </div>
                        </div>
                        <img
                          src={ArrowDown}
                          alt={
                            areaInteresse?.areasDeConhecimentoByPublicoAlvo.filter(
                              (item) =>
                                Number(item.id) === areaConhecimentoSelect,
                            )[0]?.titulo
                          }
                          className={isOpen ? "rotate-180 transform" : ""}
                        />
                      </>
                    )}
                  </div>
                  {isOpen && (
                    <div className="w-full overflow-hidden rounded-xl rounded-t-none pr-2 md:w-[300px]">
                      <ul className="max-h-[300px] w-full cursor-pointer overflow-y-auto">
                        {areaInteresse &&
                          [
                            ...(areaInteresse?.areasDeConhecimentoByPublicoAlvo ||
                              []),
                          ]
                            .sort((a, b) =>
                              a?.titulo
                                .trim()
                                .localeCompare(b?.titulo.trim(), "pt-BR", {
                                  sensitivity: "base",
                                }),
                            )
                            .map((item, index) => (
                              <li
                                key={index}
                                className="flex w-full items-center gap-3 p-[0.75rem] hover:bg-[#107B951A]"
                                onClick={() => {
                                  handleAreaInteresse(
                                    item as AreaConhecimentoItem,
                                  );
                                }}
                                ref={suggestionsRef}
                              >
                                <input
                                  type="checkbox"
                                  name="area-interesse"
                                  value={areaConhecimentoSelect}
                                  className="absolute inset-0"
                                  style={{
                                    all: "unset",
                                  }}
                                />
                                <img
                                  src={item?.imagem_url}
                                  alt={item?.titulo}
                                  className="h-9 w-9"
                                />
                                <span className="text-sm font-medium leading-4">
                                  {item?.titulo}
                                </span>
                              </li>
                            ))}
                      </ul>
                    </div>
                  )}
                </div>
                <div className="hidden h-[100px] md:-mt-9 md:block">
                  {data?.subAreaConhecimentos?.data[0]?.attributes
                    ?.ObjetosEducacionais?.data?.length! > 0 &&
                    !isOpen && (
                      <Pagination
                        className="m-0"
                        count={totalBullets}
                        page={page}
                        onChange={handlePageChange}
                        disabled={loadingObjetos}
                      />
                    )}
                </div>
              </div>
            </div>
          </Container>
          <Container>
            <div className="mt-7 block md:hidden">
              {data?.subAreaConhecimentos?.data[0]?.attributes
                ?.ObjetosEducacionais?.data?.length! > 0 &&
                !isOpen && (
                  <Pagination
                    className=""
                    count={totalBullets}
                    page={page}
                    onChange={handlePageChange}
                    disabled={loadingObjetos}
                  />
                )}
            </div>
            <div className="">
              {data?.subAreaConhecimentos?.data?.length! > 0 &&
                data?.subAreaConhecimentos?.data?.map(
                  (subAreaInteresse, index) => (
                    <React.Fragment key={index}>
                      {subAreaInteresse?.attributes?.ObjetosEducacionais &&
                        subAreaInteresse?.attributes?.ObjetosEducacionais?.data
                          ?.length > 0 && (
                          <CardSection
                            key={index}
                            title={subAreaInteresse?.attributes?.Nome!}
                            items={
                              subAreaInteresse?.attributes?.ObjetosEducacionais
                                ?.data as ObjetoEducacionalEntity[]
                            }
                            linkUrl={criarLink(
                              subAreaInteresse as SubAreaConhecimentoEntity,
                            )}
                            onClickVerMais={() => {
                              history.push(
                                criarLink(
                                  subAreaInteresse as SubAreaConhecimentoEntity,
                                ),
                                {
                                  profissao: true,
                                  titulo: subAreaInteresse.attributes?.Nome,
                                  origem: Origem.PROFISSAO,
                                  publicoAlvo:
                                    publicoALvo?.titulo,
                                },
                              );
                            }}
                            patricinadores={false}
                            showAllSee={true}
                            limit={9}
                            loading={loadingObjetos}
                          />
                        )}
                    </React.Fragment>
                  ),
                )}
              {data?.subAreaConhecimentos?.data[0]?.attributes
                ?.ObjetosEducacionais?.data?.length! > 0 && (
                <Pagination
                  count={totalBullets}
                  page={page}
                  onChange={handlePageChange}
                  disabled={loadingAreasConhecimento}
                />
              )}
            </div>
          </Container>
        </section>
      </div>
    </>
  );
};
