import { ObjetoEducacionalEntity } from "../../../generated/graphql";
import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../../hooks/useGtm";
import { useUtils } from "../../utils";
import { Button } from "../ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/tool-tip";

interface RecomendationCardProps {
  imageSrc: string;
  title: string;
  description: string;
  buttonText: string;
  link: string;
  categoryNameADE: string;
  objetoEducacional: ObjetoEducacionalEntity;
  userID: string | undefined;
}

const RecomendationCard: React.FC<RecomendationCardProps> = ({
  imageSrc,
  title,
  description,
  buttonText,
  link,
  categoryNameADE,
  objetoEducacional,
  userID,
}) => {
  const { formatTitle } = useUtils();

  const gtm = useGtm();

  const handleLink = (link: string) => {
    gtm.push(
      EDataLayerEventCategory.ADE_RECOMENDACAO,
      EDataLayerEventAction.CLIQUE,
      {
        label: `Objeto Educacional id: ${objetoEducacional.id}`,
        TituloOE: formatTitle(objetoEducacional?.attributes?.Titulo!),
        idUsuario: userID,
      },
    );
    window.open(link, "_blank");
  };

  return (
    <div
      className="my-2 flex h-[470px] w-full flex-col overflow-hidden rounded-xl border-[0.2px] border-[#cccccc]/40 bg-white-200 shadow-sm tablet:h-auto"
      // style={{
      //   boxShadow: "0px 4px 12px 0px #0B1F4D1A",
      // }}
    >
      <div className="h-[138px] w-full border tablet:h-[116.25px]">
        <img
          src={imageSrc}
          alt={title}
          className="h-full w-full object-cover"
        />
      </div>
      <div className="flex h-full flex-col px-6 pt-6 tablet:h-[157.55px] tablet:px-[9px] tablet:py-[13px]">
        <p
          className="line-clamp-5 text-base font-semibold text-[#21272A] tablet:text-xs 
          tablet:leading-[14.63px]
        "
        >
          {title}
        </p>
        <div className="mt-2 flex h-auto flex-col">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger className="line-clamp-5" asChild>
                <p className=" line-clamp-4 text-sm font-normal tablet:text-[10px] tablet:leading-[13.95px]">
                  {description}
                </p>
              </TooltipTrigger>
              <TooltipContent className="max-w-lg bg-black-900 text-gray-500">
                <p className=" text-sm font-normal">{description}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
      <div className="mb-6 flex w-full justify-center">
        <Button
          data-testid="btn-saiba-mais-simulacao"
          id="btn-saiba-mais-simulacao"
          variant="primary"
          size="default"
          onClick={() => {
            handleLink(link);
          }}
        >
          Saiba mais
        </Button>
      </div>
    </div>
  );
};

export default RecomendationCard;
