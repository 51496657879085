import { gql } from '@apollo/client';
import { QueryClient } from '../..';
import { GraphqlTopicResponse } from '../../../shared/interfaces/topic.interface';

const GET_TOPICS = gql`
query {
  topicos(sort:"titulo:asc") {
    data {
      id
      attributes {
        titulo
      }
    }
	}
}
`;

export default function QueryTopics() {
  return QueryClient<GraphqlTopicResponse>(GET_TOPICS);
}
