import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Theme, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

import InfoPatrocinadorBrancoHover from "../../assets/svgs/info-patrocinador-branco-hover.svg";

import {
  Breadcrumb,
  BreadcrumbItem,
  CardSection,
  HeaderSection,
  Loader,
} from "../../shared/components";

import {
  CategoriaPatrocinador,
  CategoriaPatrocinadorEntity,
  Patrocinador,
  PatrocinadorEntity,
  useGetCategoriaPatrocinadoresLazyQuery,
} from "../../generated/graphql";

import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../hooks/useGtm";

import "./styles.scss";
import { useScrollTop } from "../../shared/hooks/useScrollTop";
import Container from "../../shared/components/Container";

export const CategoriaPatrocinadoresPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<CategoriaPatrocinadorEntity[]>([]);
  const gtm = useGtm();
  const scrollTop = useScrollTop();
  const [GetCategoriasPatrocinadores, { data: refecthCategorias }] =
    useGetCategoriaPatrocinadoresLazyQuery({
      fetchPolicy: "network-only",
      onCompleted: (response) => {
        if (response.categoriaPatrocinadors) {
          setData(
            response.categoriaPatrocinadors
              .data as CategoriaPatrocinadorEntity[]
          );
        }

        setLoading(false);
      },
    });

  useEffect(() => {
    scrollTop();

    return () => {
      // GTM doc linha 64
      gtm.push(
        EDataLayerEventCategory.PAGE_VIEW,
        EDataLayerEventAction.NAVEGACAO,
        {
          label: "area_patrocinador",
        }
      );
    };
  }, []);

  useEffect(() => {
    GetCategoriasPatrocinadores();

    if (refecthCategorias) {
      setLoading(false);
      setData((data) => {
        const categorias = refecthCategorias.categoriaPatrocinadors
          ?.data as CategoriaPatrocinadorEntity[];

        data = [...categorias];

        return data;
      });
    }
  }, [GetCategoriasPatrocinadores]);

  const criarLink = (patrocinador: PatrocinadorEntity) => {
    return `/area-do-patrocinador/${patrocinador.attributes?.titulo
      .toLowerCase()
      .replace(/[" "]/g, "-")}/${patrocinador.id}`;
  };

  const existemOesEmPatrocinadores = (categoria: CategoriaPatrocinador) => {
    return (
      categoria.patrocinadores &&
      !!categoria.patrocinadores.data.filter(
        (p) => !!p.attributes?.objeto_educacionais?.data.length
      ).length
    );
  };

  const existemOesEmPatrocinador = (patrocinador: Patrocinador) => {
    return (
      !!patrocinador.objeto_educacionais &&
      !!patrocinador.objeto_educacionais.data.length
    );
  };

  const mapPatrocinadores = (categoria: CategoriaPatrocinador) => {
    return !!categoria.patrocinadores?.data
      ? categoria.patrocinadores.data
      : [];
  };

  const PatrocinadorTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fontSize: 12,
      fontStyle: "italic",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },
  }))(Tooltip);

  return (
    <>
      <Loader
        show={loading === true}
        message="Aguarde enquanto o conteúdo é carregado..."
      />

      <div data-area-patrocinador-page>
        <HeaderSection>
          <Breadcrumb>
            <BreadcrumbItem to="/">Home</BreadcrumbItem>
            <BreadcrumbItem active>Área do Patrocinador</BreadcrumbItem>
          </Breadcrumb>
          <div className="flex items-center">
            <h3 className="mr-2 text-base text-white font-semibold leading-none md:text-xxl">
              Área do Patrocinador
            </h3>
            <PatrocinadorTooltip
              title="Este conteúdo foi desenvolvido pela Sociedade Beneficente Israelita Brasileira Albert Einstein e tem por objetivo a difusão de conhecimento técnico-científico acerca do tema apresentado, contribuindo com o desenvolvimento das atividades de saúde. A Sociedade se compromete com a transparência e com a imparcialidade do conteúdo acadêmico, livre de qualquer propósito comercial ou vinculação com contratos de aquisição de produtos de eventual apoiador ou patrocinador."
              placement="bottom-start"
            >
              <img
                className="w-4 md:flex hidden"
                src={InfoPatrocinadorBrancoHover}
                alt=""
              />
            </PatrocinadorTooltip>
          </div>
        </HeaderSection>
        <Container>
          <div className="my-12">
            {data &&
              data.map((item, idx) => (
                <div key={idx}>
                  {existemOesEmPatrocinadores(item.attributes!) && (
                    <>
                      <h3 className="text-lg font-extrabold leading-normal text-[#107B95] mt-3 md:mt-4">
                        {item.attributes?.titulo}
                      </h3>
                      {mapPatrocinadores(item.attributes!).map(
                        (patrocinador, idx2) => (
                          <div data-item-patrocinador key={idx2}>
                            {existemOesEmPatrocinador(
                              patrocinador.attributes!
                            ) && (
                              <>
                                {patrocinador.attributes?.thumbnail && (
                                  <div
                                    data-patrocinador-logo
                                    className="mt-4 mb-8"
                                  >
                                    <Link to={criarLink(patrocinador)}>
                                      <img
                                        src={criarUrl(
                                          patrocinador?.attributes?.thumbnail
                                            ?.data?.attributes?.url
                                        )}
                                        alt=""
                                        style={{
                                          height:
                                            patrocinador.attributes?.thumbnail
                                              ?.data?.attributes?.height,
                                        }}
                                      />
                                    </Link>
                                  </div>
                                )}
                                {patrocinador.attributes
                                  ?.objeto_educacionais && (
                                  <CardSection
                                    title=""
                                    items={
                                      patrocinador.attributes
                                        .objeto_educacionais.data
                                    }
                                    linkUrl={criarLink(patrocinador)}
                                    patricinadores={true}
                                    showAllSee={true}
                                    notLimit
                                  />
                                )}
                              </>
                            )}
                          </div>
                        )
                      )}
                    </>
                  )}
                </div>
              ))}
          </div>
        </Container>
      </div>
    </>
  );
};

export const criarUrl = (url?: string) => {
  url = url && !url.includes("http") ? `http://localhost:1337${url}` : url;

  return url;
};
