import React, { useContext, useEffect, useRef, useState } from "react";
import Rotulo from "../../../shared/components/rotulos";
import LogoSpotify from "../../../assets/svgs/new/logo-spotify.svg";
import LogoSpotifyRoxo from "../../../assets/svgs/new/logo-spotify-roxo.svg";
import LogoOpiniaoEspecialista from "../../../assets/svgs/new/logo-opiniao-especialista.svg";
import ReturnAudio from "../../../assets/svgs/new/return-audio.svg";
import NextAudio from "../../../assets/svgs/new/next-audio.svg";
import useOeConsume from "../../../hooks/useOeConsume";
import { formatTime } from "../../../helpers/common";
import { UserContext } from "../../../context/UserContext";
import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../../hooks/useGtm";
import { DestaqueSemanaModel } from "../../../models/DestaqueSemana";
import {
  RotuloType,
  getTagCategoriaPorTipo,
} from "../../../shared/utils/rotulo";
import { Slider } from "../../../shared/components/ui/slider";
import { CirclePause, CirclePlay } from "lucide-react";

export interface PodCastDestaqueSemanaProps {
  podcast?: DestaqueSemanaModel;
}

export default function PodCastDestaqueSemana({
  podcast,
}: PodCastDestaqueSemanaProps) {
  const { user } = useContext(UserContext);

  const gtm = useGtm();

  const [isPlaying, setIsPlaying] = useState(false);
  const [timer, setTimer] = useState(0);
  const [duration, setDuration] = useState(0);
  const [timerFormatted, setTimerFormatted] = useState<string>();
  const [durationFormatted, setDurationFormatted] = useState<string>();
  const [oeAssistido, setOeAssistido] = useState<any>(null);
  const [saveConsumeTimer, setSaveConsumeTimer] = useState(0);

  const audioElement = useRef<HTMLAudioElement>(null!);

  const consume = useOeConsume();

  useEffect(() => {
    setTimeout(() => {
      if (!audioElement.current) {
        return;
      }

      const _duration = audioElement.current.duration;
      const _currentTime = audioElement.current.currentTime;

      setTimer(_currentTime);
      setDuration(_duration);

      const _timer = formatTime(_duration);

      setTimerFormatted(_timer);
      setDurationFormatted(formatTime(_currentTime));

      consume.updateOeConsume({
        partialDuration: Math.round(_currentTime),
        totalDuration: Math.round(_duration),
      });
    }, 3000);
  }, [audioElement]);

  useEffect(() => {
    if (!audioElement) return;

    setTimeout(() => {
      if (!audioElement.current) {
        return;
      }

      audioElement.current.addEventListener("timeupdate", handleTimeUpdate);
      audioElement.current.addEventListener("canplay", handleSetDuration);

      return () => {
        audioElement.current.removeEventListener(
          "timeupdate",
          handleTimeUpdate,
        );
        audioElement.current.removeEventListener("canplay", handleSetDuration);
      };
    }, 2000);
  }, [audioElement]);

  const handleSetDuration = (e: any) => {
    const { duration, currentTime } = e.target;

    const _timer = formatTime(duration - currentTime);
    setTimerFormatted(_timer);
    setDurationFormatted(formatTime(currentTime));
    setDuration(duration);
  };

  const handleTimeUpdate = (e: any) => {
    setTimer(e.target.currentTime);
    handleSetDuration(e);
  };

  const saveConsume = () => {
    if (
      user &&
      user.id &&
      oeAssistido &&
      audioElement.current &&
      (!audioElement.current.paused || timer === duration)
    ) {
      if (oeAssistido.segundos < timer) {
        return;
      }
    }

    if (
      user &&
      user.id &&
      oeAssistido === undefined &&
      audioElement.current &&
      (!audioElement.current.paused || timer === duration)
    ) {
    }
  };

  useEffect(() => {
    const intervalUpdateConsume = setInterval(() => {
      setSaveConsumeTimer((state) => state + 1);
    }, 10000);

    return () => clearInterval(intervalUpdateConsume);
  }, []);

  useEffect(() => {
    saveConsume();
  }, [saveConsumeTimer]);

  const handlePlay = () => {
    if (audioElement.current.paused) {
      audioElement.current.play().then(async () => {});
    } else {
      audioElement.current.pause();
    }
    setIsPlaying(!audioElement.current.paused);

    gtm.push(
      EDataLayerEventCategory.ADE_OE_INTERACAO_AUDIO,
      EDataLayerEventAction.CLIQUE,
      {
        label: `iniciar-audio:${formatTime(podcast?.titulo!)}`,
      },
    );
  };

  const handleAfter = () => {
    audioElement.current.currentTime = audioElement.current.currentTime - 15;
  };

  const handleBefore = () => {
    audioElement.current.currentTime = audioElement.current.currentTime + 15;
  };

  const handleSeek = (event: any) => {
    const newTime = event;
    audioElement.current.currentTime = newTime;
  };

  return (
    <>
      {!podcast ? (
        <div className="relative h-64 w-full space-y-5 overflow-hidden rounded-2xl  bg-slate-100 md:h-[17.626rem] tablet:h-[16.25rem]">
          <div className="h-full w-full  -translate-x-full animate-[shimmer_2s_infinite] rounded-2xl bg-gradient-to-r from-transparent via-gray-400/20 to-transparent"></div>
        </div>
      ) : (
        <div className="flex h-64  w-full flex-col gap-[1rem] rounded-xl bg-white-200 p-6 md:h-[17.626rem] md:gap-5 md:p-6 tablet:!h-80 tablet:gap-3">
          {podcast?.audioVideoUrl && (
            <audio ref={audioElement} src={podcast?.audioVideoUrl} />
          )}

          <div className="flex h-auto w-full justify-between gap-1">
            <Rotulo
              rotuloName={podcast?.descricaoRotulo || ""}
              id={podcast?.idRotulo || 0}
            />
            <button
              data-testid="btn-logo-spotify"
              onClick={() => window.open(podcast?.spotify || "", "_blank")}
              className="py-0"
            >
              <img
                src={LogoSpotify}
                alt="imagem logo spotify"
                width={86} //75
                height={28} // 25
              />
            </button>
          </div>

          <div className="flex h-auto min-h-[80px] w-full flex-row gap-3 text-left md:h-[98px] tablet:!flex-col">
            {getTagCategoriaPorTipo(podcast?.descricaoRotulo || "") ===
            RotuloType.OPINIAO_ESPECIALISTA ? (
              <img
                src={LogoOpiniaoEspecialista}
                alt="logo opiniao especialista"
                className="tablet:size-14s size-20 md:size-[98px]"
              />
            ) : (
              <img
                src={LogoSpotifyRoxo}
                alt="imagem logo spotify"
                className="tablet:size-14s size-20 md:size-[98px]"
              />
            )}

            <div className="flex h-auto flex-col gap-2 ">
              {podcast?.isPatrocinado && (
                <h4 className="text-[10px] font-bold leading-[16px]  text-[#626466] tablet:text-8 tablet:leading-[9.75px]">
                  CONTEÚDO PATROCINADO
                </h4>
              )}
              <p className="text-xs font-semibold leading-[14.63px] text-black-900 md:text-sm md:leading-18 tablet:text-[10px] tablet:leading-3">
                {podcast?.titulo}
              </p>
            </div>
          </div>

          <div className="flex flex-col gap-1 md:gap-1 tablet:gap-1">
            <div className="flex h-[26px] w-full justify-between md:h-8">
              <button
                data-testid="btn-after-audio"
                className="p-0"
                onClick={handleAfter}
              >
                <img
                  src={ReturnAudio}
                  alt="botao voltar audio"
                  className="size-6 md:size-8 tablet:size-[26px]"
                />
              </button>
              <button
                data-testid="btn-play-audio"
                className="p-0"
                onClick={handlePlay}
              >
                {isPlaying ? (
                  <CirclePause className="size-6 text-black-900 md:size-7 tablet:size-[26px]" />
                ) : (
                  <CirclePlay className="size-6 text-black-900 md:size-7 tablet:size-[26px]" />
                )}
              </button>
              <button
                data-testid="btn-before-audio"
                className="p-0"
                onClick={handleBefore}
              >
                <img
                  src={NextAudio}
                  alt="botao proximo audio"
                  className="size-6 md:size-8 tablet:size-[26px]"
                />
              </button>
            </div>
            <div className="flex h-[40px] flex-col gap-[3px]">
              <div className="relative flex gap-2">
                <Slider
                  defaultValue={[audioElement?.current?.currentTime || 0]}
                  onValueChange={(value) => handleSeek(value)}
                  max={audioElement.current ? audioElement.current.duration : 0}
                  step={1}
                  className="mb-1 mt-2"
                />

                <div
                  className={`absolute bottom-0  right-0 text-[8px] font-semibold leading-none text-black-900 md:text-[0.6rem] ${`w-[${audioElement?.current?.currentTime || 0 / audioElement?.current?.duration || 0}]`}`}
                />
              </div>
              <div className="flex w-full justify-between">
                <span className="inline-block text-[8px] font-semibold leading-none text-[#111316] md:text-[0.6rem]">
                  {durationFormatted}
                </span>
                <span className="inline-block text-[8px] font-semibold leading-none text-[#111316] md:text-[0.6rem]">
                  {timerFormatted}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
