import { useState } from "react";
import { TextField, InputAdornment, IconButton, TextFieldProps } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

interface InputPasswordProps {
  formik: any;
  label?: string;
  field?: string;
}

type InputTextPropsType = TextFieldProps & InputPasswordProps;

export const ADEInputPassword: React.ComponentType<InputTextPropsType> = (props: InputPasswordProps) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextField
      id={props.field || "password"}
      name={props.field || "password"}
      type={showPassword ? "text" : "password"}
      label={props.label || "SENHA"}
      onChange={props.formik.handleChange}
      value={props.formik.values[props.field || "password"]}
      InputLabelProps={{
        shrink: true,
      }}
      error={Boolean(props.formik.touched && props.formik.errors[props.field || "password"])}
      helperText={props.formik.errors[props.field || "password"]}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}