import { useContext, useState, useEffect } from "react";
import {
  upsertUserContext,
  UserContext,
} from "../context/UserContext";
import {
  ObjetoEducacionalEntity,
  useGetOeAssistidoByIdLazyQuery,
  useUpdateUserMutation,
} from "./../generated/graphql";
import ResetUserContext from "./ResetUserContext";

const useOeVisualized = () => {
  const { user, jwt } = useContext(UserContext);

  const { resetUserContext } = ResetUserContext();
  const [oeId, setOeId] = useState("");

  const [update] = useUpdateUserMutation();

  const [GetOeAssistido] = useGetOeAssistidoByIdLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: (response) => {

      const oe = response.objetoEducacional?.data! as ObjetoEducacionalEntity;

      if (!user || !jwt) return;

      if (!oe?.attributes?.Visualizadores?.data?.some((item) => item.id === user.id)) {
        updateOeVisualized({ ...oe, id: oeId }).then(() => {
          const oes = (user?.attributes?.OesVisualizados!.data?.map((item) => ({ id: item.id })) || []);
          const userCloned: any = { ...user };
          userCloned.OesVisualizados = [...oes, { id: oeId }];
          resetUserContext(userCloned);
          upsertUserContext({ user: userCloned, jwt });
        });
      }
    },
  });

  const updateOeVisualized = (oe: ObjetoEducacionalEntity) => {

    if (!user) {
      throw new Error("Usuario nao logado para atualizar visualizacao");
    }

    return update({
      variables: {
        id: user?.id!,
        input: {
          OesVisualizados: [
            oe.id!,
            ...(user.attributes?.OesVisualizados?.data.map((item) => item.id!) || []),
          ],
        }
      },
    });
  };

  const registerOeVisualized = (id: string) => {
    if (!user || !user) {
      return;
    }

    if (user.attributes?.OesVisualizados?.data?.some((item) => item.id === id)) {
      return;
    }

    setOeId(id);
  };

  useEffect(() => {
    if (!oeId) {
      return;
    }

    GetOeAssistido({
      variables: { id: oeId },
    });
  }, [oeId, GetOeAssistido]);

  return { registerOeVisualized };
};

export default useOeVisualized;
