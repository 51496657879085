import { useEffect, useState } from "react";
import axios from "axios";
import { SmartHintEntity } from "../../../generated/smarthint";
import RecomendationCard from "../Recomendacao";
import { ObjetoEducacionalEntity } from "../../../generated/graphql";

import CustomSwiper from "../CustomSwiper";
import Container from "../Container";
import {
  Carousel,
  CarouselBullets,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../ui/carousel";

interface OesRealacionadosProps {
  oe: ObjetoEducacionalEntity;
  userID: string | undefined;
}

function Smarthint(props: OesRealacionadosProps) {
  const [recomendation, setRecomendation] = useState<SmartHintEntity>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        if (props.oe !== undefined) {
          const palavrasChave = props?.oe?.attributes?.palavras_chave;
          const autores = props?.oe?.attributes?.Autores;
          if (palavrasChave) {
            const parametros = palavrasChave?.data?.map(
              (item) => item.attributes?.titulo!,
            );
            const parametrosString = parametros.join(",");

            var stringAutor = "";
            if (autores) {
              const author = autores?.data?.map(
                (item) => item.attributes?.nome_exibicao!,
              );
              stringAutor = author.join(",");
            }

            var hiddenKeyword = "";
            hiddenKeyword =
              props?.oe?.attributes?.palavra_chave_oculta !== null &&
              props?.oe?.attributes?.palavra_chave_oculta !== undefined
                ? props.oe.attributes.palavra_chave_oculta
                : "";

            const url = `${process.env.REACT_APP_ROOT}/api/oe/smarthint/${parametrosString}/${stringAutor}/${hiddenKeyword}`;

            await axios
              .get(url)
              .then((response) => {
                setRecomendation(response.data);
                setLoading(false);
              })
              .catch((error) => {
                setLoading(false);
              });
          }
        }
      } catch (error) {
        console.error("Erro ao obter os dados:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [props.oe]);

  return (
    <div className="py-20">
      {recomendation?.Products != null &&
        recomendation?.Products?.length > 0 && (
          <Container>
            <h3 className="text-base font-semibold text-blue-900  md:text-xxl">
              Conheça os produtos do Ensino Einstein
            </h3>
            <Carousel
              opts={{
                breakpoints: {
                  "(min-width: 768px)": { slidesToScroll: 4 },
                },
              }}
              className="mt-6"
            >
              <CarouselContent className="-ml-3">
                <>
                  {recomendation?.Products?.map((item, index) => (
                    <CarouselItem
                      key={index}
                      className="md:basis-1/3 lg:basis-1/4"
                    >
                      <RecomendationCard
                        imageSrc={item.ImageLink}
                        objetoEducacional={props.oe}
                        userID={props.userID}
                        title={item.Title}
                        description={item.DescriptionADE}
                        categoryNameADE={item.CategoryNameADE}
                        buttonText="Conheça"
                        link={`${item.Link}?utm_source=academia&utm_medium=vitrine_smarthint&utm_campaign=vitrine_smarthint&utm_content=recomendacao_curso`}
                      />
                    </CarouselItem>
                  ))}
                </>
              </CarouselContent>
              <CarouselPrevious
                data-testid="btn-previous-continue-assistindo"
                height="md:h-[470px] mt-2"
                className="hidden md:block"
              />
              <CarouselNext
                data-testid="btn-next-continue-assistindo"
                height="md:h-[470px] mt-2 "
                className="hidden md:block"
              />

              <CarouselBullets />
            </Carousel>
          </Container>
        )}
    </div>
  );
}

export default Smarthint;
