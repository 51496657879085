import { useState } from "react";
import { useHistory } from "react-router";

import "./styles.scss";

import { ObjetoEducacionalEntity } from "../../../../generated/graphql";

import { ModalService } from "../../../../shared/components/Modal";

import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../../../hooks/useGtm";
import { mostrarModalCertificado } from "../AcessoDeclaracao";
import { useUtils } from "../../../../shared/utils";
import { Button } from "../../../../shared/components/ui/button";
import ParabensConcluiu from "../../../../assets/images/parabens-concluiu.png";

interface ModalConclusaoProps {
  oe: ObjetoEducacionalEntity;
}

export const useModalConclusao = (props: ModalConclusaoProps) => {
  const history = useHistory();
  const [_oe] = useState<ObjetoEducacionalEntity>(props.oe);
  const gtm = useGtm();

  const handleFechar = () => {
    ModalService.hide();
  };

  const _renderizar = () => {
    const { formatTitle } = useUtils();
    return (
      <>
        {_oe.attributes?.PossuiCertificado ? (
          <div className="flex flex-col items-center justify-center p-8">
            <img src={ParabensConcluiu} alt="" className="h-[139.36px] w-[120px] md:h-[185.82px] md:w-[160px] mt-9 mb-6" />
            <h3 className="max-w-[300px] text-center text-lg md:text-base font-bold leading-[22px] text-[#004F92] md:max-w-none">
              Parabéns, você concluiu o conteúdo!
            </h3>

            <p className="mt-3 max-w-[250px] text-center text-sm font-normal leading-normal text-[#111316] md:max-w-[350px] ">
              Acesse a sua declaração de participação: ela estará disponível na sua área de perfil sempre que precisar.
            </p>

            <div className="mt-3 flex w-full flex-col items-center justify-center gap-3 md:flex-row mb-10">
              <Button
                data-testid="btn-acessar-declaracao-modal"
                className="h-[39px] w-[215px] rounded-[30px] bg-[#0A589B] px-[28px] py-[12px] text-base font-semibold leading-3 text-[#fff] md:h-[58px]"
                onClick={() => {
                  gtm.push(
                    EDataLayerEventCategory.ADE_OE_DECLARACAO,
                    EDataLayerEventAction.CLIQUE,
                    {
                      label: formatTitle(_oe?.attributes?.Titulo || ""),
                      idOe: _oe?.id,
                    },
                  );

                  mostrarModalCertificado(
                    formatTitle(_oe.attributes?.Titulo!),
                    _oe.attributes?.TempoMedioDuracao!,
                  );
                }}
              >
                Acessar declaração
              </Button>

              <Button
                data-testid="btn-ver-depois-declaracao"
                type="button"
                variant="outline"
                className="box-border h-[39px] w-[215px] md:w-[144px] rounded-[30px] border-[1px] border-solid border-[#0A589B] px-[28px] py-[12px] text-base font-semibold leading-3 text-[#0A589B] md:h-[58px]"
                onClick={handleFechar}
              >
                Ver depois
              </Button>
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center p-8">
            <img src={ParabensConcluiu} alt="" className="h-[139.36px] w-[120px] md:h-[185.82px] md:w-[160px] mt-9 mb-6" />
            <h3 className="max-w-[300px] text-center text-lg md:text-base font-bold leading-[22px] text-[#004F92] md:max-w-none">Parabéns, você concluiu o conteúdo!</h3>
            <p className="mt-3 max-w-[250px] text-center text-sm font-normal leading-normal text-[#111316] md:max-w-[350px] ">
              Agora você já pode conferir outros conteúdos e continuar se
              atualizando.
            </p>

            <div className="mt-3 flex w-full flex-col items-center justify-center gap-3 md:flex-row mb-10">
              <Button
                data-testid="btn-ver-agora"
                type="button"
                className="h-[39px] w-[215px] rounded-[30px] bg-[#0A589B] px-[28px] py-[12px] text-base font-semibold leading-3 text-[#fff] md:h-[58px]"
                onClick={() => {
                  gtm.push(
                    EDataLayerEventCategory.ADE_OE_DECLARACAO,
                    EDataLayerEventAction.CLIQUE,
                    {
                      label: formatTitle(_oe?.attributes?.Titulo || ""),
                      idOe: _oe?.id,
                    },
                  );
                  ModalService.hide();
                  history.push("/");
                }}
              >
                Ver Agora
              </Button>

              <Button
                data-testid="btn-ver-depois-fechar"
                type="button"
                className="box-border h-[39px] w-[215px] md:w-[144px] rounded-[30px] border-[1px] border-solid border-[#0A589B] px-[28px] py-[12px] text-base font-semibold leading-3 text-[#0A589B] md:h-[58px]"
                onClick={handleFechar}
                variant="outline"
              >
                Ver depois
              </Button>
            </div>
          </div>
        )}
      </>
    );
  };

  const mostrar = () => {
    ModalService.show({
      showCloseButton: true,
      notFullScreenMobile: true,
      closeOutsideModal: true,
      content: _renderizar(),
    });
  };

  return { mostrar };
};
