import React from "react";
import { Link, useHistory } from "react-router-dom";

import * as Icon from "../../../assets/svgs";

import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import { CardData } from "../CardAreaResidenciaMedica";
import { CardSwiperResidenciaMedica } from "../CardSwiperResidenciaMedica";
import { ResidenciaMedicaEntity } from "../../../generated/graphql";

interface CardSectionResidenciaMedicaProps {
  title: string;
  icon?: string | any;
  linkUrl?: string;
  onClickVerMais?: () => void;
  items: ResidenciaMedicaEntity[];
  showAllSee: boolean;
  loading?: boolean;
  hasTitle?: boolean;
}

export const CardSectionResidenciaMedica: React.FC<
  CardSectionResidenciaMedicaProps
> = ({
  title,
  icon,
  linkUrl,
  onClickVerMais,
  items,
  showAllSee,
  loading,
  hasTitle,
}) => {
  const history = useHistory();

  return (
    <>
      <div>
        {!hasTitle ? (
          <div
            className={`flex w-full flex-row justify-between text-xxl font-semibold normal-case leading-none text-blue-200`}
          >
            <div className="flex items-center justify-between">
              {icon ? (
                <img src={icon} alt="" className="mb-3 mr-3 mt-8 md:mt-14" />
              ) : null}
              {linkUrl && (
                <Link
                  to={`${linkUrl}`}
                  className="inline-block self-center leading-none hover:text-blue-200"
                >
                  <h3 className="mb-3 mt-8 inline-block self-center whitespace-pre-line text-base font-semibold leading-none hover:text-blue-200 md:mt-14 md:text-xxl">
                    {title}
                  </h3>
                </Link>
              )}
              {!linkUrl && (
                <h3 className="mb-3 mt-8 inline-block self-center  whitespace-pre-line text-base font-semibold leading-none hover:text-blue-200 md:mt-14 md:text-xxl ">
                  {title}
                </h3>
              )}
            </div>

            <div
              className={twMerge(
                "flex h-auto items-end justify-end text-[10px] font-bold leading-none text-[#626466] md:text-ssm",
                true && "mb-3 mt-8 md:mt-4",
              )}
            >
              {(linkUrl || onClickVerMais) && items && items.length > 4 && (
                <span
                  onClick={() =>
                    onClickVerMais ? onClickVerMais?.() : history.push(linkUrl!)
                  }
                  className={classNames(
                    "inline-block cursor-pointer hover:text-slate-700",
                  )}
                >
                  VER TUDO
                </span>
              )}
            </div>
          </div>
        ) : null}
        <CardSwiperResidenciaMedica loading={loading} items={items} />
      </div>
    </>
  );
};
