import { useEffect, useContext, useState } from "react";
import * as Yup from "yup";

import { UserContext } from "../context/UserContext";

export const useValidacaoDadosUsuario = () => {
  const { user } = useContext(UserContext);
  const [validadoDadosUsuario, setValidadoDadosUsuario] = useState(true);

  useEffect(() => {
    if (!user) {
      return;
    }

    validarDados();
  }, [user]);

  const validarDados = () => {
    const schema = Yup.object().shape({
      nome_completo: Yup.string()
        .required("Campo obrigatório")
        .min(3, "Quantidade mínima de caracteres não atingida")
        .max(255, "Quantidade máxima de caracteres ultrapassada")
        .test({
          message: "Campo não permite valor numérico",
          test: (value) => {
            return !!value && !/[0-9]/.test(value);
          },
        })
        .test({
          name: "nome_completo",
          message: "Nome e sobrenome são obrigatórios",
          test: (value) => {
            return !!value && value.trim().split(" ").length >= 2;
          },
        }),
      email: Yup.string().required("Campo Obrigatorio"),
      cpf: Yup.string().when("toogleDocumentType", {
        is: false,
        then: Yup.string().required("*Campo obrigatório"),
      }),
      celular: Yup.string().required("Campo Obrigatorio"),
      PerfilProfissao: Yup.object().shape({
        data: Yup.array().test({
          message: "Campo Obrigatorio",
          test: (value: any) => value && !!value.length,
        }),
      }),
      area_de_conhecimentos: Yup.object().shape({
        data: Yup.array().test({
          message: "Campo Obrigatorio",
          test: (value: any) => value && !!value.length,
        }),
      }),
      possui_deficiencia: Yup.string().required("Campo Obrigatorio"),
    });

    const userData = !user
      ? undefined
      : {
          ...user,
          toogleDocumentType: !user.attributes?.cpf,
          documentNumber: !user.attributes?.documentNumber
            ? ""
            : user.attributes?.documentNumber,
          documentType: !user.attributes?.documentType
            ? ""
            : user.attributes?.documentType,
        };

    schema
      .isValid(userData?.attributes)
      .then((response) => {
        setValidadoDadosUsuario(response);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  return { validadoDadosUsuario };
};
