import { useEffect, useState } from "react";
import { ParametrosBuscaModel } from "../../models/ParametroBuscaModel";
import { fetchApi } from "../services/rest-dotnet.service";
import Filters, { TypeFilter } from "./filters";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { OrderType } from "../../models/orderType";
import qs from "qs";
import { useHistory, useParams } from "react-router";
import React from "react";

async function getParametros(): Promise<ParametrosBuscaModel> {
  const response = await fetchApi("busca/get-parametros", "GET").then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      return {
        areaDeInteresses: [],
        metodosDeEnsino: [],
        niveisMaturidade: [],
        profissoes: [],
        tipoMidia: [],
      };
    }
  });

  return response;
}

interface MenuProps {
  qtdConteudos?: number;
  typeFiltro?: TypeFilter;
}

export default function Menu({
  qtdConteudos,
  typeFiltro = "SEARCH",
}: MenuProps) {
  const [parametros, setParametros] = useState<ParametrosBuscaModel>();
  const [order, setOrder] = useState<OrderType>(
    typeFiltro !== "AREA_INTERESSE_USUARIO"
      ? OrderType.MaisRecente
      : OrderType.Crescente,
  );
  const history = useHistory();

  const searchParams = useParams<{
    a?: string;
    p?: string;
    m?: string;
    n?: string;
    t?: string;
    s?: string;
  }>();

  const loadParametros = async () => {
    setParametros(await getParametros());
  };

  useEffect(() => {
    loadParametros();
  }, []);

  const handleOnChangeOrder = (value: string) => {
    const params = {
      ...searchParams,
      s: value,
    };
    const queryString = qs.stringify(
      { ...params },
      {
        arrayFormat: "repeat",
        encodeValuesOnly: true,
        encode: true,
      },
    );

    // eslint-disable-next-line no-restricted-globals
    history.push(`${location.pathname}?${queryString}`);
    setOrder(value as any as OrderType);
  };

  console.log("qtdConteudos", qtdConteudos);

  return (
    <div className="flex w-full flex-col justify-end gap-6 md:justify-center">
      <div className="flex h-12 w-full items-start justify-between md:items-center">
        <span className="w-[127px] !text-[0.937rem] !font-semibold !leading-18 text-[#111316] md:w-auto">
          {`${qtdConteudos || 0} conteúdos disponíveis`}
        </span>

        <div className="flex flex-col items-end justify-end gap-6 md:flex-row-reverse">
          <Filters
            parametros={parametros}
            order={order}
            onChangeOrder={(order) => setOrder(order)}
            typeFilter={typeFiltro}
          />
          <div className="flex justify-end">
            <Select
              onValueChange={handleOnChangeOrder}
              defaultValue={order.toString()}
            >
              <SelectTrigger className="max-w-[230px] !border-none !text-sm !font-medium text-[#111316]">
                <SelectValue placeholder="Ordenar Por:" />
              </SelectTrigger>
              <SelectContent className="w-auto bg-white-200  ">
                <SelectGroup className="">
                  <SelectLabel>Ordenar Por:</SelectLabel>
                  {typeFiltro !== "AREA_INTERESSE_USUARIO" && (
                    <>
                      <SelectItem value={OrderType.MaisRecente.toString()}>
                        Mais recente
                      </SelectItem>
                      <SelectItem value={OrderType.MaisAntigo.toString()}>
                        Mais antigo
                      </SelectItem>
                    </>
                  )}
                  <SelectItem value={OrderType.Crescente.toString()}>
                    Ordem alfabética A-Z
                  </SelectItem>
                  <SelectItem value={OrderType.Decrescente.toString()}>
                    Ordem alfabética Z-A
                  </SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        </div>
      </div>
    </div>
  );
}
