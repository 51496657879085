import React, { useEffect, useState } from "react";

import QueryTopics from "../../graphql/collections/queries/topic.query";

import { Topic } from "../../shared/interfaces/topic.interface";
import { If } from "../../shared/components/If";

import "./styles.scss";

export const TopicosPage: React.FC = () => {
  const [topics, setTopics] = useState<any>({});

  useEffect(() => {
    QueryTopics().then((response) => {
      setTopics(response.data);
    });
  }, []);

  return (
    <div>
      <h1 className="topics__areas">TODAS AS ÁREAS DE ATUAÇÃO</h1>
      {Object.keys(topics).map((k) => {
        const list: Topic[] = topics[k] as Topic[];
        return (
          <>
            <If test={list.length > 0}>
              <div className="topics__container">
                <h2 className="topics__title">{k.toUpperCase()}</h2>
                <ul className="topic__item">
                  {list.map((item: Topic) => (
                    <li className="topic__list">{item.titulo}</li>
                  ))}
                </ul>
              </div>
            </If>
          </>
        );
      })}
    </div>
  );
};
