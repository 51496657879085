import React from 'react';
import { RespostaModel } from '../../../../models/RespostaModel';

export interface ButtonActionsQuizProps {
  finalizou: boolean;
  ultimaPerguntaQuiz: boolean;
  showingQuestionFeedback: boolean;
  perguntaAtual: number;
  perguntaTotais: number;
  resposta: RespostaModel;
  isTesteConhecimento?: boolean;
  onClickRefazer: () => void;
  onClickVerResultado: () => void;
  onClickPular: () => void;
  onClickProximaPergunta: () => void;
}


export const ButtonActionsQuiz: React.FC<ButtonActionsQuizProps> = ({
  finalizou,
  ultimaPerguntaQuiz,
  showingQuestionFeedback,
  perguntaAtual,
  perguntaTotais,
  resposta,
  isTesteConhecimento,
  onClickRefazer,
  onClickVerResultado,
  onClickPular,
  onClickProximaPergunta,
}) => {
  return (
    <div className="flex w-full justify-center gap-2 my-7 md:mt-8">
      {(finalizou) && (
        <button
          data-testid="btn-refazer-quiz"
          style={{
            border: "2px solid #004F92",
            display: finalizou ? "" : "none",
          }}
          className="flex justify-center items-center w-[164px] h-[52px] px-[14.046px] py-[11.954px] flex-shrink-0 !bottom-2 border-[#004f92] hover:bg-[#005bab50]
            text-[#004f92] text-base font-medium leading-4 rounded-3xl"
          onClick={() =>
            onClickRefazer()
          }
        >
          {"Refazer"}
        </button>
      )}
      {isTesteConhecimento && ultimaPerguntaQuiz && (
        <button
          data-testid="btn-ver-resultados-quiz"
          style={{
            border: "2px solid #004F92",
            display: showingQuestionFeedback ? "" : "none",
          }}
          className="flex justify-center items-center w-[164px] h-[52px] px-[14.046px] py-[11.954px] flex-shrink-0 bg-[#004F92] hover:bg-[#005AAB]
            text-white text-base font-medium leading-4 rounded-3xl"
          onClick={() => onClickVerResultado()}
        >
          {"Ver resultados"}
        </button>
      )}
      {isTesteConhecimento && (
        <>
          <button
            data-testid="btn-pular-quiz"
            style={{
              display:
                !showingQuestionFeedback && perguntaAtual < perguntaTotais - 1
                  ? ""
                  : "none",
            }}
            className="flex justify-center items-center w-[164px] h-[52px] px-[14.046px]
          py-[11.954px] flex-shrink-0 !border-2 !border-[#004F92] hover:bg-[#005bab50]
         text-[#004F92] border-solid text-base font-medium leading-4 rounded-3xl"
            onClick={() => onClickPular()}
          >
            Pular
          </button>

          {!ultimaPerguntaQuiz && showingQuestionFeedback && (
            <button
              data-testid="btn-proxima-quiz"
              style={{
                display:
                  ultimaPerguntaQuiz &&
                    showingQuestionFeedback
                    ? "none"
                    : "",
              }}
              className={`flex justify-center items-center w-[164px] h-[52px] px-[14.046px] py-[11.954px] flex-shrink-0 ${resposta?.id ? 'bg-[#004F92] hover:bg-[#005AAB] text-white' : 'bg-[#E7E9ED] border-[#E7E9ED] text-[#B6BCC9]'} text-base font-medium leading-4 rounded-3xl`}
              onClick={() => onClickProximaPergunta()}
            >
              {"Próxima pergunta"}
            </button>
          )}
        </>
      )}

      {!showingQuestionFeedback && (
        <button
          data-testid="btn-responder-quiz"
          className={`flex justify-center items-center w-[164px] h-[52px] px-[14.046px] py-[11.954px] flex-shrink-0 ${resposta?.id ? 'bg-[#004F92] hover:bg-[#005AAB] text-white' : 'bg-[#E7E9ED] border-[#E7E9ED] text-[#B6BCC9]'} text-base font-medium leading-4 rounded-3xl`}
          onClick={() => onClickProximaPergunta()}
        >
          {"Responder"}
        </button>
      )}

    </div>
  );
}
