import React from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../redux/app";
import { useScrollTop } from "../../hooks/useScrollTop";
import { EModalActions } from "./model.reducer";
import Close from "../../../assets/svgs/close.svg";

import "./styles.scss";
import classNames from "classnames";

interface IModalServiceAPIs {
  show: (config: {
    showComponentInFullScreen?: boolean;
    showCloseButton?: boolean;
    content: JSX.Element;
    onConfirm?: (...args: any[]) => any;
    onClose?: (...args: any[]) => any;
    data?: any;
    closeOutsideModal?: boolean;
    notFullScreenMobile?: boolean;
  }) => void;
  hide: (...args: any[]) => void;
  closeMe: (...args: any[]) => void;
}

let ModalService = {} as IModalServiceAPIs;

const Modal: React.FC = () => {
  const state = useSelector((state: RootState) => state.modalReducer);
  const dispatch = useDispatch();
  const scrollTop = useScrollTop();

  /**
   * Serviço para exibir o modal
   */
  ModalService.show = (config) => {
    scrollTop();
    dispatch({
      type: EModalActions.SHOW,
      payload: {
        content: config.content,
        showComponentInFullScreen: config.showComponentInFullScreen || false,
        notFullScreenMobile: config.notFullScreenMobile || false,
        showCloseButton: config.showCloseButton,
        onConfirm: config.onConfirm || (() => {}),
        onClose: config.onClose || (() => {}),
        data: config.data,
        closeOutsideModal: config.closeOutsideModal,
      },
    });
  };

  /**
   * Serviço para ocultar o modal
   */
  ModalService.hide = (data?: any) => {
    dispatch({
      type: EModalActions.HIDE,
      payload: {
        content: "",
        showComponentInFullScreen: false,
        data,
      },
    });
  };

  const closeMe = () => {
    if (!!state.closeOutsideModal) {
      state.onClose && state.onClose();
      ModalService.hide();
    }
  };

  const modal = (
    <>
      <div data-dimmer onClick={closeMe}></div>
      <div
        data-modal
        className={classNames({
          fullScreen: state.showComponentInFullScreen,
          notFullScreenMobile: !state.notFullScreenMobile,
        })}
      >
        {state.showCloseButton && (
          <div data-modal-close onClick={closeMe}>
            <img src={Close} alt="" />
          </div>
        )}
        <div>{state.content}</div>
      </div>
    </>
  );

  return state.show ? ReactDOM.createPortal(modal, document.body) : null;
};

export default Modal;
export { ModalService };
