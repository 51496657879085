import React, { useEffect, useState } from "react";
import {
  MenusRodapeFragment,
  ComponentMenusRodapeMenusRodape,
  useGetRodapePortalQuery,
  GetRodapePortalQuery,
  ItensRodapeEntity,
} from "../../generated/graphql";

import { If } from "../../shared/components/If";
import FooterMobile from "./components/FooterMobile";
import Logo from "../../assets/images/logo-einstein-white.png";

import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../hooks/useGtm";

import "./styles.scss";
import Container from "../../shared/components/Container";
import { fetchApi } from "../../shared/services/rest-dotnet.service";
import { RodapeModel } from "../../models/RodapeModel";
import { ItemRodapeModel } from "../../models/ItemRodapeModel";

export const FooterLinks: React.FC = () => {
  const [data, setData] = useState<RodapeModel>();
  const gtm = useGtm();

  const loadRodape = () => {
    fetchApi("rodape/get", "GET").then((body) => {
      body.json().then((resultado: RodapeModel) => {
        setData(resultado);
      });
    });
  };

  useEffect(() => {
    loadRodape();
  }, []);

  const bindingGroup = (_menus: ItemRodapeModel) => {
    if (!_menus) return [];

    return (
      <div data-footer-group-items>
        <ul>
          {_menus.itensRodape.map((group, index) => (
            <li key={index}>
              <a
                data-footer-link
                target="blank"
                href={group?.path}
                onClick={() => {
                  // GTM doc linha 62
                  gtm.push(
                    EDataLayerEventCategory.ADE_RODAPE_HOME,
                    EDataLayerEventAction.CLIQUE,
                    {
                      label: group.nome,
                    },
                  );
                }}
              >
                {group?.nome}
              </a>
              {group.contato && (
                <div data-footer-contacts>
                  <span>{group.contato}</span>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    );
  };
  const renderDesktop = (data: RodapeModel | undefined) => {
    return (
      <Container>
        <div data-footer-items data-flex>
          {data?.itensRodape.map((item, index) => {
            if (index === data.itensRodape.length - 1) {
              return <React.Fragment key={index}></React.Fragment>;
            }
            return (
              <div data-col-3 key={index}>
                <div data-footer-group>
                  <div data-footer-group-title>{item?.nome}</div>
                  {item?.itensRodape && bindingGroup(item)}
                </div>
                {index === 0 && (
                  <div data-logo>
                    <img src={Logo} width="203px" alt="" />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </Container>
    );
  };

  const _props: any = {
    data,
  };

  return (
    <>
      <div data-footer-menu>{renderDesktop(data)}</div>
      {<FooterMobile {..._props} />}
    </>
  );
};

export const sortMenus = (menus: ComponentMenusRodapeMenusRodape[]) => {
  const _menus = [...menus.map((item) => ({ ...item }))];

  _menus.sort((a, b) => {
    if (a.ordem! < b.ordem!) {
      return -1;
    }

    if (a.ordem! > b.ordem!) {
      return 1;
    }

    return 0;
  });

  return _menus;
};

export const getIconUrl = (item: MenusRodapeFragment) => {
  let _url = "";

  if (!!item && !!item.icone && !!item.icone.data?.attributes?.url.length) {
    _url = item.icone.data.attributes.url;
  }

  return _url;
};
