import { TOKEN_SECRECT } from '../constants/auth-types';

export const getLocalStorageValue = (key: string) => localStorage.getItem(key);
export const setLocalStorageValue = (key: string, value: string) => localStorage.setItem(key, value);
export const removeLocalStorageValue = (key: string) => localStorage.removeItem(key);

export const isAuthenticated = () => {
    const token = getLocalStorageValue(TOKEN_SECRECT);
    return !!token;
}

export const getSessionStorage = (key: string) => sessionStorage.getItem(key);
export const setSessionStorage = (key: string, value: string) => sessionStorage.setItem(key, value);