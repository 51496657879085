import React from "react";

import BoasVindas from "../../../../assets/svgs/boasvindas.svg";

import "./styles.scss";
import { Button } from "../../../../shared/components/ui/button";

interface props {
  origin?: string;
}

export const ModalRegisterUserSucceedV2: React.FC<props> = ({ origin }) => {

  return (
    <div className="flex flex-col items-center justify-center gap-3 rounded-xl border p-2 text-center">
      <div className="flex flex-col mx-10 my-9 md:mx-9 md:my-8 w-[250px] md:w-[350px] items-center justify-center">
        <img src={BoasVindas} alt="" className="h-[100px] w-[118.52px]" />
        <div className="text-lg md:text-base font-bold leading-22 mt-6 mx-2 text-[#004F92]">
          Cadastro concluído, boas-vindas!
        </div>
        <div className="text-base md:text-sm font-normal mt-3 leading-5 text-[#313335]">
          Agora você tem acesso aos conteúdos da Academia Digital Einstein.
        </div>
      </div>
    </div>
  );
};
