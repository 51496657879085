import { ptBR } from "date-fns/locale";
import {
  ObjetoEducacionalEntity,
  UsersPermissionsUserEntity,
  useGetQuizzesLazyQuery,
  useGetQuizzesQuery,
} from "../../../../generated/graphql";
import {
  Breadcrumb,
  BreadcrumbItem,
  Favoritar,
  Loader,
  RotuloCategoria,
} from "../../../../shared/components";
import Container from "../../../../shared/components/Container";
import { format } from "date-fns";
import "./styles.scss";

interface OeTesteConhecimento {
  oe: ObjetoEducacionalEntity;
  user?: UsersPermissionsUserEntity;
}

export default function OeTesteConhecimentoPreview({
  oe,
  user,
}: OeTesteConhecimento) {
  //  quizzes(filters: { id: { in: $ids } }) {

  const { data, loading } = useGetQuizzesQuery({
    fetchPolicy: "network-only",
    variables: {
      ids: oe.attributes?.Quizzes?.data.map((item) => item.id!) || [],
    },
  });

  const RotuloDesktop = () => {
    return (
      <>
        <div data-flex className=" mt-3 md:mt-7 justify-between">
          {oe.attributes?.Rotulos &&
            oe?.attributes?.Rotulos.data.map((rotulo, idx) => (
              <RotuloCategoria
                key={idx}
                data={rotulo.attributes?.titulo || ""}
              />
            ))}
          <Favoritar
            className="w-4 h-4 self-center cursor-pointer"
            color="green"
            idOe={oe?.id}
            item={{
              id: oe?.id!,
              titulo: oe?.attributes?.Titulo!,
            }}
          />
        </div>
      </>
    );
  };

  const InfoQuiz = () => {
    return (
      <>
        <span className="text-xs font-medium leading-4 text-[#111316] inline-block !mt-3">
          {oe?.attributes?.TipoObjeto?.data.map((tipo, idx) => (
            <span key={idx}>{tipo.attributes?.titulo}</span>
          ))}
          {` · ${oe?.attributes?.TempoMedioDuracao} minutos · ${format(
            new Date(oe?.attributes?.DataProducao + "T00:00:00"),
            "dd MMM yyyy",
            {
              locale: ptBR,
            }
          )?.toLowerCase()}`}
        </span>
      </>
    );
  };

  const GetBreadCrumb = () => {
    return (
      <Breadcrumb>
        <BreadcrumbItem to="/">Home</BreadcrumbItem>
        <BreadcrumbItem to="/metodos-ensino">Métodos de ensino</BreadcrumbItem>
        <BreadcrumbItem active>Teste seus conhecimentos</BreadcrumbItem>
      </Breadcrumb>
    );
  };

  const Recomendado = () => {
    return (
      <>
        <div
          data-flex
          data-recommended
          className="text-xs font-medium text-[#111316] leading-4 mt-2"
        >
          {!!oe?.attributes?.PublicoAlvo &&
            !!oe?.attributes?.PublicoAlvo.data.length && (
              <>
                Recomendado para:{" "}
                {oe.attributes?.PublicoAlvo?.data
                  ?.filter((tag) => tag)
                  .map((tag: any) => tag.attributes.titulo)
                  .join(", ")}
              </>
            )}
        </div>
      </>
    );
  };

  const Titulo = () => {
    return (
      <h2 className="normal-case mt-2">
        <span className="font-semibold text-base md:text-xxl !leading-none text-[#004F92] ">
          {data?.quizzes?.data[0]?.attributes?.nome}
        </span>
      </h2>
    );
  };

  const Enunciado = () => {
    return (
      <>
        {data?.quizzes?.data[0].attributes?.enunciado ? (
          <div className="!mt-5 md:mt-6 mb-8">
            <h4
              data-line-height="1-2"
              className="text-[#111316] md:!text-sm !text-base !font-normal !leading-[19.5px] !font-[Montserrat]"
              dangerouslySetInnerHTML={{
                __html:
                  data?.quizzes?.data[0].attributes?.enunciado?.replaceAll(
                    "font-size:",
                    "fonte"
                  ),
              }}
            />
          </div>
        ) : null}
      </>
    );
  };

  const LegendaImagem = (color?: string) => {
    return (
      <>
        {data?.quizzes?.data[0]?.attributes?.legenda_preview_imagem && (
          <span
            className={`font-medium text-[${
              color || "#111316"
            }] text-xs leading-4 inline-block`}
          >
            {data?.quizzes?.data[0]?.attributes?.legenda_preview_imagem}
          </span>
        )}
      </>
    );
  };

  return (
    <Container>
      <Loader
        show={loading}
        message="Aguarde enquanto o conteúdo é carregado..."
      />
      <section className="flex w-full md:w-[752px] flex-col mx-auto quizz text-[#111316]">
        <header>
          {/* {GetBreadCrumb()} */}
          {RotuloDesktop()}
          {InfoQuiz()}
          {Recomendado()}
          {Titulo()}
          {Enunciado()}
        </header>

        {data?.quizzes?.data?.some(
          (quiz) => quiz.attributes?.preview_imagem?.data?.attributes?.url
        ) && (
          <section className="mt-3 md:!mt-6">
            {data?.quizzes?.data?.map((quiz, idx) => (
              <img
                src={quiz.attributes?.preview_imagem?.data?.attributes?.url}
                width="100%"
                className="object-fill object-center max-h-[300px] md:max-h-[500px] md:min-h-[500px] bg-no-repeat rounded-xl"
                alt=""
              />
            ))}
            {LegendaImagem()}
          </section>
        )}

        <div className="flex w-full justify-center my-9">
          <div
            onClick={() => {
              window.location.href = `/oe/${oe.id}/quiz/${data?.quizzes?.data[0]?.id}`;
            }}
            className="bg-[#0A589B] hover:bg-[#0a579bc5]
            w-[150px] h-[40px] md:w-[186px] flex md:h-[52px] justify-center items-center gap-2 rounded-[36px] cursor-pointer"
          >
            <span className="text-center text-white text-sm md:text-base font-semibold tracking-tight inline-block select-none">
              Começar o teste
            </span>
          </div>
        </div>
      </section>
    </Container>
  );
}
