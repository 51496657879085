import React, { useState, useEffect, useContext } from "react";

import { ObjetoEducacionalEntity } from "../../../../generated/graphql";

import { CardSection } from "../../../../shared/components";

import { UserContext } from "../../../../context/UserContext";
import { useUtils } from "../../../../shared/utils";

export const CarroselDeclaracao: React.FC = () => {
  const [oes, setOes] = useState<any[]>([]);
  const [oesCertificadas, setOesCartificadas] = useState<any[]>([]);
  const [loadingCertificados, setLoadingCertificados] =
    useState<boolean>(false);
  const { user, jwt } = useContext(UserContext);

  useEffect(() => {
    if (!user) {
      return;
    }

    fetch(
      `${process.env.REACT_APP_ROOT}/api/objeto-educacionals/certificados`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    )
      .then((resposta) => {
        return resposta.json();
      })
      .then((resposta) => {
        setOes(resposta);
      });
  }, [user]);

  useEffect(() => {
    if (oes) {
      const resultOesVideos = oes?.filter(
        (oe) =>
          oe.VideoAudio &&
          oe.VideoAudio.length > 0 &&
          oe.OesAssistidos &&
          oe.OesAssistidos.length > 0 &&
          oe.OesAssistidos.find((item: any) => item.duracao === item.segundos)
      );

      // .filter((item) => item.OesAssistidos?.length === item.VideoAudio?.length);

      const resultOesTextos = oes?.filter(
        (oe) =>
          oe.SecoesTexto &&
          oe.SecoesTexto.length > 0 &&
          oe.OesAssistidos &&
          oe.OesAssistidos.length > 0 &&
          oe.OesAssistidos.find((item: any) => item?.duracao === item?.segundos)
      );

      const resultOesInfograficos = oes?.filter(
        (oe) =>
          oe.Infografico &&
          oe.Infografico.length > 0 &&
          oe.OesAssistidos &&
          oe.OesAssistidos.length > 0 &&
          oe.OesAssistidos.find((item: any) => item.duracao === item.segundos)
      );

      setOesCartificadas([
        ...resultOesVideos,
        ...resultOesTextos,
        ...resultOesInfograficos,
      ]);
    }
  }, [oes]);

  useEffect(() => {
    if (!!oesCertificadas) {
      setLoadingCertificados(true);
    }
  }, [oesCertificadas]);

  const { formatTitle } = useUtils();

  return (
    <>
      {!!oesCertificadas && !!oesCertificadas.length && (
        <div>
          <CardSection
            title="Declarações de participação"
            items={
              oesCertificadas.map((a) => ({
                  id: a.id,
                  attributes: {
                    Descricao: a.Descricao,
                    Titulo: formatTitle(a.Titulo!),
                    ImagemCapa: {
                      data: {
                        attributes: {
                          ...a.ImagemCapa,
                        },
                      },
                    },
                    ImagemPreview: {
                      data: {
                        attributes: {
                          ...a.ImagemPreview,
                        },
                      },
                    },
                    Rotulos: {
                      data: a.Rotulos?.map((r: any) => ({
                        id: r.id,
                        attributes: {
                          ...r,
                        },
                      })),
                    },
                    TipoObjeto: {
                      data: a.TipoObjeto?.map((r: any) => ({
                        id: r.id,
                        attributes: {
                          ...r,
                        },
                      })),
                    },
                    SubRotulos:
                      a?.SubRotulos && a.SubRotulos.length > 0
                        ? {
                          data: a?.SubRotulos?.map((r: any) => ({
                            id: r.id,
                            attributes: {
                              ...r,
                            },
                          })),
                        }
                        : [],
                    patrocinador: {
                      data: {
                        id: a.patrocinador?.id,
                        attributes: {
                          ...a.patrocinador,
                        },
                      }
                    },
                    Resumo: '',
                    VideoAudio: a.VideoAudio,
                    Infografico: a.Infografico,
                    PossuiCertificado: a.PossuiCertificado,
                    TempoMedioDuracao: a.TempoMedioDuracao,
                  },
                })) as ObjetoEducacionalEntity[]
            }
            certificados={false}
            isListCertificados={true}
            showAllSee={true}
          ></CardSection>
        </div>
      )}
    </>
  );
};
