import React, {
  createRef,
  RefObject,
  SyntheticEvent,
  useEffect,
  useState,
  useContext,
} from "react";
import { useDispatch } from "react-redux";
import HtmlParse from "html-react-parser";
import { Tooltip } from "@material-ui/core";

import {
  ComponentSecaoTextSecaoTexto,
  ObjetoEducacionalEntity,
  UsersPermissionsUserEntity,
} from "../../../../../generated/graphql";

import { BarraZoom } from "../../../components/BarraZoom";
import { OEBackButton, BannerSection } from "../../../../../shared/components";

import { useModalConclusao } from "../../../components/ModalConclusao";
import { HEADER_ACTION_TYPE } from "../../../../../shared/constants/action-types";

import useOeConsume from "../../../../../hooks/useOeConsume";

import {
  TIPO_AREA_PAGINA,
  TIPO_PAGINA,
} from "../../../../../hooks/useBannerApi";

import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../../../../hooks/useGtm";
import { criarTituloSecaoTexto } from "../../../OeInfograficoPage/components/OeInfograficoTexto";

import { UserContext } from "../../../../../context/UserContext";

import "./styles.scss";
import { useUtils } from "../../../../../shared/utils";
import {
  Banner,
  loadBanner,
} from "../../../../../graphql/collections/queries/banner.query";
import { Button } from "../../../../../shared/components/ui/button";

interface TextOEContentProps {
  oe: ObjetoEducacionalEntity;
  user?: UsersPermissionsUserEntity;
}

interface TextOEContentState {
  currentZoom: number;
  currentStep: number;
  headingRefs: RefObject<HTMLHeadingElement>[];
  refTops: number[];
}

export const TextOEContent: React.FC<TextOEContentProps> = (props) => {
  const dispatch = useDispatch();
  const consume = useOeConsume();
  const gtm = useGtm();
  const { user } = useContext(UserContext);

  const { mostrar: mostrarModalConclusao } = useModalConclusao({
    oe: props.oe,
  });

  const [showBanner, setShowBanner] = useState(true);
  const [isOeConcluida, setOeConcluida] = useState(false);

  const [state, setState] = useState<TextOEContentState>({
    currentZoom: 1,
    currentStep: 0,
    headingRefs: [] as RefObject<HTMLHeadingElement>[],
    refTops: [],
  });

  const [headingRefs, setHeadingRefs] = useState<
    RefObject<HTMLHeadingElement>[]
  >([]);

  const [banners, setBanners] = useState<Banner[]>([]);

  const carregarBanner = async () => {
    const resultBanner = await loadBanner({
      homePage: false,
      objetoEducacionalId: props?.oe.id! ? Number(props?.oe.id!) : undefined,
      areaDeConhecimentoId:
        props?.oe.attributes?.SubAreaConhecimento?.data
          ?.filter((sub) => {
            return !!sub.attributes?.area_de_conhecimento?.data?.id;
          })
          ?.map((a) => Number(a.attributes?.area_de_conhecimento?.data?.id!)) ||
        [],
      subAreaDeConhecimentoId:
        props?.oe.attributes?.SubAreaConhecimento?.data?.map((a) =>
          Number(a.id!),
        ) || [],
      publicoAlvoId:
        props?.oe.attributes?.PublicoAlvo?.data?.map((a) => Number(a.id!)) ||
        [],
    });

    setBanners(resultBanner);
  };

  useEffect(() => {
    carregarBanner();
  }, [props.oe]);

  const scrollable = createRef<HTMLDivElement>();

  useEffect(() => {
    const _headingRefs: RefObject<HTMLHeadingElement>[] = [];

    props.oe.attributes?.SecoesTexto?.forEach((text, index) => {
      _headingRefs[index] = createRef<HTMLHeadingElement>();
    });

    setHeadingRefs(_headingRefs);
  }, []);

  useEffect(() => {
    if (!!state.refTops.length) {
      return;
    }

    const refTops: number[] = [];

    headingRefs.forEach((ref: React.RefObject<HTMLHeadingElement>, index) => {
      refTops.push(ref.current!?.offsetTop);
    });
    setState({ ...state, refTops });
  }, [headingRefs]);

  useEffect(() => {
    if (!props.user || !props.oe) {
      return;
    }

    const oeAssitido = (
      props.user.attributes?.OesConsumidosParcialmente?.data || []
    ).find(
      (item) =>
        !!item &&
        !!item.attributes?.LeituraOeId &&
        !!props.oe.attributes?.SecoesTexto &&
        !!props.oe.attributes?.SecoesTexto.length &&
        props.oe.attributes?.SecoesTexto.some(
          (leitura) =>
            leitura.id.toString() === item.attributes?.LeituraOeId!.toString(),
        ),
    );

    if (!!oeAssitido) {
      setOeConcluida(true);
    }
  }, [props.user, props.oe]);

  const { formatTitle } = useUtils();

  const handleScroll = (e: SyntheticEvent) => {
    const { scrollTop, scrollHeight, clientHeight } =
      e.target as HTMLTextAreaElement;

    const nextHeading = state.refTops[state.currentStep + 1];
    const lastIndexHeading = state.refTops.length - 1;
    const actualHeading = state.refTops[state.currentStep];

    if (
      scrollTop > nextHeading - 50 ||
      scrollHeight - scrollTop === clientHeight
    ) {
      if (state.currentStep < lastIndexHeading) {
        setState({ ...state, currentStep: state.currentStep + 1 });
      }
    } else if (scrollTop < actualHeading) {
      setState({ ...state, currentStep: state.currentStep - 1 });
    }

    if (scrollTop > scrollHeight - clientHeight - 250) {
      setState({ ...state, currentStep: state.refTops.length - 1 });
      for (var _i = 0; _i < state.refTops.length; _i++) {}
    }
  };

  const navigateTo = (index: number) => {
    scrollToHeading(index);

    // GTM doc linha 89
    gtm.push(
      EDataLayerEventCategory.ADE_OE_PAGINA,
      EDataLayerEventAction.CLIQUE,
      {
        label: `iniciar_leitura-secoes`,
        oe: formatTitle(props.oe.attributes?.Titulo!),
        id: props.oe.id,
      },
    );
  };

  const statusChecker = (index: number) => {
    if (state.currentStep === index) {
      return "current";
    }
    if (state.currentStep > index) {
      return "past";
    }
    return "";
  };

  const scrollToHeading = (index: number) => {
    const destination = headingRefs[index].current!.offsetTop + 2;
    scrollable.current?.scroll({
      top: destination,
      left: 0,
      behavior: "smooth",
    });
  };

  const zoomIn = () => {
    if (state.currentZoom < 5) {
      setState({ ...state, currentZoom: state.currentZoom + 1 });

      // GTM doc linha 89
      gtm.push(
        EDataLayerEventCategory.ADE_OE_PAGINA,
        EDataLayerEventAction.CLIQUE,
        {
          label: `iniciar_leitura-zoom-in`,
          oe: formatTitle(props.oe.attributes?.Titulo!),
          id: props.oe.id,
        },
      );
    }
  };

  const zoomOut = () => {
    if (state.currentZoom > 1) {
      setState({ ...state, currentZoom: state.currentZoom - 1 });

      // GTM doc linha 89
      gtm.push(
        EDataLayerEventCategory.ADE_OE_PAGINA,
        EDataLayerEventAction.CLIQUE,
        {
          label: `iniciar_leitura-zoom-out`,
          oe: formatTitle(props.oe.attributes?.Titulo!),
          id: props.oe.id,
        },
      );
    }
  };

  const handleFinish = async (
    ev: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    leitura: ComponentSecaoTextSecaoTexto,
  ) => {
    ev.stopPropagation();

    if (!leitura) {
      return;
    }

    if (!props.user) {
      dispatch({
        type: HEADER_ACTION_TYPE.PRE_REGISTER_USER,
        payload: true,
      });
      return;
    }

    const result = await consume.saveOeConsume({
      leituraId: parseInt(leitura.id),
      partialDuration: 1,
      totalDuration: 1,
      oe: props.oe.id,
      user: props.user.id,
    });
    if (!!result) {
      mostrarModalConclusao();
    }
  };

  const closeBanner = () => {
    setShowBanner(!showBanner);
  };

  return (
    <div data-text-oe-content>
      <div>
        <div data-tools="navigation">
          <OEBackButton />
          {props.oe.attributes?.SecoesTexto &&
            props.oe.attributes?.SecoesTexto.length > 1 && (
              <div data-vertical-stepper>
                <div data-progress-bar>
                  <div
                    data-progress
                    style={{
                      height: `${65 * state.currentStep}px`,
                      paddingBottom: "0px",
                    }}
                  ></div>
                  {props.oe.attributes?.SecoesTexto.map((item, index) => (
                    <span
                      key={item?.id}
                      data-step={statusChecker(index)}
                      onClick={() => navigateTo(index)}
                    >
                      <div data-bullet></div>
                      <Tooltip
                        title={criarTituloSecaoTexto(index)}
                        aria-label="add"
                      >
                        <div className="hide-for-mobile">
                          {criarTituloSecaoTexto(index)}
                        </div>
                      </Tooltip>
                    </span>
                  ))}
                </div>
              </div>
            )}
        </div>
        <div data-text-container>
          <div data-container-title className="flex-ai-c flex">
            <h1>{formatTitle(props.oe.attributes?.Titulo!)}</h1>
          </div>
          <div
            data-scrollable
            ref={scrollable}
            onScroll={(ev) => handleScroll(ev)}
            style={{ zoom: state.currentZoom }}
          >
            {props.oe.attributes?.SecoesTexto?.map((text, index) => (
              <div key={text?.id}>
                <h2 ref={headingRefs[index]}>{criarTituloSecaoTexto(index)}</h2>
                <div>{HtmlParse(text?.Secao || "")}</div>
              </div>
            ))}
          </div>
        </div>

        <BarraZoom
          zoomIn={zoomIn.bind(this)}
          zoomOut={zoomOut.bind(this)}
          oeId={props.oe.id!}
        />
      </div>

      {props.oe.attributes?.PossuiCertificado && !isOeConcluida && (
        <div data-button-finish>
          <Button
            data-testid="btn-concluir-conteudo-texto-oe"
            type="button"
            variant="primary"
            onClick={(ev) =>
              handleFinish(
                ev,
                props.oe.attributes?.SecoesTexto![
                  props.oe.attributes?.SecoesTexto!.length - 1
                ]!,
              )
            }
          >
            Concluir Conteúdo
          </Button>
        </div>
      )}

      {(() => {
        let bannersFixo = banners?.filter(
          (a) =>
            a.tipoPagina === TIPO_PAGINA.OE &&
            a.areaPagina === TIPO_AREA_PAGINA.FIXO &&
            ((!!user?.id && a.ocultarLogado === false) || !user?.id),
        );

        return (
          <>
            {showBanner && !!bannersFixo && !!bannersFixo.length && (
              <div
                data-banner
                style={{
                  zIndex: 99999,
                }}
                className={
                  !user
                    ? "deslogado"
                    : "fixed bottom-0 left-0 right-0 z-50 mx-auto flex w-full flex-col items-center justify-center md:w-[728px]"
                }
              >
                <BannerSection
                  items={bannersFixo}
                  fixo={true}
                  onClose={closeBanner}
                  showBanner={showBanner}
                />
              </div>
            )}
          </>
        );
      })()}
    </div>
  );
};
