import React from "react";
import { useHistory } from "react-router-dom";
import {
  AreaConhecimento,
  AreaDeConhecimento,
} from "../../../../generated/graphql";
import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../../../hooks/useGtm";

import "./styles.scss";
import { twMerge } from "tailwind-merge";

interface KnowlegdeAreaProps {
  areas: AreaConhecimento[];
  columns?: number;
  perfilUser?: boolean;
}

export const AreasDeInteresse: React.FC<KnowlegdeAreaProps> = (props) => {
  const history = useHistory();
  const gtm = useGtm();

  const handleNavigate = (item: any) => {
    history.push(
      `/area-de-interesse/${item.titulo
        .toString()
        .toLowerCase()
        .trim()
        .replace(/ /g, "-")}/${item.id}`,
      {
        id: item.id,
        titulo: item.titulo,
      }
    );
  };

  return (
    <div
      className={twMerge(
        !props.perfilUser &&
          "grid md:grid-cols-6 grid-cols-2 gap-4 items-center self-center",
        props.perfilUser &&
          "grid grid-cols-2 md:grid-flow-col-dense gap-4 items-center self-center"
      )}
    >
      {props.areas.map(
        (item: any, i: number) =>
          item.icone && (
            <div
              key={i}
              className="py-1 min-w-[8.625rem] border-1 border-slate-100 shadow-md w-auto min-h-[9.625rem] 
              md:min-h-[10rem] 
              flex h-full bg-white rounded-lg flex-col items-center justify-center text-center 
              cursor-pointer hover:bg-gray-100 transition-all duration-200"
              onClick={() => {
                handleNavigate(item);
                // GTM doc linha 65
                gtm.push(
                  EDataLayerEventCategory.ADE_AREAS_INTERESSE,
                  EDataLayerEventAction.CLIQUE,
                  {
                    label: item.titulo,
                  }
                );
              }}
            >
              <img
                height="3.93rem"
                src={item.icone.url}
                alt=""
                loading="lazy"
                style={{ height: "3.93rem" }}
              />
              <span className="font-medium text-sm leading-normal text-[#000000] mt-2">
                {item.titulo}
              </span>
            </div>
          )
      )}
    </div>
  );
};
